export function discernCarGurusBar(
  data: SaleVehicle[],
  carGurusChoice: 0 | 1 | 2 | 3 | 4
) {
  let totalCarGurus = 0
  let totalDifferenceFromCarGurus = 0
  let totalSaleValue = 0
  let countWithCarGurusAndMargin = 0
  let totalDiscount = 0
  let totalCountWithDiscount = 0
  for (let i = 0;i < data.length;i++) {
    let currVehicle = data[i]
    let SellPrice = currVehicle.SellPrice
    if (!currVehicle.SellPrice)
      SellPrice = currVehicle.InitialAskingPrice
    if (!SellPrice) continue
    if (isNaN(SellPrice)) continue
    if (Number(SellPrice) === 0) continue
    if (currVehicle.IsRollback) continue
    if (!currVehicle.FinalCarGurusFairPrice) continue
    if (!currVehicle.FinalCarGurusGoodPrice) continue
    if (!currVehicle.FinalCarGurusGreatPrice) continue
    if (!currVehicle.FinalCarGurusHighPrice) continue
    if (!currVehicle.FinalCarGurusOverPrice) continue
    if (isNaN(currVehicle.FinalCarGurusFairPrice)) continue
    if (isNaN(currVehicle.FinalCarGurusGoodPrice)) continue
    if (isNaN(currVehicle.FinalCarGurusGreatPrice)) continue
    if (isNaN(currVehicle.FinalCarGurusHighPrice)) continue
    if (isNaN(currVehicle.FinalCarGurusOverPrice)) continue
    if (currVehicle.FinalCarGurusFairPrice === 0) continue
    if (currVehicle.FinalCarGurusGoodPrice === 0) continue
    if (currVehicle.FinalCarGurusGreatPrice === 0) continue
    if (currVehicle.FinalCarGurusHighPrice === 0) continue
    if (currVehicle.FinalCarGurusOverPrice === 0) continue

    let cargurusPriceArray = [
      currVehicle.FinalCarGurusGreatPrice,
      currVehicle.FinalCarGurusGoodPrice,
      currVehicle.FinalCarGurusFairPrice,
      currVehicle.FinalCarGurusHighPrice,
      currVehicle.FinalCarGurusOverPrice
    ]
    let cargurusNumberAtChoice = cargurusPriceArray[carGurusChoice]
    if (cargurusNumberAtChoice === 0) continue

    totalCarGurus += cargurusNumberAtChoice
    totalDifferenceFromCarGurus +=
      Number(SellPrice) - cargurusNumberAtChoice
    totalSaleValue += Number(SellPrice)
    currVehicle.IsRollback
      ? countWithCarGurusAndMargin--
      : countWithCarGurusAndMargin++
    if (SellPrice && currVehicle.InitialAskingPrice) {
      totalDiscount += currVehicle.InitialAskingPrice - SellPrice
      totalCountWithDiscount++
    }
  }

  let averageSalePriceOffsetFromCarGurusChoice = Math.round(
    (totalSaleValue - totalCarGurus) / countWithCarGurusAndMargin
  )
  let amountToMoveIndicatorFromLeft = carGurusChoice * 25
  let amountToMoveIndicatorFromPreset =
    (totalDifferenceFromCarGurus / totalCarGurus) * 500
  let isHigher = totalDifferenceFromCarGurus > 0

  return {
    averageSalePriceOffsetFromCarGurusChoice,
    amountToMoveIndicatorFromLeft,
    amountToMoveIndicatorFromPreset,
    totalSaleValue,
    totalCarGurus,
    countWithCarGurusAndMargin,
    isHigher,
  }
}

export function discernGroupings(docs: SaleVehicle[]) {
  const returnObj = [
    {
      name: 'Great Price',
      value: 0,
      AverageAge: 0,
      TotalUnits: 0,
      PercentOfTotal: 0,
      AverageGrossPerSale: 0,
      TotalGross: 0,
      AverageDaysInInventory: 0
    },
    {
      name: 'Good Price',
      value: 0,
      AverageAge: 0,
      TotalUnits: 0,
      PercentOfTotal: 0,
      AverageGrossPerSale: 0,
      TotalGross: 0,
      AverageDaysInInventory: 0
    },
    {
      name: 'Fair Price',
      value: 0,
      AverageAge: 0,
      TotalUnits: 0,
      PercentOfTotal: 0,
      AverageGrossPerSale: 0,
      TotalGross: 0,
      AverageDaysInInventory: 0
    },
    {
      name: 'High Price',
      value: 0,
      AverageAge: 0,
      TotalUnits: 0,
      PercentOfTotal: 0,
      AverageGrossPerSale: 0,
      TotalGross: 0,
      AverageDaysInInventory: 0
    },
    {
      name: 'Overpriced',
      value: 0,
      AverageAge: 0,
      TotalUnits: 0,
      PercentOfTotal: 0,
      AverageGrossPerSale: 0,
      TotalGross: 0,
      AverageDaysInInventory: 0
    }
  ]
  docs.forEach((doc: SaleVehicle) => {
    let price = doc.SellPrice
    if (!doc.SellPrice) price = doc.InitialAskingPrice
    if (!doc.SellPrice) return
    if (!doc.FinalCarGurusFairPrice) return
    const allCarGurus = [
      doc.FinalCarGurusGreatPrice,
      doc.FinalCarGurusGoodPrice,
      doc.FinalCarGurusFairPrice,
      doc.FinalCarGurusHighPrice,
      doc.FinalCarGurusOverPrice
    ]
    const differences = allCarGurus.map((carGurus) => {
      if (!carGurus) return 0
      if (!price) return 0
      return Math.abs(price - carGurus)
    })
    const minDifference = Math.min(...differences)
    const index = differences.indexOf(minDifference)
    returnObj[index].value++
    returnObj[index].AverageAge += doc.DaysInInventory ?? 0
    returnObj[index].TotalUnits++
    returnObj[index].TotalGross += doc.Gross ?? 0
  })
  returnObj.forEach((group) => {
    group.PercentOfTotal = Math.round((100 * group.TotalUnits) / docs.length)
    group.AverageAge = Math.round(group.AverageAge / group.TotalUnits)
    group.AverageGrossPerSale = Math.round(group.TotalGross / group.TotalUnits)
  })
  return returnObj
}