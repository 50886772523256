import LinkWithQuery from '@/components/link-with-query'
import { Alert, Button, Input, Label } from '@/components/ui'
import axios from '@/lib/axios'
import { FormEvent, useEffect, useState } from 'react'

type LoginFormProps = {
  onSuccess: (options: any[]) => void
}

export default function LoginForm({ onSuccess }: LoginFormProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    setError('')
    try {
      event.preventDefault()
      const EmailAddress = (event.currentTarget.elements.namedItem('email') as HTMLInputElement).value
      const Password = (event.currentTarget.elements.namedItem('password') as HTMLInputElement).value
      const response = await axios.post('/auth', {
        EmailAddress,
        Password
      })
      if (response.status > 201) throw new Error(response.statusText)
      onSuccess(response.data)
    } catch (error: any) {
      let errorMessage = error.message
      if (error.response?.data?.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message
        } else {
          errorMessage = error.response.data.message[0]
        }
      }
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('Authorization')
    if (token) {
      window.location.href = '/dashboard'
    }
  }, [])

  return (
    <form className='grid gap-4' onSubmit={onSubmit}>
      {error && <Alert variant='destructive'>{error}</Alert>}
      <div className='grid gap-2'>
        <Label htmlFor='email'>Email</Label>
        <Input id='email' type='email' placeholder='m@example.com' required />
      </div>
      <div className='grid gap-2'>
        <div className='flex items-center'>
          <Label htmlFor='password'>Password</Label>
          <LinkWithQuery to='/forgot-password' className='ml-auto inline-block text-sm underline'>
            Forgot your password?
          </LinkWithQuery>
        </div>
        <Input id='password' type='password' required />
      </div>
      <Button name='submit' loading={loading} type='submit' className='w-full'>
        Login
      </Button>
    </form>
  )
}
