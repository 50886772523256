import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import InventoryTableFilters from './inventory-table-filters'
import AddInventory from './add-inventory'

type InventoryTableHeaderProps = {
  store: Company
}

export default function InventoryTableHeader({ store }: InventoryTableHeaderProps) {
  return (
    <GridToolbarContainer
      style={{
        borderBottom: '1px solid var(--background-tertiary)',
        paddingBottom: '0.25rem'
      }}
    >
      <div className='flex justify-between items-center w-full'>
        <div className='flex items-center w-full'>
          <div className='flex items-center w-11/12 lg:w-1/3'>
            <GridToolbarQuickFilter
              style={{
                width: '100%',
                paddingLeft: '1rem',
                paddingTop: 4,
                marginRight: 8
              }}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '6px',
                  margin: 0,
                  height: 36
                },
                '& .MuiInputBase-input': {
                  border: 'none',
                  boxShadow: 'none'
                },
                '& .MuiInputBase-input:focus': {
                  boxShadow: 'none'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid rgb(226, 232, 240)',
                  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)'
                }
              }}
              size='small'
              variant='outlined'
            />
            <AddInventory store={store} />
          </div>
        </div>
        <div className='flex items-center gap-2 text-black fill-black'>
          <InventoryTableFilters />
          <GridToolbarColumnsButton
            slotProps={{
              button: {
                className:
                  'shadow-sm inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 p-2',
                style: {
                  color: 'var(--text-primary)',
                  border: '1px solid rgb(226, 232, 240)',
                  borderRadius: '0.3rem',
                  padding: '0.34rem 0.5rem',
                  fontFamily: 'inherit',
                  textTransform: 'none'
                }
              }
            }}
          />
          <div className='hidden lg:flex items-center'>
            <GridToolbarDensitySelector
              slotProps={{
                button: {
                  className:
                    'shadow-sm inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 p-2',
                  style: {
                    color: 'var(--text-primary)',
                    border: '1px solid rgb(226, 232, 240)',
                    borderRadius: '0.3rem',
                    padding: '0.34rem 0.5rem',
                    fontFamily: 'inherit',
                    textTransform: 'none'
                  }
                }
              }}
            />
          </div>
          <div className='hidden lg:flex items-center gap-2'>
            <GridToolbarExport
              slotProps={{
                button: {
                  className:
                    'shadow-sm inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 p-2',
                  style: {
                    color: 'var(--text-primary)',
                    border: '1px solid rgb(226, 232, 240)',
                    borderRadius: '0.3rem',
                    padding: '0.34rem 0.5rem',
                    fontFamily: 'inherit',
                    textTransform: 'none'
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </GridToolbarContainer>
  )
}
