import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import { useEffect, useMemo, useState } from 'react'
import useStores from '@/hooks/use-stores'
import { discernCarGurusBar } from '@/lib/cargurus'
import useDebounce from '@/hooks/use-debounce'
import CarDataForm from './components/car-data-form'
import CargurusBar from './components/cargurus-bar'
import SimilarSalesTable from './components/similar-sales-table'
import { StoreContext } from '@/services/providers/store-context-provider'
import SimilarInventoryTable from './components/similar-inventory-table'

type MarketData = {
  greatPrice: number
  goodPrice: number
  fairPrice: number
  highPrice: number
  overPrice: number
  alikeElements: any[]
} | null

export default function Appraisal() {
  const newAppraisal = {
    Make: 'Nothing yet...',
    Model: 'Nothing yet...',
    ModelYear: 'Nothing yet...',
    VehicleTrimLevel: 'Nothing yet...'
  }
  const stores = useStores()
  const [store, setStore] = useState<StoreContext | null>(null)

  useEffect(() => {
    const selectedStores = stores.data.filter((store) => store.IsSelected)
    if (selectedStores.length === 0) return
    setStore(selectedStores[0])
  }, [stores.data])

  const price = useDebounce(0, 500)
  const [appraisal, setAppraisal] = useState(newAppraisal)
  const [marketData, setMarketData] = useState<MarketData>(null)
  const [similar, setSimilar] = useState<{
    similarSales: SaleVehicle[]
    similarInventory: SaleVehicle[]
  }>({ similarSales: [], similarInventory: [] })
  const cargurus = useMemo(() => {
    if (!marketData) return null
    const barData: SaleVehicle[] = [
      {
        FinalCarGurusGreatPrice: marketData.greatPrice,
        FinalCarGurusGoodPrice: marketData.goodPrice,
        FinalCarGurusFairPrice: marketData.fairPrice,
        FinalCarGurusHighPrice: marketData.highPrice,
        FinalCarGurusOverPrice: marketData.overPrice,
        SellPrice: price.value ?? 1
      }
    ] as SaleVehicle[]
    const cargurusBar = discernCarGurusBar(barData, stores.data[0].DefaultCarGurusPreference)
    return cargurusBar
  }, [marketData, price])
  return (
    <div className='flex flex-col container p-4 gap-4'>
      <Tabs
        value={String(store?.CompanyId)}
        onValueChange={(newValue) => {
          const newStore = stores.data.find((store) => store.CompanyId === Number(newValue))
          if (!newStore) return
          setStore(newStore)
        }}
      >
        <TabsList>
          {stores.data
            .filter((store) => store.IsSelected)
            .map((store) => (
              <TabsTrigger key={store.CompanyId} value={String(store.CompanyId)}>
                {store.Name}
              </TabsTrigger>
            ))}
        </TabsList>
      </Tabs>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <CarDataForm
          setAppraisal={setAppraisal}
          setMarketData={setMarketData}
          store={store}
          price={price}
          setSimilar={setSimilar}
        />
        <Card>
          <CardHeader>
            <CardTitle>{appraisal ? "Here's what we found" : 'Enter a valid VIN to get started'}</CardTitle>
          </CardHeader>
          <CardContent className='flex flex-col gap-4'>
            <div className='flex flex-col'>
              <span className='opacity-80 text-sm'>Year</span>
              <span>{appraisal.ModelYear}</span>
            </div>
            <div className='flex flex-col'>
              <span className='opacity-80 text-sm'>Make</span>
              <span>{appraisal.Make}</span>
            </div>
            <div className='flex flex-col'>
              <span className='opacity-80 text-sm'>Model</span>
              <span>{appraisal.Model}</span>
            </div>
            <div className='flex flex-col'>
              <span className='opacity-80 text-sm'>Trim Level</span>
              <span>{appraisal.VehicleTrimLevel}</span>
            </div>
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>CarGurus Pricing</CardTitle>
        </CardHeader>
        <CardContent>
          {cargurus && marketData ? (
            <CargurusBar cargurus={cargurus} marketData={marketData} stores={stores.data} />
          ) : (
            <div className='flex justify-center items-center h-[5.5rem] text-lg opacity-40'>
              <div className='h-2 rounded-full w-full relative'>
                <p className='-mt-8 text-center'>No data to display</p>
                <div className='absolute top-0 left-0 bottom-0 right-0 flex h-full w-full z-[0]'>
                  <div className='bg-gray-400 w-full h-full rounded-l-full'></div>
                  <div className='bg-gray-500 w-full h-full border-l-2'></div>
                  <div className='bg-gray-600 w-full h-full border-l-2'></div>
                  <div className='bg-gray-700 w-full h-full border-l-2'></div>
                  <div className='bg-gray-800 w-full h-full rounded-r-full border-l-2'></div>
                </div>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Similar sales from your store (last 90 days)</CardTitle>
        </CardHeader>
        <CardContent className=''>
          <div className='w-full border rounded-lg  max-h-96 overflow-y-auto'>
            <SimilarSalesTable data={similar.similarSales} />
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Similar inventory from your store (current inventory)</CardTitle>
        </CardHeader>
        <CardContent className=''>
          <div className='w-full border rounded-lg  max-h-96 overflow-y-auto'>
            <SimilarInventoryTable data={similar.similarInventory} />
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Similar listings nearby</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Picture</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Location</TableHead>
                <TableHead>Price</TableHead>
                <TableHead>Mileage</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TooltipProvider>
                {marketData?.alikeElements.map((element, index) => (
                  <TableRow key={element.title + index}>
                    <Tooltip delayDuration={0} disableHoverableContent>
                      <TooltipTrigger>
                        <TableCell>
                          <img src={element.image} alt={element.title} className='w-20 rounded-xl' />
                        </TableCell>
                      </TooltipTrigger>
                      <TooltipContent>
                        <img src={element.image} alt={element.title} className='w-72 rounded-xl' />
                      </TooltipContent>
                    </Tooltip>
                    <TableCell>{element.title}</TableCell>
                    <TableCell>{element.location}</TableCell>
                    <TableCell>${Number(element.price).toLocaleString()}</TableCell>
                    <TableCell>{Number(element.mileage).toLocaleString()} Miles</TableCell>
                  </TableRow>
                ))}
              </TooltipProvider>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}
