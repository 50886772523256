import { useQuery } from '@tanstack/react-query'
import axios from '@/lib/axios'
import useDebounce from '@/hooks/use-debounce'
import { useState } from 'react'
import FootprintTable from './footprint-table'
import FootprintTableRow from './footprint-table-row'
import FootprintTableExpandedRow from './footprint-table-expanded-row'

type InventoryFootprintTabProps = {
  store: Company | undefined
}

type DataType = {
  franchiseModelData: InventoryFootprintItem[]
  otherModelData: InventoryFootprintItem[]
}

export type InventoryFootprintItem = {
  name: string
  averageTurn: number
  averageSoldPerDay: number
  unitsSoldLast90days: number
  targetDaysSupply: number
  daysSupply: number
  unitsInStock: number
  amountOffset: number
  averageInvestment: number
  totalGross: number
}

export default function InventoryFootprintTab({ store }: InventoryFootprintTabProps) {
  const { data, isPending } = useQuery({
    queryKey: ['footprint', { CompanyId: store?.CompanyId }],
    queryFn: async () => {
      if (!store?.CompanyId) return
      const data = await axios.get(`/analytics/inventory-footprint/${store.CompanyId}`)
      return data.data as DataType
    }
  })

  const filter1 = useDebounce('', 500)
  const filter2 = useDebounce('', 500)
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  return (
    <div className='w-full flex flex-col gap-4 pb-32'>
      <FootprintTable
        label='Franchise Models'
        loading={isPending || !store}
        data={data?.franchiseModelData ?? []}
        filter={filter1}
      >
        {store &&
          data?.franchiseModelData
            ?.filter((item: InventoryFootprintItem) => item.name.toUpperCase().includes(filter1.value.toUpperCase()))
            .map((item: InventoryFootprintItem, index: number) => {
              return (
                <>
                  <FootprintTableRow
                    key={index}
                    item={item}
                    isExpanded={expandedRow === index}
                    setExpanded={setExpandedRow}
                    index={index}
                    CompanyId={store.CompanyId}
                    zipCode={store.ZipCode}
                  />
                  {expandedRow === index && <FootprintTableExpandedRow key={index + '-dd'} item={item} />}
                </>
              )
            })}
      </FootprintTable>
      <FootprintTable label='Other Models' loading={isPending} data={data?.otherModelData ?? []} filter={filter2}>
        {store &&
          data?.otherModelData
            ?.filter((item: InventoryFootprintItem) => item.name.toUpperCase().includes(filter2.value.toUpperCase()))
            .map((item: InventoryFootprintItem, index: number) => {
              index += 100
              return (
                <>
                  <FootprintTableRow
                    key={index}
                    item={item}
                    isExpanded={expandedRow === index}
                    setExpanded={setExpandedRow}
                    index={index}
                    CompanyId={store.CompanyId}
                    zipCode={store.ZipCode}
                  />
                  {expandedRow === index && <FootprintTableExpandedRow key={index + '-dd'} item={item} />}
                </>
              )
            })}
      </FootprintTable>
    </div>
  )
}
