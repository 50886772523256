import axios from "@/lib/axios"

export async function authenticateUserWithJWT(jwt: string | null | undefined) {
  try {
    if (!jwt) return null
    const user = await axios.get('/auth')
    return user.data
  } catch (error: unknown) {
    console.error(error)
    localStorage.removeItem('Authorization')
    return null
  }
}