import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import useStores from '@/hooks/use-stores'
import UserCards from './components/user-cards'
import SourcesCards from './components/sources-cards'
import StoreDetails from './components/store-details'
import AutomationsList from './components/automations-list'

export default function Stores() {
  const stores = useStores()
  return (
    <div className='container p-4'>
      <Card>
        <CardHeader>
          <CardTitle>Stores</CardTitle>
        </CardHeader>
        <CardContent>
          <Accordion type='single' collapsible className='w-full'>
            {stores.data.map((store) => (
              <AccordionItem key={store.CompanyId} title={store.Name} value={String(store.CompanyId)}>
                <AccordionTrigger className='p-4'>{store.Name}</AccordionTrigger>
                <AccordionContent>
                  <div className='p-4'>
                    <p>
                      {store.City}, {store.state_FullName} {store.ZipCode}
                    </p>
                    <div className='flex gap-2 mt-2'>
                      <StoreDetails company={store} />
                      <Dialog>
                        <DialogTrigger>
                          <Button variant='outline'>Edit who gets reports</Button>
                        </DialogTrigger>
                        <DialogContent className='w-[56rem] max-w-none'>
                          <DialogHeader>
                            <DialogTitle>Report receivers for {store.Name}</DialogTitle>
                          </DialogHeader>
                          <UserCards CompanyId={store.CompanyId} />
                        </DialogContent>
                      </Dialog>
                      <Dialog>
                        <DialogTrigger>
                          <Button variant='outline'>Edit sources</Button>
                        </DialogTrigger>
                        <DialogContent className='w-[56rem] max-w-none'>
                          <DialogHeader>
                            <DialogTitle>Vehicle sources for {store.Name}</DialogTitle>
                          </DialogHeader>
                          <SourcesCards CompanyId={store.CompanyId} />
                        </DialogContent>
                      </Dialog>
                      <Dialog>
                        <DialogTrigger>
                          <Button variant='outline'>Edit automations</Button>
                        </DialogTrigger>
                        <DialogContent className='w-[56rem] max-w-none'>
                          <DialogHeader>
                            <DialogTitle>Automations for {store.Name}</DialogTitle>
                          </DialogHeader>
                          <AutomationsList CompanyId={store.CompanyId} />
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </CardContent>
      </Card>
    </div>
  )
}
