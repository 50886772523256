import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'
import 'non.geist'
import dayjs from 'dayjs'
import { dayjsUtils } from './setup/dayjs-utils.ts'
// import * as Sentry from '@sentry/react'

// Sentry.init({
//   dsn: 'https://4f99f161c78d8fc0f05ea24d5e7cdb22@o4507415418044416.ingest.us.sentry.io/4507415420403712',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: true,
//       blockAllMedia: true
//     })
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 0.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/maxautolytics\.com/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
//   sampleRate: 0.0
// })

dayjsUtils.install(dayjs)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
)
