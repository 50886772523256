import axios from '@/lib/axios'

export default function useCargurusIdentification() {

  const getCargurusIdentification = async (vin: string | undefined, zip: number | string | undefined, miles: number | string | undefined, radius: number = 75, CompanyId: number) => {
    if (!vin) return console.error('Vin is required')
    if (typeof vin !== 'string') return console.error('Vin must be a string')
    if (vin.length !== 17) return console.error('Vin must have 17 characters')
    const returnObject = {
      Make: null,
      Model: null,
      ModelYear: null,
      VehicleTrimLevel: null,
      pricing: {
        greatPrice: null,
        goodPrice: null,
        fairPrice: null,
        highPrice: null,
        overPrice: null,
        alikeElements: null
      },
      similar: {
        similarSales: [],
        similarInventory: []
      }
    }
    try {
      const response = await axios.get('/appraisal/details', { params: { vin } })
      const data = response.data.data.carDetails
      returnObject.Make = data.makerName
      returnObject.Model = data.modelName
      returnObject.ModelYear = data.yearName
      returnObject.VehicleTrimLevel = data.trimName
      if (!zip || !miles) return returnObject

      let entityId = data.trim
      if (!entityId) entityId = data.year
      if (!entityId) entityId = data.model
      if (!entityId) entityId = data.maker
      const transmissionId = data.transmission
      const engineId = data.engine
      try {
        const { data: pricing } = await axios.get('/appraisal/pricing', { params: { vin, id: entityId, zip, miles, transmissionId, engineId, radius } })
        returnObject.pricing = pricing
      } catch (error) {
        console.error(error)
      }
      try {
        const { data: similar } = await axios.get('/appraisal/similar', { params: { CompanyId, ModelYear: data.yearName, Make: data.makerName, Model: data.modelName, VehicleTrimLevel: data.trimName } })
        returnObject.similar = similar
      } catch (error) {
        console.error(error)
      }
      return returnObject
    } catch (error) {
      console.error(error)
    }
  }

  const getCargurusPricing = async (vin: string | undefined, zip: number | string | undefined, miles: number | string | undefined) => {
    if (!vin) return console.error('Vin is required')
    if (typeof vin !== 'string') return console.error('Vin must be a string')
    if (vin.length !== 17) return console.error('Vin must have 17 characters')
    try {
      const { data } = await axios.get(`https://cargurus-api-2001a88b531d.herokuapp.com/${vin}`, {
        params: {
          zip,
          miles
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data
    } catch (error) {
      console.error(error)
    }
  }

  return { getCargurusIdentification, getCargurusPricing }
}