import { Alert, Button, InputOTP, Label } from '@/components/ui'
import { InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '@/components/ui/input-otp'
import axios from '@/lib/axios'
import { FormEvent, useState } from 'react'

type Option = {
  name: string
  value: string
  authKey: string
}

type LoginOTPFormProps = {
  option: Option | null
}

export default function LoginOTPForm({ option }: LoginOTPFormProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [otp, setOtp] = useState('')

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    setError('')
    try {
      event.preventDefault()
      const otp = (event.currentTarget.elements.namedItem('otp') as HTMLInputElement).value
      const response = await axios.post(`/auth/otp/${option?.name.toLowerCase()}/${otp}`, {
        PreAuthToken: option?.authKey
      })
      if (response.status > 201) throw new Error(response.statusText)
      if (!response.headers['authorization']) throw new Error('No token found')
      else localStorage.setItem('Authorization', response.headers['authorization'])
      const urlParams = new URLSearchParams(window.location.search)
      const redirect = urlParams.get('redirect') || '/dashboard'
      window.location.href = redirect
    } catch (error: any) {
      let errorMessage = error.message
      if (error.response?.data?.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message
        } else {
          errorMessage = error.response.data.message[0]
        }
      }
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form className='grid gap-4' onSubmit={onSubmit}>
      {error && <Alert variant='destructive'>{error}</Alert>}
      <div className='grid gap-2 justify-center'>
        <Label htmlFor='email'>Code</Label>
        <InputOTP
          id='otp'
          required
          maxLength={6}
          value={otp}
          onChange={(value) => setOtp(value.toUpperCase())}
          autoFocus
          pattern={'[0-9a-zA-Z]*'}
          inputMode='text'
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
        <Label className='text-xs text-gray-500' htmlFor='otp'>
          Enter the 6 digit code that we sent to your email
        </Label>
      </div>
      <Button name='submit' loading={loading} type='submit' className='w-full'>
        Login and go to app
      </Button>
    </form>
  )
}
