import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { useState } from 'react'

type AddAutomationMappingFormProps = {
  loading: boolean
  onSubmit: (event: HTMLFormElement) => void
}

export default function AddAutomationMappingForm({ loading, onSubmit }: AddAutomationMappingFormProps) {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const validate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const form = ev.currentTarget as HTMLFormElement
    let hasError = false
    const errors: Record<string, string> = {}
    if (!form.FromColumn.value) {
      errors.FromColumn = 'You must choose a column to source from'
      hasError = true
    }
    if (!form.ConvertionFn.value) {
      errors.ConvertionFn = 'You must choose a conversion function'
      hasError = true
    }
    if (!form.ToKey.value) {
      errors.ToKey = 'You must choose a field to map to'
      hasError = true
    }
    setErrors(errors)
    if (!hasError) {
      onSubmit(form)
    }
  }

  const letters = []
  // add from A to AZ like a google sheet column
  for (let i = 65; i <= 90; i++) {
    letters.push(String.fromCharCode(i))
  }
  for (let i = 65; i <= 90; i++) {
    letters.push('A' + String.fromCharCode(i))
  }

  return (
    <form className='grid grid-cols-3 gap-4' onSubmit={validate}>
      <DisplayField
        label='From column'
        description='This is the letter of the column in which this data is found in your spreadsheet'
      >
        <Select name='FromColumn'>
          <SelectTrigger>
            <SelectValue placeholder='Select a column' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Column letter</SelectLabel>
              {letters.map((letter) => (
                <SelectItem key={letter} value={letter}>
                  {letter}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <span className='text-red-500 text-sm'>{errors.FromColumn}</span>
      </DisplayField>
      <DisplayField
        label='Formatter'
        description='This is the function that will be applied to the data in the column to format it properly'
      >
        <Select name='ConvertionFn'>
          <SelectTrigger>
            <SelectValue placeholder='Select a function' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Function to format data</SelectLabel>
              <SelectItem value='to-date'>Convert format of date entered to proper date time format</SelectItem>
              <SelectItem value='to-boolean'>Convert data from [not empty/empty] to [true/false]</SelectItem>
              <SelectItem value='to-number'>
                Convert column to pure number (remove all non-numeric characters)
              </SelectItem>
              <SelectItem value='none'>Leave column as is</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <span className='text-red-500 text-sm'>{errors.ConvertionFn}</span>
      </DisplayField>
      <DisplayField
        label='To field'
        description='This is the name of the field on each sale where the formatted data will be applied'
      >
        <Select name='ToKey'>
          <SelectTrigger>
            <SelectValue placeholder='Select a field' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Field on sale this value will be mapped to</SelectLabel>
              <SelectItem value='StockNumber'>Stock number</SelectItem>
              <SelectItem value='Vin'>Vin</SelectItem>
              <SelectItem value='IsCertified'>Is certified? (true/false)</SelectItem>
              <SelectItem value='TookATrade'>Took a trade? (true/false)</SelectItem>
              <SelectItem value='ModelYear'>ModelYear</SelectItem>
              <SelectItem value='Make'>Make</SelectItem>
              <SelectItem value='Model'>Model</SelectItem>
              <SelectItem value='VehicleTrimLevel'>Trim level</SelectItem>
              <SelectItem value='Gross'>*Gross</SelectItem>
              <SelectItem value='SoldAt'>*Sold date (proper date time)</SelectItem>
              <SelectItem value='Odometer'>Odometer</SelectItem>
              <SelectItem value='InitialAskingPrice'>Initial asking price</SelectItem>
              <SelectItem value='DaysInInventory'>Days to sell</SelectItem>
              <SelectItem value='SellPrice'>Sell price</SelectItem>
              <SelectItem value='PercentOfMarket'>Percent of market</SelectItem>
              <SelectItem value='MSRP'>MSRP</SelectItem>
              <SelectItem value='InitialManheimWholesale'>Initial Manheim price</SelectItem>
              <SelectItem value='FinalManheimWholesale'>Final Manheim price</SelectItem>
              <SelectItem value='InitialACV'>Estimated cost</SelectItem>
              <SelectItem value='FinalACV'>Real cost</SelectItem>
              <SelectItem value='AppraisedValue'>Appraised value</SelectItem>
              <SelectItem value='CostToMarket'>Cost to market</SelectItem>
              <SelectItem value='Notes'>Notes</SelectItem>
              <SelectItem value='IsRollback'>Is rolled back? (true/false)</SelectItem>
              <SelectItem value='RollbackDate'>Rolled back on date (proper date time)</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <span className='text-red-500 text-sm'>{errors.ToKey}</span>
      </DisplayField>
      <Button type='submit' className='col-span-5' size='sm' loading={loading}>
        Create new mapping for automation
      </Button>
    </form>
  )
}

type DisplayFieldProps = {
  colspan?: number
  label: string
  children: React.ReactNode
  description?: string
}

const DisplayField = ({ label, children, colspan, description }: DisplayFieldProps) => {
  const colspanString = colspan ? `col-span-${colspan}` : ''
  return (
    <div className={cn('flex flex-col gap-2', colspanString)}>
      <div className='flex items-center space-x-2 justify-start w-full whitespace-nowrap'>
        <span className='font-semibold'>{label}</span>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <div className='flex items-center text-sm opacity-80'>
                <QuestionMarkCircledIcon /> What is this?
              </div>
            </TooltipTrigger>
            <TooltipContent>{description}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      {children}
    </div>
  )
}
