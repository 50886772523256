import { Toaster } from './components/ui'
import './globals.css'
import { useAssertQueryDates } from './hooks/use-query-dates'
import Header from './layouts/header'
import Logo from './layouts/logo'
import Sidebar from './layouts/sidebar'
import LocalAppPreferencesContextProvider from './services/providers/local-app-preferences-provider'
import StoreContextProvider from './services/providers/store-context-provider'
import UserContextProvider from './services/providers/user-context-provider'
import RouteDefinitions from './setup/routing/route-definitions'
import { LicenseInfo } from '@mui/x-license'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()
function App() {
  useAssertQueryDates()
  LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY)
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <StoreContextProvider>
          <LocalAppPreferencesContextProvider>
            <Toaster />
            <div className='flex flex-col h-screen w-screen bg-border'>
              <div className='flex w-full h-fit'>
                <Logo />
                <Header />
              </div>
              <div className='flex h-full w-full overflow-y-hidden'>
                <Sidebar />
                <div className='w-full h-full rounded-t-xl overflow-y-auto overflow-x-hidden bg-background border relative z-1'>
                  <RouteDefinitions />
                </div>
              </div>
            </div>
          </LocalAppPreferencesContextProvider>
        </StoreContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  )
}

export default App
