import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Input,
  Switch,
  Tabs,
  TabsContent,
  TabsTrigger
} from '@/components/ui'
import useDebounce from '@/hooks/use-debounce'
import useSources from '../hooks/use-sources'
import useStores from '@/hooks/use-stores'
import { TabsList } from '@radix-ui/react-tabs'
import { useState } from 'react'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'

type ExcludeSourcesProps = {
  isOpen: boolean
  onClose: () => void
  onSave: (sources: number[]) => void
}

export default function ExcludeSources({ isOpen, onClose, onSave }: ExcludeSourcesProps) {
  const sourceFilter = useDebounce('', 300)
  const stores = useStores()
  const sources = useSources(stores.data.filter((store) => store.IsSelected).map((store) => store.CompanyId))
  const preferences = useLocalAppPreferences()
  const excludeSources = preferences.preferences.dashboard.excludeSources

  const [newSources, setNewSources] = useState<number[]>(excludeSources)

  const toggleSource = (sourceId: number) => {
    setNewSources((prev) => (prev.includes(sourceId) ? prev.filter((id) => id !== sourceId) : [...prev, sourceId]))
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className='z-50'>
        <DialogTitle>Exclude Sources</DialogTitle>
        <Input
          placeholder='Search sources'
          value={sourceFilter.value}
          onChange={(e) => sourceFilter.setValue(e.target.value)}
        />
        <Tabs defaultValue='0'>
          <TabsList className='bg-border p-1 rounded-lg'>
            {stores.data
              .filter((store) => store.IsSelected)
              .map((store, index) => (
                <TabsTrigger key={store.CompanyId} value={String(index)}>
                  {store.Name}
                </TabsTrigger>
              ))}
          </TabsList>
          <div className='flex flex-col gap-2 mt-4 overflow-y-auto h-96 p-1'>
            {newSources.includes(0) && (
              <Card className='shadow-sm'>
                <div className='flex items-center justify-between p-2 text-sm'>
                  <div>No Source</div>
                  <div className='flex gap-2 items-center'>
                    <span className='opacity-50'>{newSources.includes(0) ? 'Excluded' : 'Included'}</span>
                    <Switch checked={!newSources.includes(0)} onCheckedChange={() => toggleSource(0)} />
                  </div>
                </div>
              </Card>
            )}
            {stores.data
              .filter((store) => store.IsSelected)
              .map((store, index) => (
                <TabsContent key={store.CompanyId} value={String(index)} className='flex flex-col space-y-2'>
                  {!sources.loading &&
                    sources.data &&
                    sources.data[index] &&
                    sources.data[index]
                      .filter((source) =>
                        sourceFilter.value === ''
                          ? true
                          : source.SourceName.toUpperCase().includes(sourceFilter.value.toUpperCase())
                      )
                      // sort by if it is excluded
                      .sort((a, b) => {
                        if (newSources.includes(a.VehicleSourceId) && !newSources.includes(b.VehicleSourceId)) return -1
                        if (!newSources.includes(a.VehicleSourceId) && newSources.includes(b.VehicleSourceId)) return 1
                        return 0
                      })
                      .map((source) => (
                        <Card key={source.VehicleSourceId} className='shadow-sm'>
                          <div className='flex items-center justify-between p-2 text-sm'>
                            <div>{source.SourceName}</div>
                            <div className='flex gap-2 items-center'>
                              <span className='opacity-50'>
                                {newSources.includes(source.VehicleSourceId) ? 'Excluded' : 'Included'}
                              </span>
                              <Switch
                                checked={!newSources.includes(source.VehicleSourceId)}
                                onCheckedChange={() => toggleSource(source.VehicleSourceId)}
                              />
                            </div>
                          </div>
                        </Card>
                      ))}
                  {!newSources.includes(0) && (
                    <Card className='shadow-sm'>
                      <div className='flex items-center justify-between p-2 text-sm'>
                        <div>No Source</div>
                        <div className='flex gap-2 items-center'>
                          <span className='opacity-50'>{newSources.includes(0) ? 'Excluded' : 'Included'}</span>
                          <Switch checked={!newSources.includes(0)} onCheckedChange={() => toggleSource(0)} />
                        </div>
                      </div>
                    </Card>
                  )}
                </TabsContent>
              ))}
          </div>
        </Tabs>
        <DialogFooter>
          <Button variant='secondary' onClick={onClose} className='w-full'>
            Cancel
          </Button>
          <Button onClick={() => onSave(newSources)} className='w-full'>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
