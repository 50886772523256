import { useState } from 'react'
import useCargurusIdentification from '../hooks/use-cargurus-identification'
import { Button, Card, CardContent, CardHeader, CardTitle, Input, Label } from '@/components/ui'
import { StoreContext } from '@/services/providers/store-context-provider'
import { AxiosResponse } from 'axios'
import { Slider } from '@/components/ui/slider'

type CarDataFormProps = {
  setAppraisal: (appraisal: any) => void
  setMarketData: (data: any) => void
  setSimilar: (data: any) => void
  store: StoreContext | null
  price: {
    value: number
    setValue: (value: number) => void
  }
}

type ResponseData = {
  Make: string
  Model: string
  ModelYear: string
  VehicleTrimLevel: string
  pricing: {
    greatPrice: number
    goodPrice: number
    fairPrice: number
    highPrice: number
    overPrice: number
    alikeElements: {
      location: string
      price: string
      miles: string
    }
  }
  similar: {
    similarSales: SaleVehicle[]
    similarInventory: SaleVehicle[]
  }
}

export default function CarDataForm({ setAppraisal, setMarketData, store, price, setSimilar }: CarDataFormProps) {
  const [vin, setVin] = useState('')
  const [miles, setMiles] = useState(0)
  const [radius, setRadius] = useState(75)
  const { getCargurusIdentification } = useCargurusIdentification()

  const isResponseValid = (data: AxiosResponse['data'] | null | undefined): data is ResponseData => {
    return !!data && !!data.Make && !!data.Model && !!data.ModelYear && !!data.VehicleTrimLevel
  }

  const validateResponse = (data: AxiosResponse['data'] | null | undefined): ResponseData => {
    if (!isResponseValid(data)) throw new Error('Invalid response')
    return data
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Let&apos;s get some information</CardTitle>
      </CardHeader>
      <CardContent>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (!store) return
            getCargurusIdentification(vin, store.ZipCode, miles, radius, store.CompanyId).then((data) => {
              const responseData = validateResponse(data)
              if (!responseData) return
              const updateAppraisal = {
                Make: responseData.Make,
                Model: responseData.Model,
                ModelYear: responseData.ModelYear,
                VehicleTrimLevel: responseData.VehicleTrimLevel
              }
              setAppraisal(updateAppraisal)
              setSimilar(responseData.similar)
              if (!responseData?.pricing) return
              setMarketData(responseData.pricing)
            })
          }}
          className='flex flex-col gap-4'
        >
          <div className='flex flex-col'>
            <Label>VIN</Label>
            <Input name='vin' value={vin} onChange={(e) => setVin(e.target.value)} />
          </div>
          <div className='flex gap-4'>
            <div className='flex flex-col w-full'>
              <Label>Price</Label>
              <Input
                name='price'
                value={price.value}
                onChange={(e) => price.setValue(Number(e.target.value.replace(/[^0-9.-]/g, '') ?? 0))}
              />
            </div>
            <div className='flex flex-col w-full'>
              <Label>Odometer (miles)</Label>
              <Input value={miles} name='miles' onChange={(e) => setMiles(Number(e.target.value))} />
            </div>
          </div>
          <div className='flex flex-col w-full gap-2'>
            <Label>Search Radius (miles)</Label>
            <Slider
              value={[radius]}
              onValueChange={(newValue: [number, number]) => setRadius(newValue[0])}
              min={10}
              max={500}
              step={10}
            />
            <div className='flex gap-1 items-center'>
              <Input value={radius} onChange={(e) => setRadius(Number(e.target.value))} className='w-16' />
              <Label>miles</Label>
            </div>
          </div>
          <Button className='w-full'>Get details</Button>
        </form>
      </CardContent>
    </Card>
  )
}
