import CubeIcon from '@/assets/icons/cube-icon'
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import useStores from '@/hooks/use-stores'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'

export default function StoreSelector() {
  const stores = useStores()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [newStores, setNewStores] = useState(stores.data)

  const toggleStore = (storeId: number) => {
    setNewStores((prevStores) => {
      return prevStores.map((store) => {
        if (store.CompanyId === storeId) {
          store.IsSelected = !store.IsSelected
        }
        return store
      })
    })
  }

  useEffect(() => {
    setNewStores(stores.data)
  }, [stores.data, popoverOpen])

  useEffect(() => {
    if (!stores.loading && (!stores.data || stores.data.filter((store) => store.IsSelected).length === 0))
      setPopoverOpen(true)
  }, [stores.loading, stores.data])

  type GroupedStores = {
    [key: string]: (Company & { IsSelected: boolean })[]
  }
  const groupedStores = newStores.reduce((acc: GroupedStores, store) => {
    const group = store.GroupName ?? 'No Group'

    if (!acc[group]) {
      acc[group] = []
    }
    acc[group].push(store)
    return acc
  }, {})

  return (
    <DropdownMenu
      open={popoverOpen}
      onOpenChange={(newValue: boolean) => {
        if (!stores.loading && (!stores.data || stores.data.filter((store) => store.IsSelected).length === 0)) return
        setPopoverOpen(newValue)
      }}
    >
      <DropdownMenuTrigger>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                variant='secondary'
                className='flex items-center gap-2 h-10 rounded-full md:rounded-md aspect-square md:aspect-auto bg-background'
              >
                <CubeIcon /> <span className='hidden md:block'>Change Stores</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side='bottom'>Change Stores</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-60'>
        <DropdownMenuLabel>Change Stores</DropdownMenuLabel>
        {Object.keys(groupedStores).map((groupName) => {
          return (
            <>
              <DropdownMenuSeparator key={`${groupName}-separator`} />
              <DropdownMenuLabel key={`${groupName}-label`} className='text-sm opacity-60'>
                {groupName}
              </DropdownMenuLabel>
              <DropdownMenuGroup key={`${groupName}-group`}>
                {groupedStores[groupName]?.map((store) => (
                  <DropdownMenuCheckboxItem
                    key={store.CompanyId}
                    className='cursor-pointer'
                    checked={store.IsSelected}
                    onCheckedChange={() => toggleStore(store.CompanyId)}
                    onSelect={(event: Event) => event.preventDefault()}
                  >
                    {store.Name}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuGroup>
            </>
          )
        })}
        <DropdownMenuSeparator />
        <Button
          variant='default'
          size='sm'
          onClick={() => {
            stores.setSelectedStores(newStores.filter((store) => store.IsSelected).map((store) => store.CompanyId))
            toast.info('Applied stores have been changed', {
              description: `Now using ${newStores.filter((store) => store.IsSelected).length} stores`
            })
            setPopoverOpen(false)
          }}
          className='w-full'
        >
          Apply
        </Button>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
