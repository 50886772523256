import { proper } from '@/lib/utils'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { TooltipProps } from 'recharts/types/component/Tooltip'
import { Card, CardContent, CardHeader, CardTitle, Separator } from '../ui'

type ChartTooltipProps = TooltipProps<ValueType, NameType> & {
  displayKeys: {
    key: string
    format: 'plain' | 'currency' | 'percent' | 'properText'
  }[]
}

export default function ChartTooltip({ active, payload, label, displayKeys }: ChartTooltipProps) {
  if (active && payload && payload.length) {
    const data = payload[0].payload
    const displayValues = displayKeys.map(({ key, format }, index) => {
      const keySplitByUpperCase = key.split(/(?=[A-Z])/)
      const keyProper = proper(keySplitByUpperCase.join(' '))
      let value = data[key]
      switch (format) {
        case 'currency':
          if (!value) value = '$0'
          else value = `$${value.toLocaleString()}`
          break
        case 'percent':
          if (!value) value = '0%'
          else value = `${value.toLocaleString()}%`
          break
        case 'properText':
          if (!value) value = 'N/A'
          else value = proper(value)
          break
        default:
          break
      }
      const element = (
        <>
          <div key={key} className='flex items-center justify-between space-x-6 text-sm'>
            <span>{keyProper}</span>
            <span>{value}</span>
          </div>
          {index < displayKeys.length - 1 && <Separator className='my-2' />}
        </>
      )
      return element
    })
    return (
      <Card>
        <CardHeader>
          <CardTitle>{label}</CardTitle>
        </CardHeader>
        <CardContent>{displayValues}</CardContent>
      </Card>
    )
  }

  return null
}
