import { cn } from '@/lib/utils'
import { CarIcon } from 'lucide-react'
import { InventoryFootprintItem } from '.'

export default function CarsSymbols({ item }: { item: InventoryFootprintItem }) {
  return (
    <>
      {Array.from({
        length: Math.max(1, item.unitsInStock - Math.round(item.amountOffset))
      }).map((_, index) => (
        <CarIcon
          className={cn('w-6 h-6 rounded-full mr-12', Math.round(item.unitsInStock) > index && ' fill-foreground')}
        />
      ))}
      {Math.round(item.amountOffset) > 0 &&
        item.unitsInStock > 1 &&
        Array.from({ length: Math.round(item.amountOffset) }).map((_) => (
          <CarIcon className={cn('w-6 h-6 rounded-full fill-red-500 stroke-red-500 mr-12')} />
        ))}
    </>
  )
}
