import { cn } from '@/lib/utils'
import { Payload, VerticalAlignmentType } from 'recharts/types/component/DefaultLegendContent'
import { LayoutType } from 'recharts/types/util/types'

type CustomLegendProps = {
  formatter?: (value: any) => string
  direction?: 'row' | 'column'
  alignX?: 'left' | 'right' | 'center'
  alignY?: 'top' | 'bottom' | 'middle'
}

export default function useRechartsLegendProps({
  formatter,
  direction,
  alignX = 'left',
  alignY = 'middle'
}: CustomLegendProps) {
  return {
    align: alignX,
    verticalAlign: alignY as VerticalAlignmentType,
    layout: direction === 'row' ? 'horizontal' : ('vertical' as LayoutType),
    iconSize: 10,
    content: (props: any) => {
      return props.payload ? (
        <CustomLegendContent formatter={formatter} payload={props.payload} direction={direction} />
      ) : null
    }
  }
}

type CustomLegendContentProps = {
  payload: Payload[]
  formatter?: (value: any) => string
  direction?: 'row' | 'column'
}

function CustomLegendContent({ payload, formatter, direction = 'row' }: CustomLegendContentProps) {
  if (!payload) return null
  return (
    <div
      className={cn(
        'w-full flex',
        direction === 'row' ? 'flex-row gap-3 justify-center' : 'flex-col gap-2 justify-end'
      )}
    >
      {payload.map((entry: any, index: number) => (
        <div className='flex items-center gap-2 mb-1' key={`legend-${index}`}>
          <div className='w-2 h-2 rounded-full' style={{ backgroundColor: entry.color }} />
          <span
            style={{
              color: 'var(--text-secondary)'
            }}
            className='whitespace-nowrap font-normal text-sm'
          >
            {formatter ? formatter(entry) : entry.value}
          </span>
        </div>
      ))}
    </div>
  )
}
