import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Input,
  InputOTP,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '@/components/ui/input-otp'
import { PhoneInput } from '@/components/ui/phone-input'
import useUser from '@/hooks/use-user'
import axios from '@/lib/axios'
import { Skeleton } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { type Value } from 'react-phone-number-input'
import Dropzone from 'react-dropzone'
import { UploadIcon } from 'lucide-react'
import { cn } from '@/lib/utils'

export default function Profile() {
  const user = useUser()
  const queryClient = useQueryClient()
  const [newPhoneNumber, setNewPhoneNumber] = useState<Value | undefined>(user.data?.PhoneNumber as Value | undefined)
  const [otp, setOtp] = useState<string | undefined>(undefined)
  const [otpError, setOtpError] = useState<string | undefined>(undefined)
  const [verificationOpen, setVerificationOpen] = useState(false)

  const [newUser, setNewUser] = useState(user.data as User)

  useEffect(() => {
    if (!user.data) return
    setNewUser(user.data as User)
  }, [user.data])

  const { mutateAsync: updateUser, isPending: userUpdatePending } = useMutation({
    mutationFn: async (data: {
      FirstName: string
      MiddleInitial: string | null
      LastName: string
      PhoneNumber: string
      TwoFactorMethod?: 'email' | 'phone'
      AllowEmailTwoFactor?: 0 | 1
      AllowPhoneTwoFactor?: 0 | 1
    }) => {
      if (!user.data?.UserId) return
      const response = await axios.patch('/users/' + user.data.UserId, data)
      return response.data
    },
    onSuccess: () => {
      setVerificationOpen(false)
      queryClient.invalidateQueries({
        queryKey: ['users']
      })
    }
  })

  const { mutateAsync: setPhoneVerification, isPending: phoneVerificationPending } = useMutation({
    mutationFn: async (data: { PhoneNumber: string; otp: string }) => {
      const response = await axios.patch('/auth/verify-phone/' + data.PhoneNumber + '/' + data.otp, data)
      return response.data
    },
    onSuccess: () => {
      setVerificationOpen(false)
      queryClient.invalidateQueries({
        queryKey: ['users']
      })
    }
  })

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // this will be an image
        const binaryStr = reader.result
        // convert to Buffer
        const uint8Array = new Uint8Array(binaryStr as ArrayBuffer)
        const buffer = Buffer.from(uint8Array)
        console.log('buffer')
        // set the buffer in the hidden input
        const hiddenInput = document.getElementById('new-image-buffer')
        if (hiddenInput) {
          hiddenInput.setAttribute('data-image-buffer', buffer.toString('base64'))
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  return (
    <main className='container flex flex-col gap-4 pt-4'>
      <Card>
        <CardHeader className='flex items-center justify-between flex-row'>
          <CardTitle>Your preferences</CardTitle>
          <Button
            loading={userUpdatePending}
            onClick={() => {
              updateUser({
                FirstName: newUser?.FirstName,
                MiddleInitial: newUser?.MiddleInitial,
                LastName: newUser?.LastName,
                PhoneNumber: String(newPhoneNumber),
                TwoFactorMethod: newUser?.TwoFactorMethod,
                AllowEmailTwoFactor: newUser?.AllowEmailTwoFactor,
                AllowPhoneTwoFactor: newUser?.AllowPhoneTwoFactor
              })
            }}
          >
            Save changes
          </Button>
        </CardHeader>
        <CardContent className='flex flex-col gap-12'>
          {user.loading ? (
            <Skeleton variant='rectangular' height={100} />
          ) : (
            <>
              <div className='flex items-center justify-evenly gap-4'>
                <div className='flex flex-col gap-2 items-center whitespace-nowrap w-full group relative'>
                  <Label>Your avatar</Label>
                  <Popover>
                    <PopoverTrigger>
                      <Avatar className='border w-20 h-20'>
                        <div
                          id='new-image-buffer'
                          className='text-sm hidden group-hover:flex absolute top-0 left-0 right-0 bottom-0  items-center justify-center bg-black/50 text-white cursor-pointer'
                        >
                          Change
                        </div>
                        <AvatarImage src={user.data?.Image} />
                        <AvatarFallback>{`${user.data?.FirstName[0].toUpperCase()}${user.data?.LastName[0].toUpperCase()}`}</AvatarFallback>
                      </Avatar>
                    </PopoverTrigger>
                    <PopoverContent>
                      <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div
                            {...getRootProps()}
                            className={cn(
                              'group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25',
                              'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                              isDragActive && 'border-muted-foreground/50'
                            )}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                                <div className='rounded-full border border-dashed p-3'>
                                  <UploadIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                                </div>
                                <p className='font-medium text-muted-foreground'>Drop the file here</p>
                              </div>
                            ) : (
                              <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                                <div className='rounded-full border border-dashed p-3'>
                                  <UploadIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                                </div>
                                <div className='space-y-px'>
                                  <p className='font-medium text-muted-foreground'>
                                    Drag {`'n'`} drop a file here, or click to select a file
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </PopoverContent>
                  </Popover>
                </div>
                <InputField
                  label='First name'
                  placeholder='First name'
                  value={newUser?.FirstName}
                  onChange={(e) => setNewUser({ ...newUser, FirstName: e.target.value })}
                  id='first-name'
                />
                <InputField
                  label='Middle Initial'
                  placeholder='Middle Initial'
                  value={newUser?.MiddleInitial as undefined | string}
                  onChange={(e) => setNewUser({ ...newUser, MiddleInitial: e.target.value })}
                  id='initial'
                />
                <InputField
                  label='Last name'
                  placeholder='Last name'
                  value={newUser?.LastName}
                  onChange={(e) => setNewUser({ ...newUser, LastName: e.target.value })}
                  id='last-name'
                />
              </div>
              <div className='flex items-end justify-evenly gap-4'>
                <div className='flex flex-col gap-2 whitespace-nowrap'>
                  <Label>Two-factor options</Label>
                  <TooltipProvider>
                    <div className='flex flex-col gap-4 mx-auto mr-8'>
                      <div className='flex items-center gap-2'>
                        <Switch
                          checked={Boolean(newUser?.AllowEmailTwoFactor)}
                          onCheckedChange={() => {
                            setNewUser({ ...newUser, AllowEmailTwoFactor: newUser?.AllowEmailTwoFactor ? 0 : 1 })
                          }}
                        />
                        <Label>Allow email</Label>
                      </div>
                      <div className='flex items-center gap-2'>
                        <Switch
                          checked={Boolean(newUser?.AllowPhoneTwoFactor)}
                          disabled={!user.data?.PhoneNumberVerified}
                          onCheckedChange={() => {
                            setNewUser({ ...newUser, AllowPhoneTwoFactor: newUser?.AllowPhoneTwoFactor ? 0 : 1 })
                          }}
                        />
                        <Label>Allow phone</Label>
                      </div>
                    </div>
                  </TooltipProvider>
                </div>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger className='w-full'>
                      <InputField
                        label='Email'
                        placeholder='Email'
                        value={user.data?.EmailAddress}
                        onChange={() => {}}
                        disabled
                      />
                    </TooltipTrigger>
                    <TooltipContent>You cannot change your email address</TooltipContent>
                  </Tooltip>
                </TooltipProvider>

                <div className='flex flex-col gap-2 w-full items-start'>
                  <Label>
                    Phone
                    {user.data?.PhoneNumberVerified ? (
                      <span className='text-green-600 ml-4'>Looks good! Your phone number is verified</span>
                    ) : (
                      user.data?.PhoneNumber &&
                      user.data.PhoneNumber.length > 0 && (
                        <span className='text-red-600 ml-4'>
                          You&apos;ll need to verify your phone number to enable SMS
                        </span>
                      )
                    )}
                  </Label>
                  <PhoneInput
                    className='w-full'
                    defaultCountry='US'
                    value={newPhoneNumber}
                    onChange={(value) => setNewPhoneNumber(value)}
                    placeholder='Phone number'
                  />
                </div>
                <AlertDialog
                  onOpenChange={(open) => {
                    setVerificationOpen(open)
                    if (!open) setOtp(undefined)
                  }}
                  open={verificationOpen}
                >
                  <AlertDialogTrigger>
                    <Button
                      variant='outline'
                      onClick={() => handleOTP(newPhoneNumber)}
                      disabled={
                        !newPhoneNumber || newPhoneNumber.length < 10 || Boolean(user.data?.PhoneNumberVerified)
                      }
                    >
                      Verify
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <CardHeader>
                      <CardTitle>Verify your phone number</CardTitle>
                    </CardHeader>
                    <CardContent>
                      {otpError && (
                        <Badge variant='destructive' className='mb-6 -mt-8'>
                          {otpError}
                        </Badge>
                      )}
                      {newPhoneNumber && newPhoneNumber.length >= 10 ? (
                        <div className='flex flex-col gap-4'>
                          <Label>Enter the verification code sent to {newPhoneNumber}</Label>
                          <InputOTP maxLength={6} value={otp} onChange={setOtp}>
                            <InputOTPGroup>
                              <InputOTPSlot index={0} />
                              <InputOTPSlot index={1} />
                              <InputOTPSlot index={2} />
                            </InputOTPGroup>
                            <InputOTPSeparator />
                            <InputOTPGroup>
                              <InputOTPSlot index={3} />
                              <InputOTPSlot index={4} />
                              <InputOTPSlot index={5} />
                            </InputOTPGroup>
                          </InputOTP>
                        </div>
                      ) : (
                        <p>Please enter a valid phone number, area code included (10 digits)</p>
                      )}
                    </CardContent>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <Button
                        disabled={!newPhoneNumber || newPhoneNumber.length < 10 || !otp || otp.length < 6}
                        onClick={checkOTP}
                        loading={phoneVerificationPending}
                      >
                        Confirm code
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </main>
  )
  async function checkOTP() {
    if (!newPhoneNumber || !otp) return
    try {
      const { data: isValid } = await axios.post(`/auth/verify-phone/${newPhoneNumber}/${otp}`)
      if (!isValid) return
      if (!user.data) return
      setPhoneVerification({ PhoneNumber: newPhoneNumber, otp })
      setVerificationOpen(false)
      queryClient.invalidateQueries({
        queryKey: ['users']
      })
    } catch (error: any) {
      setOtpError('There was an error verifying your phone number. Please try again.')
    }
  }
}

async function handleOTP(phoneNumber: string | null | undefined) {
  if (!phoneNumber) return
  await axios.post(`/auth/verify-phone/${phoneNumber}`)
  return
}

type InputFieldProps = {
  label: string
  value: string | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  disabled?: boolean
  id?: string
}

const InputField = ({ label, placeholder, value, onChange, disabled, id }: InputFieldProps) => (
  <div className='flex flex-col gap-2 w-full items-start'>
    <Label>{label}</Label>
    <Input placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} id={id} />
  </div>
)
