import useRechartsLegendProps from '@/hooks/use-recharts-legend-props'
import { cn } from '@/lib/utils'
import { SalesOverviewData } from '@/services/api/v2/companies/:company-id/analytics/sales-overview'
import { useMemo } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelProps,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'

type ByAgeStackedPieProps = {
  data: SalesOverviewData[] | undefined
  legendAlign?: 'left' | 'right' | 'center'
  title: string
  legendPosition?: 'top' | 'bottom' | 'middle'
  loading?: boolean
}
export default function ByAgeStackedPie({
  legendAlign = 'left',
  title,
  data,
  legendPosition = 'middle',
  loading
}: ByAgeStackedPieProps) {
  const legendProps = useRechartsLegendProps({
    formatter: (value) =>
      `${value.payload?.Count ?? 0} units ${
        data && data.length === 1 ? `(${value.payload?.PercentOfTotal ?? 0}%)` : ' '
      }`,
    direction: data && data.length > 1 ? 'row' : 'column',
    alignX: legendAlign,
    alignY: legendPosition
  })

  const CustomLabel = ({ viewBox }: LabelProps) => {
    return (
      <>
        <text
          // @ts-ignore
          x={viewBox.cx - title.length * 4}
          // @ts-ignore
          y={viewBox.cy + 8}
        >
          <tspan
            style={{
              fill: 'white',
              mixBlendMode: 'difference'
            }}
          >
            {title}
          </tspan>
        </text>
      </>
    )
  }

  const groupings: SalesOverviewData['SalesByAgeGraphData'][] = useMemo(() => {
    if (data) return data.map((entry) => entry.SalesByAgeGraphData)
    else return []
  }, [data])

  const Donut = () =>
    loading ? (
      <PieChart width={300} height={300} style={{ width: '100' }} className='opacity-40'>
        <Pie
          data={[
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' }
          ]}
          dataKey='Count'
          nameKey='label'
          cx='50%'
          cy='50%'
          outerRadius={100}
          animationDuration={0}
          animationBegin={0}
          className='animate-pulse'
        >
          {[
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' },
            { GroupName: 'Loading', Count: 1, Color: 'gray' }
          ]?.map((entry, index: number) => {
            return <Cell key={`cell-${index}`} fill={entry.Color} style={{ zIndex: -1, fill: entry.Color }} />
          })}
        </Pie>
        {window.innerWidth > 768 && (
          <Legend
            {...legendProps}
            content={() => {
              return (
                <div className={cn('w-full flex', 'flex-col gap-3 justify-end', 'animate-pulse')}>
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: 'gray' }} />
                    <div className='w-40 h-4 bg-stone-400 animate-pluse rounded-md'></div>
                  </div>
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: 'gray' }} />
                    <div className='w-40 h-4 bg-stone-400 animate-pluse rounded-md'></div>
                  </div>
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: 'gray' }} />
                    <div className='w-40 h-4 bg-stone-400 animate-pluse rounded-md'></div>
                  </div>
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: 'gray' }} />
                    <div className='w-40 h-4 bg-stone-400 animate-pluse rounded-md'></div>
                  </div>
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: 'gray' }} />
                    <div className='w-40 h-4 bg-stone-400 animate-pluse rounded-md'></div>
                  </div>
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: 'gray' }} />
                    <div className='w-40 h-4 bg-stone-400 animate-pluse rounded-md'></div>
                  </div>
                  <div className='flex items-center gap-2 mb-1'>
                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: 'gray' }} />
                    <div className='w-40 h-4 bg-stone-400 animate-pluse rounded-md'></div>
                  </div>
                </div>
              )
            }}
          />
        )}
      </PieChart>
    ) : (
      <PieChart width={300} height={300} style={{ width: '100' }}>
        <Pie
          data={groupings[0]}
          dataKey='Count'
          nameKey='label'
          cx='50%'
          cy='50%'
          outerRadius={100}
          innerRadius={80}
          animationDuration={800}
          animationBegin={0}
        >
          {groupings[0]?.map((entry, index: number) => {
            return <Cell key={`cell-${index}`} fill={entry.Color} style={{ zIndex: -1, fill: entry.Color }} />
          })}
          <Label value={title} content={CustomLabel} position='insideBottom' />
        </Pie>
        {window.innerWidth > 768 && <Legend {...legendProps} />}
      </PieChart>
    )

  const formattedData = groupings.map((item, index) => {
    const formattedObj = {
      name: data?.[index].Store
    }
    item.forEach((item) => {
      // @ts-ignore
      formattedObj[item.label] = item.PercentOfTotal
    })
    return formattedObj
  })

  const StackedBar = () => {
    return (
      <div style={{ width: '100%' }}>
        <h3 className='text-center'>{title}</h3>
        <BarChart
          width={500}
          height={300}
          style={{ width: '100%' }}
          data={formattedData}
          layout='vertical'
          margin={{ bottom: 0, top: 0, left: 0, right: 0 }}
          barSize={20}
        >
          <XAxis type='number' domain={[0, 100]} hide />
          <YAxis type='category' dataKey='name' allowDuplicatedCategory={false} />
          {groupings[0].map((entry, index) => (
            <Bar key={index} dataKey={entry.label} stackId='a' fill={entry.Color} />
          ))}
        </BarChart>
      </div>
    )
  }

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <ResponsiveContainer width='100%' height={250}>
        {data && data.length > 0 ? data.length <= 1 ? <Donut /> : <StackedBar /> : <div>No Data Available</div>}
      </ResponsiveContainer>
    </div>
  )
}
