import LinkWithQuery from '@/components/link-with-query'
import { Badge, Button, Input, Label, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui'
import { FormEvent, useState } from 'react'
import RegisterArt from '@/assets/images/register-art.jpg'
import axios from '@/lib/axios'
import { useQuery } from '@tanstack/react-query'
import { CheckSquare } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'

export default function Register() {
  const [successful, setSuccessful] = useState(false)
  const [error, setError] = useState('')
  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
    'confirm-password': '',
    fname: '',
    initial: '',
    lname: ''
  })

  const params = useSearchParams()
  const token = new URLSearchParams(params.toString()).get('token')

  const { data: EmailAddress } = useQuery({
    queryKey: ['email', token],
    queryFn: async () => {
      return (
        await axios.get('/users/email-from-token', {
          params: { token }
        })
      ).data
    }
  })

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget as HTMLFormElement
    const EmailAddress = form.email.value
    const FirstName = form.fname.value
    const MiddleInitial = form.initial.value
    const LastName = form.lname.value
    const Password = form.password.value
    const ConfirmPassword = form['confirm-password'].value
    if (Password !== ConfirmPassword) {
      setFormErrors({ ...formErrors, 'confirm-password': 'Passwords do not match' })
      return
    }
    if (Password.length < 8) {
      setFormErrors({ ...formErrors, password: 'Password must be at least 8 characters' })
      return
    }
    if (FirstName.length === 0) {
      setFormErrors({ ...formErrors, fname: 'First name is required' })
      return
    }
    if (LastName.length === 0) {
      setFormErrors({ ...formErrors, lname: 'Last name is required' })
      return
    }
    if (!Password.match(/[a-z]/)) {
      setFormErrors({ ...formErrors, password: 'Password must contain a lowercase letter' })
      return
    }
    if (!Password.match(/[A-Z]/)) {
      setFormErrors({ ...formErrors, password: 'Password must contain an uppercase letter' })
      return
    }
    if (!Password.match(/[0-9]/)) {
      setFormErrors({ ...formErrors, password: 'Password must contain a number' })
      return
    }
    if (!Password.match(/[^A-Za-z0-9]/)) {
      setFormErrors({ ...formErrors, password: 'Password must contain a special character' })
      return
    }
    try {
      await axios.put('/auth', {
        EmailAddress,
        FirstName,
        MiddleInitial,
        LastName,
        Password,
        inviteToken: token
      })
      setSuccessful(true)
    } catch (error: any) {
      setError(error.response.data)
    }
  }

  return (
    <div className='w-full lg:grid h-screen lg:grid-cols-2 '>
      <div className='flex items-center justify-center py-12 w-full lg:border-r'>
        <div className='mx-auto grid w-[350px] gap-6'>
          <div className='grid gap-2 text-center'>
            {error && <Badge variant='destructive'>{error}</Badge>}
            {successful ? (
              <h1 className='text-3xl font-bold'>
                <CheckSquare className='w-8 h-8 inline-block stroke-green-500' /> Account created!
              </h1>
            ) : (
              <h1 className='text-3xl font-bold'>Register</h1>
            )}
            {!successful && <p className='text-balance text-muted-foreground'>Let&apos;s get you signed up!</p>}
            <div className='grid gap-4'>
              {successful ? (
                <div className='opacity-80'>
                  You can now{' '}
                  <LinkWithQuery to='/login' className='text-blue-700 underline'>
                    log in
                  </LinkWithQuery>{' '}
                  with your new account!
                </div>
              ) : (
                <form className='grid gap-4' onSubmit={onSubmit}>
                  <div className='grid gap-2'>
                    <Label htmlFor='email' className='w-full text-start'>
                      Email {formErrors.email && <Badge variant='destructive'>{formErrors.email}</Badge>}
                    </Label>
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <Input
                            id='email'
                            type='email'
                            placeholder='m@example.com'
                            required
                            name='email'
                            value={EmailAddress ?? ''}
                            disabled
                          />
                        </TooltipTrigger>
                        <TooltipContent>
                          The email address was assigned in the invite and cannot be changed.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <div className='grid grid-cols-5 gap-2'>
                    <div className='grid gap-2 col-span-2'>
                      <Label htmlFor='fname' className='w-full text-start'>
                        First {formErrors.fname && <Badge variant='destructive'>{formErrors.fname}</Badge>}
                      </Label>
                      <Input id='fname' placeholder='John' required name='fname' />
                    </div>
                    <div className='grid gap-2'>
                      <Label htmlFor='initial' className='w-full text-start'>
                        M.I. {formErrors.initial && <Badge variant='destructive'>{formErrors.initial}</Badge>}
                      </Label>
                      <Input id='initial' placeholder='F' required name='initial' />
                    </div>
                    <div className='grid gap-2 col-span-2'>
                      <Label htmlFor='lname' className='w-full text-start'>
                        Last {formErrors.lname && <Badge variant='destructive'>{formErrors.lname}</Badge>}
                      </Label>
                      <Input id='lname' placeholder='Smith' required name='lname' />
                    </div>
                  </div>
                  <div className='grid gap-2'>
                    <Label htmlFor='password' className='w-full text-start'>
                      Password {formErrors.password && <Badge variant='destructive'>{formErrors.password}</Badge>}
                    </Label>
                    <Input
                      id='password'
                      placeholder='•••••••••••••••••••••••'
                      type='password'
                      required
                      name='password'
                    />
                  </div>
                  <div className='grid gap-2'>
                    <Label htmlFor='confirm-password' className='w-full text-start'>
                      Confirm Password{' '}
                      {formErrors['confirm-password'] && (
                        <Badge variant='destructive'>{formErrors['confirm-password']}</Badge>
                      )}
                    </Label>
                    <Input
                      id='confirm-password'
                      placeholder='•••••••••••••••••••••••'
                      type='password'
                      required
                      name='confirm-password'
                    />
                  </div>
                  <Button name='submit' type='submit' className='w-full'>
                    Finish and sign up
                  </Button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='hidden lg:flex bg-background items-center justify-center '>
        <img
          src={RegisterArt}
          alt='Image'
          width='1920'
          height='1080'
          className='h-fit w-full object-cover dark:brightness-[0.2] dark:grayscale'
        />
      </div>
    </div>
  )
}
