import { getSourcesByCompanyId } from '@/services/api/sources'
import { useQuery } from '@tanstack/react-query'

export default function useSources(CompanyId: SaleVehicle['CompanyId'][] | undefined, excludeHiddenFromSuggestions?: boolean) {

  const getSources = async (): Promise<VehicleSource[][]> => {
    let sources: VehicleSource[][] = []
    if (!CompanyId) return sources
    for (const id of CompanyId) {
      const currSources = await getSourcesByCompanyId(id, Boolean(excludeHiddenFromSuggestions))
      sources.push(currSources)
    }
    return sources
  }

  const { data, isPending, refetch } = useQuery({
    queryKey: ['sources', CompanyId],
    queryFn: getSources,
  })

  return { data, loading: isPending, refetch }
}