import useSources from '@/layouts/header/hooks/use-sources'
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Input,
  Label,
  Popover,
  PopoverTrigger,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import { PopoverClose, PopoverContent } from '@radix-ui/react-popover'
import { PencilLine, PlusSquare } from 'lucide-react'
import useDebounce from '@/hooks/use-debounce'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateSource } from '@/services/api/sources'
import { CircularProgress } from '@mui/material'
import { cn } from '@/lib/utils'

type SourcesCardProps = {
  CompanyId: number
}

export default function SourcesCards({ CompanyId }: SourcesCardProps) {
  const sources = useSources([CompanyId])
  const filter = useDebounce('', 500)

  const queryClient = useQueryClient()

  const {
    mutateAsync: updateSourceMutation,
    isPending,
    isError
  } = useMutation({
    mutationFn: (sourceDto: VehicleSource) => updateSource({ ...sourceDto, CompanyId }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['sources']
      })
  })

  const buildSource = (ev: React.FormEvent<HTMLFormElement>, VehicleSourceId: number) => {
    ev.preventDefault()
    const form = ev.currentTarget as HTMLFormElement
    const source: VehicleSource = {
      VehicleSourceId,
      SourceName: form.SourceName.value,
      IsZeroGrossSource: form.IsZeroGrossSource.checked,
      ExcludeDefault: form.ExcludeDefault.checked,
      HideFromSuggestions: form.HideFromSuggestions.checked
    } as VehicleSource
    updateSourceMutation(source)
  }

  return (
    <div className='flex flex-col gap-4'>
      <Popover>
        <PopoverTrigger>
          <Button variant='outline' className='w-full'>
            <PlusSquare className='opacity-60 mr-2' /> Add a new source
          </Button>
        </PopoverTrigger>
        <PopoverContent className='z-50'>
          <Card className='z-50'>
            <CardHeader>
              <CardTitle>Add a new source</CardTitle>
            </CardHeader>
            <form onSubmit={(ev) => ev.preventDefault()}>
              <CardContent className='flex flex-col gap-2'>
                <Label>Receiver Name</Label>
                <Input className='mb-2' name='ReceivingUserFullName' />
                <Label>Receiver Email Address</Label>
                <Input className='mb-2' name='ReceivingUserEmailAddress' />
                <Label>Receives Weekly Summary Report?</Label>
                <Switch className='mb-2' name='WeeklySummaryReport' />
                <Label>Receives Into The Weekend Report?</Label>
                <Switch className='mb-2' name='IntoTheWeekendReport' />
              </CardContent>
              <CardFooter className='flex justify-end gap-2'>
                <PopoverClose className='text-sm font-medium border shadow-sm rounded-md p-2'>
                  Cancel and discard changes
                </PopoverClose>
                <Button variant='default' type='submit'>
                  Save new source
                </Button>
              </CardFooter>
            </form>
          </Card>
        </PopoverContent>
      </Popover>
      <Label className='-mb-3'>Search...</Label>
      <Input placeholder='Filter sources' onChange={(ev) => filter.setValue(ev.target.value)} />
      <div className='grid grid-cols-3 gap-2 overflow-y-auto max-h-96'>
        <TooltipProvider>
          {sources?.data?.[0]
            ?.filter((source: VehicleSource) => source.SourceName.toUpperCase().includes(filter.value.toUpperCase()))
            .sort((a: VehicleSource) => (a.HideFromSuggestions ? 1 : -1))
            .map((source: VehicleSource) => (
              <Card key={source.VehicleSourceId} className={cn('flex flex-row items-center justify-between')}>
                <div className={cn('p-3', source.HideFromSuggestions && 'opacity-40')}>
                  <div className='text-ellipsis whitespace-nowrap max-w-48 overflow-x-hidden'>{source.SourceName}</div>
                  <div className='opacity-60 text-xs'>
                    {source.IsZeroGrossSource ? 'Marked as zero gross source' : <p>&nbsp;</p>}
                  </div>
                  <div className='opacity-60 text-xs'>
                    {source.ExcludeDefault ? 'Marked as default exclude' : <p>&nbsp;</p>}
                  </div>
                </div>
                <Popover>
                  <PopoverTrigger>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger>
                        <div className='p-3 opacity-60'>
                          <PencilLine className='w-4' />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent side='bottom'>Edit {source.SourceName}</TooltipContent>
                    </Tooltip>
                  </PopoverTrigger>
                  <PopoverContent className='z-50'>
                    <Card className='z-50'>
                      <CardHeader className='flex flex-row items-center gap-2'>
                        <CardTitle>Editting {source.SourceName}</CardTitle>
                        {isError && <Badge variant='destructive'>Error Saving!!</Badge>}
                        {isPending && (
                          <Badge variant='secondary'>
                            <CircularProgress className='mr-2' size={12} /> Saving Changes...
                          </Badge>
                        )}
                      </CardHeader>
                      <form onSubmit={(ev) => buildSource(ev, source.VehicleSourceId)}>
                        <CardContent className='flex flex-col gap-2'>
                          <Label>Source Name</Label>
                          <Input defaultValue={source.SourceName} className='mb-2' name='SourceName' />
                          <Label>Is zero gross source?</Label>
                          <Switch defaultChecked={source.IsZeroGrossSource} className='mb-2' name='IsZeroGrossSource' />
                          <Label>Is default exclude?</Label>
                          <Switch defaultChecked={source.ExcludeDefault} className='mb-2' name='ExcludeDefault' />
                          <Label>Hide from suggestions?</Label>
                          <Switch
                            defaultChecked={source.HideFromSuggestions}
                            className='mb-2'
                            name='HideFromSuggestions'
                          />
                        </CardContent>
                        <CardFooter className='flex justify-end gap-2'>
                          <PopoverClose className='text-sm font-medium border shadow-sm rounded-md p-2'>
                            Cancel and discard changes
                          </PopoverClose>
                          <Button loading={isPending} variant='default' type='submit'>
                            Save changes
                          </Button>
                        </CardFooter>
                      </form>
                    </Card>
                  </PopoverContent>
                </Popover>
              </Card>
            ))}
        </TooltipProvider>
      </div>
    </div>
  )
}
