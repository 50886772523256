import CrossCircledIcon from '@/assets/icons/cross-circled-icon'
import InfoCircledIcon from '@/assets/icons/info-circled-icon'
import OptionsIcon from '@/assets/icons/options-icon'
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { useState } from 'react'
import { toast } from 'sonner'
import ExcludeSources from './exclude-sources'

export default function ReportOptions() {
  const preferences = useLocalAppPreferences()
  const includeAdjustments = preferences.preferences.dashboard.useAdjustments
  const excludeSources = preferences.preferences.dashboard.excludeSources
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [sourcesOpen, setSourcesOpen] = useState(false)

  const [newSettings, setNewSettings] = useState({
    includeAdjustments: includeAdjustments,
    excludeSources: excludeSources
  })

  const toggleIncludeAdjustments = () => {
    const newIncludeAdjustments = !newSettings.includeAdjustments
    setNewSettings((prev) => ({ ...prev, includeAdjustments: newIncludeAdjustments }))
  }

  const setExcludeSources = (sources: number[]) => {
    preferences.setPreference('dashboard.excludeSources', sources)
    toast.info(
      <div className='flex flex-col gap-1'>
        <div className='flex gap-2 items-center'>
          <InfoCircledIcon />
          <span data-title>Applied new report settings</span>
        </div>
        <Badge variant='secondary'>Turned {includeAdjustments ? 'ON' : 'OFF'} adjustments</Badge>
        <Badge variant='secondary'>Excluded {sources.length} sources</Badge>
      </div>
    )
  }

  const applySettings = () => {
    preferences.setPreference('dashboard.useAdjustments', newSettings.includeAdjustments)
    setPopoverOpen(false)
    setSourcesOpen(false)
    document.body.style.pointerEvents = ''
    toast.info(
      <div className='flex flex-col gap-1'>
        <div className='flex gap-2 items-center'>
          <InfoCircledIcon />
          <span data-title>Applied new report settings</span>
        </div>
        <Badge variant='secondary'>Turned {newSettings.includeAdjustments ? 'ON' : 'OFF'} adjustments</Badge>
        <Badge variant='secondary'>Excluded {newSettings.excludeSources.length} sources</Badge>
      </div>
    )
  }

  return (
    <DropdownMenu open={popoverOpen} onOpenChange={setPopoverOpen}>
      <DropdownMenuTrigger>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                variant='secondary'
                className='flex items-center gap-2 h-10 rounded-full md:rounded-md aspect-square md:aspect-auto bg-background'
              >
                <OptionsIcon /> <span className='hidden md:block'>Report Options</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side='bottom'>Report Options</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-60 z-50'>
        <DropdownMenuLabel>Report Options</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          className='cursor-pointer'
          checked={newSettings.includeAdjustments}
          onCheckedChange={toggleIncludeAdjustments}
          onSelect={(event: Event) => event.preventDefault()}
        >
          Include Adjustments
        </DropdownMenuCheckboxItem>
        <DropdownMenuItem
          className='cursor-pointer flex items-center gap-2'
          onSelect={(event: Event) => {
            event.preventDefault()
            setSourcesOpen(true)
          }}
        >
          <CrossCircledIcon /> Exclude Sources
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <Button variant='default' className='w-full' onClick={applySettings}>
          Apply
        </Button>
        <ExcludeSources
          isOpen={sourcesOpen}
          onClose={() => setSourcesOpen(false)}
          onSave={(e) => {
            setExcludeSources(e)
            applySettings()
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
