import DatePicker from '@/components/date-picker'
import { Button, Input, Label, Popover, PopoverContent, PopoverTrigger, Separator, Switch } from '@/components/ui'
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import useSources from '@/layouts/header/hooks/use-sources'
import { cn } from '@/lib/utils'
import dayjs from 'dayjs'

type EditFormProps = {
  sale: SaleVehicle
  edits: SaleEdit[]
  setEdits: (edits: SaleEdit[]) => void
}
type SaleEdit = {
  field: keyof SaleVehicle
  oldValue: any
  newValue: any
}
export default function EditForm({ sale, edits, setEdits }: EditFormProps) {
  const changeField = (newValue: any, field: keyof SaleVehicle) => {
    const edit = edits.find((edit) => edit.field === field)
    if (edit) {
      setEdits(edits.map((edit) => (edit.field === field ? { ...edit, newValue } : edit)))
    } else {
      setEdits([...edits, { field, oldValue: sale[field], newValue }])
    }
  }

  const changeFields = (
    newValues: {
      [key: string]: any
    },
    fields: (keyof SaleVehicle)[]
  ) => {
    const copyArr = [...edits]
    fields.forEach((field) => {
      const edit = copyArr.find((edit) => edit.field === field)
      if (edit) {
        copyArr[copyArr.indexOf(edit)] = { ...edit, newValue: newValues[field] }
      } else {
        copyArr.push({ field, oldValue: sale[field], newValue: newValues[field] })
      }
    })
    setEdits(copyArr)
  }

  const availableYears = Array.from({ length: 50 }, (_, i) => {
    const year = dayjs().subtract(i, 'year').year()
    return { value: year, label: String(year) }
  })

  const sources = useSources([sale?.CompanyId])

  return (
    <form onSubmit={() => {}} className='flex flex-col gap-2'>
      <Label className='opacity-60 mt-4'>Vehicle Information</Label>
      <Separator className='my-1' />
      <div className='grid grid-cols-5 gap-4'>
        <FormField
          title='Stock Number'
          fieldName='StockNumber'
          onChange={changeField}
          placeholder='BZ239460'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Vin'
          fieldName='Vin'
          onChange={changeField}
          colSpan={'2'}
          placeholder='1GCRKSE31BZ239460'
          data={sale}
          edits={edits}
        />
        <div className='flex flex-col w-full gap-3 justify-start h-full'>
          <Label>Certified?</Label>
          <Switch
            onCheckedChange={(checked) => changeField(checked ? 1 : 0, 'IsCertified')}
            checked={edits.find((edit) => edit.field === 'IsCertified')?.newValue ?? sale?.IsCertified}
          />
        </div>
        <div className='flex flex-col item w-full gap-3 justify-start h-full'>
          <Label>Took a trade?</Label>
          <Switch
            onCheckedChange={(checked) => changeField(checked ? 1 : 0, 'TookATrade')}
            checked={edits.find((edit) => edit.field === 'TookATrade')?.newValue ?? sale?.IsCertified}
          />
        </div>
      </div>
      <div className='grid grid-cols-4 gap-4'>
        <FormSelect
          title='Year'
          fieldName='ModelYear'
          onChange={changeField}
          data={sale}
          edits={edits}
          placeholder='2021...'
          listItems={availableYears}
        />
        <FormField
          title='Make'
          fieldName='Make'
          onChange={changeField}
          placeholder='TOYOTA...'
          required
          data={sale}
          edits={edits}
        />
        <FormField
          title='Model'
          fieldName='Model'
          onChange={changeField}
          placeholder='CAMRY...'
          required
          data={sale}
          edits={edits}
        />
        <FormField
          title='Trim'
          fieldName='VehicleTrimLevel'
          onChange={changeField}
          placeholder='LE...'
          data={sale}
          edits={edits}
        />
      </div>
      <Label className='opacity-60 mt-4'>General Pricing</Label>
      <Separator className='my-1' />
      <div className='grid grid-cols-3 gap-4'>
        <FormField
          title='Gross'
          fieldName='Gross'
          onChange={changeField}
          placeholder='$...'
          required
          data={sale}
          edits={edits}
        />
        <FormSelect
          title='Source'
          fieldName='VehicleSourceId'
          onChange={changeField}
          placeholder='Source...'
          listItems={sources.data?.[0]?.map((source) => ({
            value: source.VehicleSourceId,
            label: source.SourceName
          }))}
          data={sale}
          edits={edits}
        />
        <FormField
          title='Odometer'
          fieldName='Odometer'
          onChange={changeField}
          placeholder='10000...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Start price'
          fieldName='InitialAskingPrice'
          onChange={changeField}
          placeholder='12345...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Sell price'
          fieldName='SellPrice'
          onChange={changeField}
          placeholder='23456...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Days to sell'
          fieldName='DaysInInventory'
          onChange={changeField}
          placeholder='4...'
          data={sale}
          edits={edits}
        />
      </div>
      <Label className='opacity-60 mt-4'>Market Pricing</Label>
      <Separator className='my-1' />
      <div className='grid grid-cols-4 gap-4'>
        <FormField
          title='Percent of market'
          fieldName='PercentOfMarket'
          onChange={changeField}
          placeholder='99...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='MSRP'
          fieldName='MSRP'
          onChange={changeField}
          placeholder='12345...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Initial MMR'
          fieldName='InitialManheimWholesale'
          onChange={changeField}
          placeholder='12345...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Final MMR'
          fieldName='FinalManheimWholesale'
          onChange={changeField}
          placeholder='23456...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Estimated Cost'
          fieldName='InitialACV'
          onChange={changeField}
          placeholder='12345...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Real Cost'
          fieldName='FinalACV'
          onChange={changeField}
          placeholder='23456...'
          data={sale}
          edits={edits}
        />
        <FormField
          title='Appraised Value'
          fieldName='AppraisedValue'
          onChange={changeField}
          placeholder='99...'
          data={sale}
          edits={edits}
        />
      </div>
      <Label className='opacity-60 mt-4'>Metadata</Label>
      <Separator className='my-1' />
      <div className='grid grid-cols-4 gap-x-4'>
        <FormField
          title='Notes'
          fieldName='Notes'
          onChange={changeField}
          colSpan='3'
          placeholder='in transit...'
          data={sale}
          edits={edits}
        />
        <div className='flex flex-col w-full gap-1'>
          <Label>Sold at</Label>
          <DatePicker
            date={edits.find((edit) => edit.field === 'SoldAt')?.newValue ?? sale?.SoldAt}
            setDate={(data: string) => changeField(dayjs(data).format('YYYY-MM-DD'), 'SoldAt')}
            size='sm'
          />
        </div>
      </div>
      <Label className='opacity-60 mt-4'>Rollback data</Label>
      <Separator className='my-1' />
      <div className='grid grid-cols-4 gap-x-4'>
        <div className='flex flex-col item w-full gap-3 justify-start h-full'>
          <Label>Rollback?</Label>
          <Switch
            onCheckedChange={(checked) => {
              console.log(
                'checked',
                checked,
                edits.find((edit) => edit.field === 'IsRollback')
              )
              if (checked) {
                changeFields({ IsRollback: true, RollbackDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD') }, [
                  'IsRollback',
                  'RollbackDate'
                ])
              } else {
                changeFields({ IsRollback: false, RollbackDate: null }, ['IsRollback', 'RollbackDate'])
              }
            }}
            checked={Boolean(
              edits.find((edit) => edit.field === 'IsRollback')
                ? edits.find((edit) => edit.field === 'IsRollback')?.newValue
                : sale?.IsRollback
            )}
          />
        </div>
        <div className='flex flex-col w-full gap-1'>
          <Label>Rollback date</Label>
          <DatePicker
            disabled={
              edits.find((edit) => edit.field === 'IsRollback')
                ? !edits.find((edit) => edit.field === 'IsRollback')?.newValue
                : !sale?.IsRollback
            }
            date={edits.find((edit) => edit.field === 'RollbackDate')?.newValue ?? sale?.RollbackDate}
            setDate={(data: string) => changeField(dayjs(data).format('YYYY-MM-DD'), 'RollbackDate')}
            size='sm'
          />
        </div>
      </div>
    </form>
  )
}

type FormFieldProps = {
  title: string
  fieldName: keyof SaleVehicle
  onChange: (newValue: any, field: keyof SaleVehicle) => void
  edits: SaleEdit[]
  colSpan?: '1' | '2' | '3' | '4'
  placeholder?: string
  required?: boolean
  data: SaleVehicle
}

var FormField = ({ title, fieldName, onChange, colSpan = '1', placeholder, required, data, edits }: FormFieldProps) => {
  let colspanString = ''
  if (colSpan === '2') {
    colspanString = 'col-span-2'
  } else if (colSpan === '4') {
    colspanString = 'col-span-4'
  } else if (colSpan === '3') {
    colspanString = 'col-span-3'
  } else {
    colspanString = 'col-span-1'
  }

  return (
    <div className={`flex flex-col w-full gap-1 ${colspanString}`}>
      <Label>
        {title}
        {required && <span className='text-red-500'>*</span>}
      </Label>
      <Input
        type='text'
        onChange={(ev) => {
          onChange(ev.target.value, fieldName)
        }}
        value={edits.find((edit) => edit.field === fieldName)?.newValue ?? data?.[fieldName]}
        placeholder={placeholder}
        size='sm'
      />
    </div>
  )
}

type FormSelectProps = Omit<FormFieldProps, 'register'> & {
  listItems: any[] | undefined
}

var FormSelect = ({
  title,
  fieldName,
  colSpan = '1',
  placeholder,
  listItems,
  onChange,
  data,
  edits
}: FormSelectProps) => {
  const colspanString = `col-span-${String(colSpan)}`
  const activeValue = edits.find((edit) => edit.field === fieldName)?.newValue ?? data?.[fieldName]
  const valueRef = listItems?.find((item) => item.value === activeValue)
  return (
    <div className={`flex flex-col w-full gap-1 ${colspanString}`}>
      <Label>
        {title} <span className='text-red-500'>*</span>
      </Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant='outline' className='w-full justify-start text-sm font-normal' size='sm'>
            <span className={cn('opacity-100', !valueRef && 'opacity-40')}>{valueRef?.label ?? placeholder}</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <Command>
            <CommandInput placeholder='Search...' />
            <CommandEmpty>No Options Found.</CommandEmpty>
            <CommandList>
              {listItems?.map((item) => (
                <CommandItem
                  className='cursor-pointer'
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    onChange(item.value, fieldName)
                  }}
                >
                  <span>{item.label}</span>
                </CommandItem>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}
