import LinkWithQuery from '@/components/link-with-query'
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui'

type NavButtonProps = {
  href: string
  onNavigate: () => void
  children: React.ReactNode
  title: string
}

export default function NavButton({ href, onNavigate, children, title }: NavButtonProps) {
  return (
    <div className='relative z-[999]'>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <LinkWithQuery to={href} aria-label={`${title} Nav Link`}>
              <Button
                aria-label={`${title} Nav Button`}
                variant='ghost'
                size='sm'
                onClick={onNavigate}
                className={`md:w-9 h-9 ${location.pathname === href ? 'bg-background' : ''}`}
              >
                {children} <span className='block md:hidden ml-3'>{title}</span>
              </Button>
            </LinkWithQuery>
          </div>
        </TooltipTrigger>
        <TooltipContent side='right' className='z-[999]'>
          {title}
        </TooltipContent>
      </Tooltip>
    </div>
  )
}
