import { Button, Input, Label, Switch } from '@/components/ui'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { cn } from '@/lib/utils'
import { useState } from 'react'
import AutomationTripLogIdInstructions from './automation-trip-log-id-instructions'

type AddAutomationPreferenceFormProps = {
  onSubmit: (event: HTMLFormElement, AllowOverrideRequiredFields: boolean) => void
}

export default function AddAutomationPreferenceForm({ onSubmit }: AddAutomationPreferenceFormProps) {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [AllowOverrideRequiredFields, setAllowOverrideRequiredFields] = useState(false)
  const validate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const form = ev.currentTarget as HTMLFormElement
    let hasError = false
    const errors: Record<string, string> = {}
    if (!form.ExistingCompareValue.value || !['Vin', 'StockNumber'].includes(form.ExistingCompareValue.value)) {
      errors.ExistingCompareValue = 'You must choose a value to compare'
      hasError = true
    }
    setErrors(errors)
    if (!hasError) {
      onSubmit(form, AllowOverrideRequiredFields)
    }
  }
  return (
    <form className='grid grid-cols-5 gap-4 items-center' onSubmit={validate}>
      <DisplayField label='Automation name' colspan={5}>
        <Input name='Name' id='Name' placeholder='My automation' />
      </DisplayField>
      <DisplayField label='Is Active'>
        <Switch name='IsActive' id='IsActive' checked={false} disabled />
      </DisplayField>
      <DisplayField label='Override required fields?' colspan={2}>
        <Switch
          name='AllowOverrideRequiredFields'
          id='AllowOverrideRequiredFields'
          checked={AllowOverrideRequiredFields}
          onCheckedChange={setAllowOverrideRequiredFields}
        />
      </DisplayField>
      <DisplayField colspan={2} label='Value to find existing inventory'>
        <RadioGroup name='ExistingCompareValue' id='ExistingCompareValue'>
          <div className='flex items-center space-x-2'>
            <RadioGroupItem value='Vin' />
            <Label>Use Vin to compare</Label>
          </div>
          <div className='flex items-center space-x-2'>
            <RadioGroupItem value='StockNumber' />
            <Label>Use Stock Number to compare</Label>
          </div>
        </RadioGroup>
        {errors.ExistingCompareValue && <span className='text-red-500 text-sm'>{errors.ExistingCompareValue}</span>}
      </DisplayField>
      <DisplayField label='Email for notifications' colspan={5}>
        <Input name='EmailAddressesOnFailed' id='EmailAddressesOnFailed' placeholder='example@m.com' />
      </DisplayField>
      <div className='flex flex-col gap-2 col-span-5 rounded-lg border p-2'>
        <div className='flex items-center space-x-2'>
          <span className='font-semibold'>Trip log google sheet ID</span>
          <AutomationTripLogIdInstructions />
        </div>
        <div className='flex gap-0 items-center'>
          <span className='opacity-80'>https://docs.google.com/spreadsheets/d/</span>
          <Input name='TripLogGoogleSheetId' id='TripLogGoogleSheetId' size='sm' className='h-6 pl-1' />
          <span className='opacity-80'>/edit</span>
        </div>
      </div>
      <Button type='submit' className='col-span-5' size='sm'>
        Create new automation
      </Button>
    </form>
  )
}

type DisplayFieldProps = {
  colspan?: number
  label: string
  children: React.ReactNode
}

const DisplayField = ({ label, children, colspan }: DisplayFieldProps) => {
  const colspanString = colspan ? `col-span-${colspan}` : ''
  return (
    <div className={cn('flex flex-col gap-2', colspanString)}>
      <span className='font-semibold'>{label}</span>
      {children}
    </div>
  )
}
