import Appraisal from '@/pages/appraisal'
import { RouteDefinition } from './routing'
import Dashboard from '@/pages/dashboard'
import Main from '@/pages/main'
import Sales from '@/pages/sales'
import Inventory from '@/pages/inventory'
import UpdateFromFile from '@/pages/update-from-file'
import Stores from '@/pages/stores'
import SendOutReports from '@/pages/:company-id/send-out-reports'
import Adjustments from '@/pages/adjustments'
import { ArchiveIcon, DesktopIcon, FileTextIcon, HomeIcon, PaperPlaneIcon } from '@radix-ui/react-icons'
import { LineChartIcon, PartyPopper, StoreIcon } from 'lucide-react'
import { Login } from '@/pages/login'
import Sale from '@/pages/sale'
import Profile from '@/pages/profile'
import ForgotPassword from '@/pages/forgot-password'
import ResetPassword from '@/pages/reset-password'
import InviteUser from '@/pages/invite-user'
import Register from '@/pages/register'

const routes: RouteDefinition[] = [
  {
    path: '/',
    component: Main,
    properties: {
      layout: {
        sidebar: 'hidden',
        header: 'base'
      },
      authentication: 'public',
      requiredUrlParams: [],
      navigation: {
        title: 'Main'
      }
    }
  },
  {
    path: '/login',
    component: Login,
    properties: {
      layout: {
        sidebar: 'hidden',
        header: 'hidden'
      },
      authentication: 'public',
      requiredUrlParams: [],
      navigation: {
        title: 'Login'
      }
    }
  },
  {
    path: '/register',
    component: Register,
    properties: {
      layout: {
        sidebar: 'hidden',
        header: 'hidden'
      },
      authentication: 'public',
      requiredUrlParams: [],
      navigation: {
        title: 'Register'
      }
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    properties: {
      layout: {
        sidebar: 'hidden',
        header: 'hidden'
      },
      authentication: 'public',
      requiredUrlParams: [],
      navigation: {
        title: 'Forgot Password'
      }
    }
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    properties: {
      layout: {
        sidebar: 'hidden',
        header: 'hidden'
      },
      authentication: 'public',
      requiredUrlParams: [],
      navigation: {
        title: 'Reset Password'
      }
    }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: ['startDate', 'endDate'],
      navigation: {
        title: 'Dashboard',
        icon: HomeIcon,
        sidebarGroup: 1
      }
    }
  },
  {
    path: '/invite-user',
    component: InviteUser,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: [],
      navigation: {
        title: 'Invite a user',
        icon: PartyPopper,
        sidebarGroup: 3
      }
    }
  },
  {
    path: '/sales',
    component: Sales,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: ['startDate', 'endDate'],
      navigation: {
        title: 'Sales',
        icon: DesktopIcon,
        sidebarGroup: 2
      }
    }
  },
  {
    path: '/inventory',
    component: Inventory,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: ['startDate', 'endDate'],
      navigation: {
        title: 'Inventory',
        icon: ArchiveIcon,
        sidebarGroup: 2
      }
    }
  },
  {
    path: '/adjustments',
    component: Adjustments,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: ['startDate', 'endDate'],
      navigation: {
        title: 'Adjustments',
        icon: LineChartIcon,
        sidebarGroup: 2
      }
    }
  },
  {
    path: '/appraisal',
    component: Appraisal,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: ['startDate', 'endDate'],
      navigation: {
        title: 'Appraisal',
        icon: FileTextIcon,
        sidebarGroup: 3
      }
    }
  },
  {
    path: '/file-update',
    component: UpdateFromFile,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: [],
      navigation: {
        title: 'Update from File'
      }
    }
  },
  {
    path: '/stores',
    component: Stores,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: [],
      navigation: {
        title: 'Stores',
        icon: StoreIcon
      }
    }
  },
  {
    path: '/:company-id/send-out-reports',
    component: SendOutReports,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: [],
      navigation: {
        title: 'Send Out Reports',
        icon: PaperPlaneIcon
      }
    }
  },
  {
    path: '/sale/:id',
    component: Sale,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: [],
      navigation: {
        title: 'Edit Sale'
      }
    }
  },
  {
    path: '/profile',
    component: Profile,
    properties: {
      layout: {
        sidebar: 'full',
        header: 'full'
      },
      authentication: 'private',
      requiredUrlParams: [],
      navigation: {
        title: 'Edit Sale'
      }
    }
  }
]

export { routes }
