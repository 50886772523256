import { Badge, Button, Input, Label } from '@/components/ui'
import { FormEvent, useState } from 'react'
import ResetPasswordArt from '@/assets/images/reset-password-art.jpg'
import axios from '@/lib/axios'
import { useMutation } from '@tanstack/react-query'
import { CheckSquare } from 'lucide-react'
import LinkWithQuery from '@/components/link-with-query'

export default function ResetPassword() {
  const token = new URLSearchParams(window.location.search).get('token')
  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget as HTMLFormElement
    const oldPassword = form.old.value
    const newPassword = form.new.value
    const confirmNewPassword = form['confirm-new'].value
    if (newPassword !== confirmNewPassword) {
      setError('Passwords do not match.')
      return
    }
    await mutateAsync({ token: token!!, oldPassword, newPassword })
  }

  const [successful, setSuccessful] = useState(false)
  const [error, setError] = useState('')
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: { token: string; oldPassword: string; newPassword: string }) => {
      return await axios.post('/users/password-reset', data)
    },
    onSuccess: () => {
      setSuccessful(true)
    },
    onError: () => {
      setError('An error occurred. Please try again.')
    }
  })

  return (
    <div className='w-full lg:grid h-screen lg:grid-cols-2 '>
      <div className='flex items-center justify-center py-12 w-full lg:border-r'>
        <div className='mx-auto grid w-[350px] gap-6'>
          {token ? (
            <div className='grid gap-2 text-center'>
              {error && <Badge variant='destructive'>{error}</Badge>}
              {successful ? (
                <h1 className='text-3xl font-bold'>
                  <CheckSquare className='w-8 h-8 inline-block stroke-green-500' /> Password reset!
                </h1>
              ) : (
                <h1 className='text-3xl font-bold'>Reset Password</h1>
              )}
              <div className='grid gap-4'>
                {successful ? (
                  <div className='opacity-80'>
                    Your password has been reset. You can now{' '}
                    <LinkWithQuery to='/login' className='text-primary underline'>
                      login
                    </LinkWithQuery>
                    .
                  </div>
                ) : (
                  <form className='grid gap-4' onSubmit={onSubmit}>
                    <div className='grid gap-2'>
                      <Label htmlFor='old' className='w-full text-start'>
                        Old password
                      </Label>
                      <Input id='old' type='password' placeholder='m@example.com' required name='old' />
                    </div>
                    <div className='grid gap-2'>
                      <Label htmlFor='new' className='w-full text-start'>
                        New password
                      </Label>
                      <Input id='new' type='password' placeholder='m@example.com' required name='new' />
                    </div>
                    <div className='grid gap-2'>
                      <Label htmlFor='confirm-new' className='w-full text-start'>
                        Confirm password
                      </Label>
                      <Input id='confirm-new' type='password' placeholder='m@example.com' required name='confirm-new' />
                    </div>
                    <Button name='submit' type='submit' className='w-full' loading={isPending}>
                      Reset password
                    </Button>
                  </form>
                )}
              </div>
            </div>
          ) : (
            <div className='grid gap-2 text-center'>
              <h1 className='text-3xl font-bold'>Oops!</h1>
              <p className='text-balance text-muted-foreground'>
                Looks like you accidentally ended up here! To reset your password, go{' '}
                <LinkWithQuery to='/forgot-password' className='text-primary underline'>
                  here
                </LinkWithQuery>{' '}
                and request a link to do so.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='hidden lg:flex bg-background items-center justify-center '>
        <img
          src={ResetPasswordArt}
          alt='Image'
          width='1920'
          height='1080'
          className='h-fit w-full object-cover dark:brightness-[0.2] dark:grayscale'
        />
      </div>
    </div>
  )
}
