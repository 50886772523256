import { routes } from '@/setup/routing/routes'
import { useLocation } from 'react-router-dom'
import NavButton from './components/nav-button'
import { Separator, TooltipProvider } from '@/components/ui'
import { RouteDefinition } from '@/setup/routing/routing'

enum SidebarVariant {
  Hidden,
  Full
}

export default function Sidebar() {
  const location = useLocation()
  const currentRouteObject = routes.find((route) => route.path === location.pathname)
  const isShown =
    currentRouteObject?.properties?.layout?.sidebar === 'hidden' ? SidebarVariant.Hidden : SidebarVariant.Full
  if (isShown === SidebarVariant.Hidden) return null

  const toggleSidebar = () => {
    const sidebar = document.querySelector('nav[data-open]')
    if (!sidebar) return
    sidebar.setAttribute('data-open', sidebar.getAttribute('data-open') === 'true' ? 'false' : 'true')
  }

  const groupedRoutes: RouteDefinition[][] = []
  for (const route of routes) {
    const navigation = route.properties.navigation
    if (!navigation.sidebarGroup) continue
    if (!groupedRoutes[navigation.sidebarGroup]) groupedRoutes[navigation.sidebarGroup] = []
    groupedRoutes[navigation.sidebarGroup].push(route)
  }

  return (
    <>
      <nav
        data-open='false'
        className={`flex flex-col gap-4 items-center justify-start h-full w-fit md:w-12 p-2 border-b border-r border-border bg-border fixed md:relative md:unset transition-transform overflow-x-visible z-[999] `}
      >
        <TooltipProvider delayDuration={0} disableHoverableContent>
          {groupedRoutes.map((group, index) => (
            <>
              {group.map((route) => (
                <NavButton
                  key={route.path + 'nav-button'}
                  href={route.path}
                  onNavigate={toggleSidebar}
                  title={route.properties.navigation.title}
                >
                  {route.properties.navigation.icon && <route.properties.navigation.icon />}
                </NavButton>
              ))}
              {index < groupedRoutes.length - 1 && (
                <Separator key={index + 'separator'} className='w-full bg-black/20' />
              )}
            </>
          ))}
        </TooltipProvider>
      </nav>
      <div
        id='navigation-backdrop'
        className='w-screen h-screen bg-black/20 fixed top-0 left-0 z-[99]'
        onClick={toggleSidebar}
      ></div>
    </>
  )
}
