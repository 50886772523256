import { v2 as axios } from '@/lib/axios'

export async function getAdjustmentsByCompanyIdWithDates(CompanyId: number | null | undefined, startDate: string, endDate: string) {
  if (!CompanyId) return []
  const data = await axios.get(`/companies/${CompanyId}/adjustments`, {
    params: {
      startDate,
      endDate
    }
  })
  return data.data
}

export async function insertAdjustment(CompanyId: number | null | undefined, data: AdjustmentDto) {
  await axios.post(`/companies/${CompanyId}/adjustments`, data)
}

export async function deleteAdjustment(CompanyId: number | null | undefined, AdjustmentId: number) {
  await axios.delete(`/companies/${CompanyId}/adjustments/${AdjustmentId}`)
}