import { InfiniteMovingCards } from './components/infinite-moving-cards'
import { HeroSection } from './components/section-hero'
import useCancelScroll from './hooks/use-cancel-scroll'

export default function Main() {
  useCancelScroll()
  const features = [
    {
      quote: 'Manage your inventory with consideration for age and sourcing',
      title: 'Inventory Management'
    },
    {
      quote: "Understand your dealership's market",
      title: 'Market Analysis'
    },
    {
      quote: 'Sell your inventory faster and for more profit',
      title: 'Sales Optimization'
    },
    {
      quote: 'See how your dealership is performing visually',
      title: 'Visual Reporting'
    },
    {
      quote: "Get a better understanding of your dealership's performance",
      title: 'Performance Metrics'
    }
  ]

  return (
    <div className='container'>
      <HeroSection />
      <InfiniteMovingCards items={features} direction='right' speed='slow' />
    </div>
  )
}
