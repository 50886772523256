import { v2 as axios } from "@/lib/axios"

export async function getStoresFromUserId(id: number | null | undefined) {
  try {
    if (!id) return null
    const stores = await axios.get('/companies')
    return stores.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}