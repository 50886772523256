import {
  Badge,
  Button,
  Card,
  CardContent,
  CardTitle,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Separator,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { cn } from '@/lib/utils'
import { ArrowRight, EllipsisVertical, PlayIcon, PlusSquareIcon, TriangleAlert } from 'lucide-react'
import AddAutomationPreferenceForm from './form-add-automation-preference'
import { useState } from 'react'
import UpdateAutomationPreferenceForm from './form-update-automation-preference'
import { conversionFnToSemantic, findAutomationMappingByName } from '../utils/automation'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import AddAutomationMappingForm from './form-add-automation-mapping'
import { useAutomations } from '../hooks/automation'
import AddAutomationRuleForm from './form-add-automation-rule'
import dayjs from 'dayjs'
import axios from '@/lib/axios'

type AutomationsListProps = {
  CompanyId: number
}

export default function AutomationsList({ CompanyId }: AutomationsListProps) {
  const [addAutomationDialogOpen, setAddAutomationDialogOpen] = useState(false)
  const [addAutomationMappingDialogOpen, setAddAutomationMappingDialogOpen] = useState(false)
  const [addAutomationRuleDialogOpen, setAddAutomationRuleDialogOpen] = useState(false)
  const { automationPreferences, automationMappings, automationRules, reload } = useAutomations(CompanyId)

  return (
    <div className='flex flex-col space-y-4'>
      <Card className='max-h-[48rem] overflow-auto'>
        <Accordion type='multiple' className='w-full'>
          {automationPreferences?.data?.map((automation: AutomationPreference) => (
            <AccordionItem
              key={automation.AutomationPreferenceId}
              title={automation.Name}
              value={String(automation.AutomationPreferenceId)}
            >
              <AccordionTrigger key={automation.AutomationPreferenceId} className='p-4'>
                <div className=' flex items-center justify-start space-x-4'>
                  <p>{automation.Name}</p>

                  <div className='opacity-70 flex gap-1 items-center'>
                    <div
                      className={cn('w-2 h-2 rounded-full ', automation.IsActive ? 'bg-green-500' : 'bg-red-500')}
                    ></div>
                    {automation.IsActive ? 'Active' : 'Inactive'}
                  </div>
                  {automation.IsActive && (
                    <p className='font-normal text-sm opacity-70'>
                      {automation.LastRunDate
                        ? `Last run on ${dayjs(automation.LastRunDate.toString()).format('MM/DD/YYYY  h:mm a')}`
                        : 'Has never run'}
                    </p>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <CardContent className='flex flex-col gap-4'>
                  <Card className='flex flex-row items-center gap-4 p-2'>
                    <Button
                      className='flex items-center gap-2'
                      size='sm'
                      variant='outline'
                      onClick={async () => {
                        await axios.post(`/automations/run/${automation.AutomationPreferenceId}`)
                        reload()
                      }}
                      disabled={dayjs().diff(dayjs(automation.LastRunDate), 'hour') < 1}
                    >
                      <PlayIcon className='w-4 h-4' /> <p>Run this automation</p>
                    </Button>
                    <p className='font-normal text-xs'>
                      {automation.LastRunDate
                        ? dayjs().diff(dayjs(automation.LastRunDate), 'hour') < 1
                          ? `Last run at ${dayjs(automation.LastRunDate.toString()).format(
                              'h:mm a'
                            )}. You can run this automation again at ${dayjs(automation.LastRunDate.toString())
                              .add(1, 'hour')
                              .format('h:mm a')}`
                          : `Last run on ${dayjs(automation.LastRunDate.toString()).format(
                              'MM/DD/YYYY h:mm a'
                            )} and ready to run again`
                        : 'This automation has never run'}
                    </p>
                  </Card>
                  <Card>
                    <CardContent>
                      <CardTitle className='mt-2'>Details</CardTitle>
                      <Separator className='my-2' />
                      <UpdateAutomationPreferenceForm
                        onSubmit={automationPreferences.update}
                        data={automation}
                        loading={automationPreferences.loading}
                      />
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <CardTitle className='mt-2 flex items-center gap-2'>
                        Mappings
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger className='flex items-center opacity-80'>
                              <QuestionMarkCircledIcon /> <p className='text-sm font-normal'>What is this?</p>
                            </TooltipTrigger>
                            <TooltipContent side='right'>
                              A mapping tells the automation which column from the spreadsheet belongs to which field on
                              the sale
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </CardTitle>
                      {!automation.AllowOverrideRequiredFields &&
                        !findAutomationMappingByName('Vin', automation.AutomationMappings) &&
                        !(
                          findAutomationMappingByName('ModelYear', automation.AutomationMappings) &&
                          findAutomationMappingByName('Make', automation.AutomationMappings) &&
                          findAutomationMappingByName('Model', automation.AutomationMappings)
                        ) && (
                          <div className='flex items-center gap-1'>
                            <TriangleAlert className='w-4 h-4 text-red-500' />
                            You must have a mapping for either
                            <Card className='p-1'>
                              <Badge>Vin</Badge>
                            </Card>
                            OR
                            <Card className='p-1'>
                              <Badge>Make</Badge> AND <Badge>Model</Badge> AND <Badge>Year</Badge>
                            </Card>
                          </div>
                        )}
                      {!automation.AllowOverrideRequiredFields &&
                        !findAutomationMappingByName(
                          automation.ExistingCompareValue,
                          automation.AutomationMappings
                        ) && (
                          <div className='flex items-center gap-1'>
                            <TriangleAlert className='w-4 h-4 text-red-500' />
                            You must have a mapping for
                            <Card className='p-1'>
                              <Badge>{automation.ExistingCompareValue}</Badge>
                            </Card>
                          </div>
                        )}
                      {!automation.AllowOverrideRequiredFields &&
                        !findAutomationMappingByName('Gross', automation.AutomationMappings) && (
                          <div className='flex items-center gap-1'>
                            <TriangleAlert className='w-4 h-4 text-red-500' />
                            You must have a mapping for
                            <Card className='p-1'>
                              <Badge>Gross</Badge>
                            </Card>
                          </div>
                        )}
                      {!automation.AllowOverrideRequiredFields &&
                        !findAutomationMappingByName('SoldAt', automation.AutomationMappings) && (
                          <div className='flex items-center gap-1'>
                            <TriangleAlert className='w-4 h-4 text-red-500' />
                            You must have a mapping for
                            <Card className='p-1'>
                              <Badge>SoldAt</Badge>
                            </Card>
                          </div>
                        )}
                      <Separator className='my-2' />
                      <div>
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>From column</TableHead>
                              <TableHead></TableHead>
                              <TableHead>Formatter</TableHead>
                              <TableHead></TableHead>
                              <TableHead>To field</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {automation.AutomationMappings.map((mapping) => (
                              <TableRow key={mapping.AutomationMappingId}>
                                <TableCell>{mapping.FromColumn}</TableCell>
                                <TableCell>
                                  <ArrowRight />
                                </TableCell>
                                <TableCell>
                                  {conversionFnToSemantic(mapping.ConvertionFn, mapping.FromColumn)}
                                </TableCell>
                                <TableCell>
                                  <ArrowRight />
                                </TableCell>
                                <TableCell>{mapping.ToKey}</TableCell>
                                <TableCell className='cursor-pointer hover:bg-secondary'>
                                  <EllipsisVertical />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell colSpan={6}>
                                <Dialog
                                  open={addAutomationMappingDialogOpen}
                                  onOpenChange={setAddAutomationMappingDialogOpen}
                                >
                                  <DialogTrigger className='w-full'>
                                    <Button variant='outline' className='w-full'>
                                      <PlusSquareIcon className='mr-2' /> Add new mapping
                                    </Button>
                                  </DialogTrigger>
                                  <DialogContent className='w-[56rem] max-w-none'>
                                    <DialogHeader>
                                      <DialogTitle>Add new mapping</DialogTitle>
                                    </DialogHeader>
                                    <AddAutomationMappingForm
                                      loading={automationMappings.loading}
                                      onSubmit={(form: HTMLFormElement) => {
                                        automationMappings.create(automation.AutomationPreferenceId, form)
                                        setAddAutomationMappingDialogOpen(false)
                                      }}
                                    />
                                  </DialogContent>
                                </Dialog>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <CardTitle className='mt-2'>Validation rules</CardTitle>
                      <Separator className='my-2' />
                      <div className='flex flex-col gap-2'>
                        {automation.AutomationRules.map((rule: AutomationRule) => {
                          const includeFields = Object.keys(rule).filter(
                            (key: any) =>
                              // @ts-ignore
                              rule[key] !== null &&
                              key !== 'AutomationPreferenceId' &&
                              key !== 'AutomationPreference' &&
                              key !== 'AutomationRuleId' &&
                              key !== 'FromColumn'
                          ) as (keyof AutomationRule)[]
                          const semanticConversions: Record<string, { text: string; appendValue: boolean }> = {
                            ValueIsNotEmpty: { text: 'Value is not empty', appendValue: false },
                            ValueIsEmpty: { text: 'Value is empty', appendValue: false },
                            ValueEqualTo: { text: 'Value is equal to', appendValue: true },
                            ValueNotEqualTo: { text: 'Value is not equal to', appendValue: true },
                            ValueGreaterThan: { text: 'Value is greater than', appendValue: true },
                            ValueLessThan: { text: 'Value is less than', appendValue: true },
                            BackgroundColorRed: { text: 'Background color has red value of', appendValue: true },
                            BackgroundColorGreen: { text: 'Background color has green value of', appendValue: true },
                            BackgroundColorBlue: { text: 'Background color has blue value of', appendValue: true }
                          }
                          const matchingMapping = automation.AutomationMappings.find(
                            (mapping) => mapping.FromColumn === rule.FromColumn
                          )
                          return (
                            <>
                              <div className='mb-2 grid grid-cols-3 gap-4 w-full'>
                                <p>
                                  Rule for column {rule.FromColumn} {matchingMapping && `(${matchingMapping.ToKey})`}
                                </p>
                                <div className='flex gap-2 col-span-2'>
                                  {includeFields.map((field: keyof AutomationRule) => (
                                    <Badge key={field} variant='secondary'>
                                      {semanticConversions[field].text}
                                      {semanticConversions[field].appendValue && ` ${rule[field]}`}
                                    </Badge>
                                  ))}
                                </div>
                              </div>
                              <Separator />
                            </>
                          )
                        })}
                        <Dialog open={addAutomationRuleDialogOpen} onOpenChange={setAddAutomationRuleDialogOpen}>
                          <DialogTrigger className='w-full'>
                            <Button variant='outline' className='w-full'>
                              <PlusSquareIcon className='mr-2' /> Add new validation rule
                            </Button>
                          </DialogTrigger>
                          <DialogContent className='w-[56rem] max-w-none'>
                            <DialogHeader>
                              <DialogTitle>Add new validation rule</DialogTitle>
                            </DialogHeader>
                            <AddAutomationRuleForm
                              loading={false}
                              onSubmit={(form: HTMLFormElement) => {
                                automationRules.create(automation.AutomationPreferenceId, form)
                                setAddAutomationRuleDialogOpen(false)
                              }}
                            />
                          </DialogContent>
                        </Dialog>
                      </div>
                    </CardContent>
                  </Card>
                </CardContent>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </Card>
      <Dialog open={addAutomationDialogOpen} onOpenChange={setAddAutomationDialogOpen}>
        <DialogTrigger>
          <Button variant='outline' className='w-full'>
            <PlusSquareIcon className='mr-2' /> Add new automation
          </Button>
        </DialogTrigger>
        <DialogContent className='w-[56rem] max-w-none'>
          <DialogHeader>
            <DialogTitle>Add new automation</DialogTitle>
          </DialogHeader>
          <AddAutomationPreferenceForm
            onSubmit={async (values, AllowOverrideRequiredFields) => {
              await automationPreferences.create(values, AllowOverrideRequiredFields)
              setAddAutomationDialogOpen(false)
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
