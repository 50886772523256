import { Card, CardContent, CardHeader, CardTitle, TableCell, TableRow } from '@/components/ui'

type FootprintTableExpandedRowProps = {
  item: any
}

export default function FootprintTableExpandedRow({ item }: FootprintTableExpandedRowProps) {
  const getVelocity = (item: any) => {
    if (item.averageSoldPerDay >= 1) {
      return `~${item.averageSoldPerDay} units per day.`
    } else {
      return `1 unit every ~${Math.round(1 / item.averageSoldPerDay)} days.`
    }
  }

  const getOptimalUnitCount = (item: any) => {
    return Math.round(-item.amountOffset + item.unitsInStock)
  }

  const getInvestmentDelta = (item: any) => {
    return Math.round(-item.amountOffset * item.averageInvestment).toLocaleString()
  }

  const getRevenueDelta = (item: any) => {
    return Math.round(-item.amountOffset * item.totalGross).toLocaleString()
  }

  let explanation = ''
  explanation += `${item.name}'s typically sell around ${getVelocity(item)} `
  explanation += `Since you have ${item.unitsInStock} units in stock, you have a ${item.daysSupply} days supply. `
  explanation += `To reach your target days supply of ${item.targetDaysSupply} days, you need to have `
  explanation += `${Math.round(Math.abs(item.amountOffset))} ${
    item.amountOffset < 0 ? 'more' : 'less'
  } units in stock, `
  explanation += `or a total of ${getOptimalUnitCount(item)} units. `
  explanation += `This will ${item.amountOffset < 0 ? 'increase' : 'decrease'} your investment by $${getInvestmentDelta(
    item
  )} for this model. `
  explanation += `This also creates a potential revenue ${
    -item.amountOffset * item.totalGross > 0 ? 'gain' : 'loss'
  } of $${getRevenueDelta(item)}, `
  explanation += `from an average of $${item.totalGross.toLocaleString()} per unit.`
  return (
    <TableRow>
      <TableCell colSpan={8}>
        <div className='grid grid-cols-6 space-x-2'>
          <Card>
            <CardHeader>
              <CardTitle>Velocity</CardTitle>
            </CardHeader>
            <CardContent>{getVelocity(item)}</CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Days Supply</CardTitle>
            </CardHeader>
            <CardContent>{item.daysSupply} days</CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Units in stock</CardTitle>
            </CardHeader>
            <CardContent>{item.unitsInStock}</CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Optimal unit count</CardTitle>
            </CardHeader>
            <CardContent>{getOptimalUnitCount(item)} units</CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Investment delta</CardTitle>
            </CardHeader>
            <CardContent>${getInvestmentDelta(item)}</CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Revenue delta</CardTitle>
            </CardHeader>
            <CardContent>${getRevenueDelta(item)}</CardContent>
          </Card>
        </div>
        <div className='p-4'>
          <span className='text-base opacity-70'>{explanation}</span>
        </div>
      </TableCell>
    </TableRow>
  )
}
