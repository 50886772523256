import { ResponsiveContainer, Bar, XAxis, YAxis, CartesianGrid, Cell, ComposedChart, Legend, Tooltip } from 'recharts'
import dayjs from 'dayjs'
import { CardHeader, CardTitle } from '../ui'
import { useEffect, useState } from 'react'
import { Skeleton } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import ChartTooltip from './chart-tooltip'

interface BarChartProps {
  data: any[][] | undefined
  dataKey: string
  valueKey: string
  loading?: boolean
  dataName?: string
  valueName?: string
  title?: string
  legendValues?: string[]
  tooltipValues?: {
    key: string
    format: 'plain' | 'currency' | 'percent' | 'properText'
  }[]
  hrefFormatter?: (value: any, index: number) => string | null
  cancelFormatTicks?: boolean
  tickRotation?: number
  hideY?: boolean
}

export default function BarChart({
  data,
  dataKey,
  valueKey,
  loading,
  dataName,
  valueName,
  title,
  legendValues,
  hrefFormatter,
  tooltipValues,
  cancelFormatTicks,
  tickRotation = -45,
  hideY
}: BarChartProps) {
  const [windowWidth, setWindowWidth] = useState<number>(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

  const navigate = useNavigate()

  return (
    <div className='w-full p-0 flex flex-col'>
      {title && (
        <CardHeader className='p-2 md:p-4'>
          <CardTitle className='font-semibold'>{title}</CardTitle>
        </CardHeader>
      )}
      <AnimatePresence mode='wait'>
        {loading ? (
          <motion.div
            key='skeleton-bar'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, type: 'tween', ease: 'easeInOut' }}
            style={{
              width: '100%',
              height: windowWidth > 768 ? 300 : 200
            }}
          >
            <Skeleton variant='rectangular' width='100%' height='100%' />
          </motion.div>
        ) : (
          <motion.div
            key='bar-chart'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, type: 'tween', ease: 'easeInOut' }}
            style={{
              width: '100%',
              height: windowWidth > 768 ? 300 : 200
            }}
          >
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart data={data} margin={{ bottom: 15, top: 0, left: 0, right: 0 }}>
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                <XAxis
                  dataKey={dataKey}
                  name={dataName}
                  style={{ fontSize: '0.75rem' }}
                  tickFormatter={(value) => (cancelFormatTicks ? value : dayjs(value).format('MMM D'))}
                  angle={tickRotation}
                  tickMargin={10}
                  allowDuplicatedCategory={false}
                />
                <YAxis width={30} hide={windowWidth > 768 ? (hideY ? true : false) : true} stroke='black' />
                {tooltipValues && (
                  <Tooltip content={(props) => <ChartTooltip displayKeys={tooltipValues} {...props} />} />
                )}
                {data?.map((item, superIndex) => (
                  <Bar
                    key={superIndex}
                    data={item}
                    dataKey={valueKey}
                    fill={`hsl(${221.2 - 40 * superIndex} 83.2% 53.3%)`}
                    barSize={10}
                    radius={[4, 4, 0, 0]}
                    name={valueName}
                  >
                    {item?.map((dataItem: any, index: number) => {
                      return (
                        <Cell
                          key={`cell-${index}`}
                          style={{
                            cursor: hrefFormatter?.(dataItem, superIndex) ? 'pointer' : 'default'
                          }}
                          onClick={() => {
                            if (!hrefFormatter || !dataItem || hrefFormatter(dataItem, superIndex) === null) return
                            const href = hrefFormatter(dataItem, superIndex)
                            if (!href) return
                            navigate(href)
                          }}
                        />
                      )
                    })}
                  </Bar>
                ))}
                <Legend
                  align='right'
                  verticalAlign='top'
                  height={36}
                  content={() => {
                    return (
                      <div className='flex flex-wrap gap-4 justify-end'>
                        {legendValues?.map((value, index) => (
                          <div key={index} className='flex items-center gap-1'>
                            <div
                              className='w-2 h-2 rounded-full'
                              style={{ backgroundColor: `hsl(${221.2 - 40 * index} 83.2% 53.3%)` }}
                            ></div>
                            <span>{value}</span>
                          </div>
                        ))}
                      </div>
                    )
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
