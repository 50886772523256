import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input
} from '@/components/ui'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { v2 as axios } from '@/lib/axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

type StoreDetailsProps = {
  company: Company
}

export default function StoreDetails({ company }: StoreDetailsProps) {
  const { data: states } = useQuery({
    queryKey: ['states'],
    queryFn: async () => {
      const response = await axios.get('/states')
      return response.data
    }
  })

  const [newCompany, setNewCompany] = useState<Company>(company)

  useEffect(() => {
    setNewCompany(company)
  }, [company])

  type CargurusOption =
    | {
        value: '0'
        label: 'Great price'
      }
    | {
        value: '1'
        label: 'Good price'
      }
    | {
        value: '2'
        label: 'Fair price'
      }
    | {
        value: '3'
        label: 'High price'
      }
    | {
        value: '4'
        label: 'Overpriced'
      }

  const cargurusOptions: CargurusOption[] = [
    {
      value: '0',
      label: 'Great price'
    },
    {
      value: '1',
      label: 'Good price'
    },
    {
      value: '2',
      label: 'Fair price'
    },
    {
      value: '3',
      label: 'High price'
    },
    {
      value: '4',
      label: 'Overpriced'
    }
  ]

  const setCompanyValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setNewCompany({
      ...newCompany,
      [ev.target.name]: ev.target.value
    })
  }

  const queryClient = useQueryClient()

  const { mutateAsync: updateStore, isPending } = useMutation({
    mutationFn: async () => {
      const companyUpdateDto = {
        AmbiguousAlias: newCompany.AmbiguousAlias,
        DefaultCarGurusPreference: newCompany.DefaultCarGurusPreference,
        City: newCompany.City,
        LogicalId: newCompany.LogicalId,
        Name: newCompany.Name,
        StreetAddress1: newCompany.StreetAddress1,
        StreetAddress2: newCompany.StreetAddress2,
        TargetMargin: newCompany.TargetMargin,
        ZipCode: newCompany.ZipCode,
        StateId: newCompany.StateId,
        FranchiseMake: newCompany.FranchiseMake
      }
      const response = await axios.patch(`/companies/${newCompany.CompanyId}`, companyUpdateDto)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['companies']
      })
    }
  })

  const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    await updateStore()
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant='outline'>Edit store details</Button>
      </DialogTrigger>
      <DialogContent className='w-[56rem] max-w-none'>
        <form className='space-y-4' onSubmit={onSubmit}>
          <DialogHeader className='flex items-center justify-between flex-row mt-2'>
            <DialogTitle>Store details for {company.Name}</DialogTitle>
            <Button type='submit' loading={isPending}>
              Save changes
            </Button>
          </DialogHeader>
          <Card>
            <CardHeader>
              <CardTitle>Basic details</CardTitle>
            </CardHeader>
            <CardContent className='grid grid-cols-4 gap-4'>
              <FormField label='Name'>
                <Input type='text' value={newCompany.Name} onChange={setCompanyValue} name='Name' />
              </FormField>
              <FormField label='Ambiguous alias'>
                <Input type='text' value={newCompany.AmbiguousAlias} onChange={setCompanyValue} name='AmbiguousAlias' />
              </FormField>
              <FormField label='Logical id (vAuto)'>
                <Input type='text' value={newCompany.LogicalId} onChange={setCompanyValue} name='LogicalId' />
              </FormField>
              <FormField label='State'>
                <Select
                  defaultValue={String(newCompany.StateId)}
                  onValueChange={(value) => {
                    setNewCompany({
                      ...newCompany,
                      StateId: Number(value)
                    })
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select a state' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>States</SelectLabel>
                      {states?.map((state: StateData) => (
                        <SelectItem key={state.StateId} value={String(state.StateId)}>
                          {state.FullName}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormField>
              <FormField label='City'>
                <Input type='text' value={newCompany.City} onChange={setCompanyValue} name='City' />
              </FormField>
              <FormField label='Street address'>
                <Input
                  type='text'
                  value={newCompany.StreetAddress1 ?? ''}
                  onChange={setCompanyValue}
                  name='StreetAddress1'
                />
              </FormField>
              <FormField label='Street address 2'>
                <Input
                  type='text'
                  value={newCompany.StreetAddress2 ?? ''}
                  onChange={setCompanyValue}
                  name='StreetAddress2'
                />
              </FormField>
              <FormField label='Zip code'>
                <Input type='text' value={newCompany.ZipCode} onChange={setCompanyValue} name='ZipCode' />
              </FormField>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Pricing preferences</CardTitle>
            </CardHeader>
            <CardContent className='grid grid-cols-3 gap-4'>
              <FormField label='Target margin'>
                <Input type='text' value={newCompany.TargetMargin} onChange={setCompanyValue} name='TargetMargin' />
              </FormField>
              <FormField label='Default Cargurus price level'>
                <Select
                  defaultValue={String(newCompany.DefaultCarGurusPreference)}
                  onValueChange={(value) => {
                    const newValue = value === '0' ? 0 : value === '1' ? 1 : value === '2' ? 2 : value === '3' ? 3 : 4
                    setNewCompany({
                      ...newCompany,
                      DefaultCarGurusPreference: newValue
                    })
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder='Select a level' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>States</SelectLabel>
                      {cargurusOptions.map((option: CargurusOption) => (
                        <SelectItem key={option.value} value={String(option.value)}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormField>
              <FormField label='Franchise make'>
                <Input
                  type='text'
                  value={newCompany.FranchiseMake ?? ''}
                  onChange={(ev) => {
                    setNewCompany({
                      ...newCompany,
                      FranchiseMake: ev.target.value?.toUpperCase()
                    })
                  }}
                  name='FranchiseMake'
                />
              </FormField>
            </CardContent>
          </Card>
        </form>
      </DialogContent>
    </Dialog>
  )
}

type FormFieldProps = {
  label: string | undefined | null
  children: React.ReactNode
}

function FormField({ label, children }: FormFieldProps) {
  return (
    <div className='flex flex-col gap-2'>
      <label>{label}</label>
      {children}
    </div>
  )
}
