import { InventoryFootprintItem } from '.'

export default function StockingIndicator({ item }: { item: InventoryFootprintItem }) {
  if (Math.round(item.amountOffset) > 0 && item.unitsInStock <= 1) {
    return <span className='text-red-500'>Historically, this unit will take a while to sell</span>
  }
  if (Math.round(item.amountOffset) === 0) {
    return 'You have the right amount of units'
  }
  if (item.amountOffset > 0) {
    return (
      <span>
        <p>You have {Math.abs(Math.round(item.amountOffset))} too many units. </p>
        <p>
          Reallocation potential: ${Math.round(Math.abs(item.amountOffset * item.averageInvestment)).toLocaleString()}
        </p>
      </span>
    )
  }
  return (
    <span>
      <p>You have {Math.abs(Math.round(item.amountOffset))} too few units. </p>
      <p>
        Additional investment needed: $
        {Math.round(Math.abs(item.amountOffset * item.averageInvestment)).toLocaleString()}
      </p>
    </span>
  )
}
