import { createContext, useState } from 'react'
import { getStoresFromUserId } from '../api/stores'
import useUser from '@/hooks/use-user'
import { useQuery } from '@tanstack/react-query'

export type StoreContext = Company & {
  IsSelected: boolean
}

declare interface StoreContextType {
  data: StoreContext[] | null
  loading: boolean
  toggleStore: (storeId: number) => void
  toggleStores: (storeIds: number[]) => void
  setSelectedStores: (storeIds: number[]) => void
}

export const StoreContext = createContext({
  data: null,
  loading: true,
  toggleStore: () => {},
  toggleStores: () => {},
  setSelectedStores: () => {}
} as StoreContextType)

type StoreContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

export default function StoreContextProvider({ children }: StoreContextProviderProps) {
  const [stores, setStores] = useState<StoreContext[]>([])

  const user = useUser()

  const toggleStore = (storeId: number) => {
    const selectedStores = localStorage.getItem('SelectedStores')
    const newSelectedStores = selectedStores ? JSON.parse(selectedStores) : []

    if (newSelectedStores.includes(storeId)) {
      newSelectedStores.splice(newSelectedStores.indexOf(storeId), 1)
    } else {
      newSelectedStores.push(storeId)
    }

    localStorage.setItem('SelectedStores', JSON.stringify(newSelectedStores))

    setStores((prevStores) => {
      return prevStores.map((store) => {
        if (store.CompanyId === storeId) {
          store.IsSelected = !store.IsSelected
        }
        return store
      })
    })
  }

  const toggleStores = (storeIds: number[]) => {
    const selectedStores = localStorage.getItem('SelectedStores')
    const newSelectedStores = selectedStores ? JSON.parse(selectedStores) : []

    storeIds.forEach((storeId) => {
      if (newSelectedStores.includes(storeId)) {
        newSelectedStores.splice(newSelectedStores.indexOf(storeId), 1)
      } else {
        newSelectedStores.push(storeId)
      }
    })

    localStorage.setItem('SelectedStores', JSON.stringify(newSelectedStores))

    setStores((prevStores) => {
      return prevStores.map((store) => {
        if (storeIds.includes(store.CompanyId)) {
          store.IsSelected = !store.IsSelected
        }
        return store
      })
    })
  }

  const setSelectedStores = (storeIds: number[]) => {
    localStorage.setItem('SelectedStores', JSON.stringify(storeIds))

    setStores((prevStores) => {
      return prevStores.map((store) => {
        store.IsSelected = storeIds.includes(store.CompanyId)
        return store
      })
    })
  }

  const getData = async () => {
    if (user.loading || !user.data?.UserId) return []
    const stores: StoreContext[] = await getStoresFromUserId(user.data.UserId)
    const selectedStores = localStorage.getItem('SelectedStores')
    if (selectedStores) {
      try {
        stores.forEach((store) => {
          const isSelected = JSON.parse(selectedStores)?.includes(store.CompanyId)
          store.IsSelected = isSelected
        })
      } catch (error) {
        console.error('Error parsing selected stores', error)
        localStorage.removeItem('SelectedStores')
      }
    } else {
      stores.forEach((store) => {
        store.IsSelected = false
      })
    }
    setStores(stores)
    return stores
  }

  const { status } = useQuery({
    queryKey: ['stores', user.data?.UserId],
    queryFn: getData,
    refetchInterval: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  })

  return (
    <StoreContext.Provider
      value={{
        data: stores,
        loading: status === 'pending' || user.loading,
        toggleStore,
        toggleStores,
        setSelectedStores
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
