export default function LogoIcon() {
  return (
    <svg viewBox='0 0 1080 1080' fill='none'>
      <rect width='1080' height='1080' rx='96' className='fill-background' />
      <path
        d='M552.902 499.963C553.258 497.345 555.72 495.133 556.906 494.354C527.098 779.502 326.893 957.135 77.75 903.845C399.501 870.562 528.581 620.723 552.902 499.963Z'
        fill='white'
        className='invert'
      />
      <path
        d='M78.1949 903.845C284.183 950.591 511.971 829.052 556.906 494.821C568.029 666.377 394.518 1007.15 78.1949 903.845Z'
        fill='#EEEFF2'
        className='invert'
      />
      <path
        d='M572.478 383.567C409.823 149.839 202.173 237.253 118.681 310.176C298.865 27.8323 596.502 177.418 676.139 326.537L996.911 918.804H865.221L572.478 383.567Z'
        fill='white'
        className='invert'
      />
      <path d='M996.021 918.804H864.776L871.45 929.088H1002.25L996.021 918.804Z' fill='#EEEFF2' className='invert' />
    </svg>
  )
}
