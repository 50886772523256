import { Button, Card, CardContent, CardHeader, CardTitle, Input, Label } from '@/components/ui'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import useStores from '@/hooks/use-stores'
import axios from '@/lib/axios'
import { AnimatePresence, motion } from 'framer-motion'
import { ArrowLeftCircle, CheckCircle2 } from 'lucide-react'
import { useState } from 'react'

export default function InviteUser() {
  const [validNewEmail, setValidNewEmail] = useState(false)
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [authorizations, setAuthorizations] = useState<
    {
      CompanyId: number
      PermissionLevel: number
    }[]
  >([])

  const checkEmail = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    setError('')
    try {
      const { data: user } = await axios.get('/users/by-email', { params: { EmailAddress: email } })
      if (user.UserId) {
        setValidNewEmail(false)
        setError('A user with this email address already exists!')
      }
    } catch (err: any) {
      if (err?.response?.data?.message === 'No user found') {
        setValidNewEmail(true)
      }
    }
  }

  const stores = useStores()

  const [loading, setLoading] = useState(false)
  const [inviteSent, setInviteSent] = useState(false)

  const sendInvite = async () => {
    try {
      setLoading(true)
      await axios.post('/users/invite', {
        EmailAddress: email,
        Authorizations: authorizations
      })
      setInviteSent(true)
    } catch (err: any) {
      setError(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='container pt-4'>
      <Card>
        {inviteSent ? (
          <CardHeader>
            <CardTitle>Invite sent!</CardTitle>
            <CardContent className='flex justify-center items-center gap-4 opacity-80'>
              <CheckCircle2 className='w-12 h-12 text-green-600 animate-pulse' />
              <p>
                An email has been sent to <strong>{email}</strong> with an invitation to join your team.
              </p>
            </CardContent>
          </CardHeader>
        ) : (
          <>
            <CardHeader>
              <CardTitle>Invite someone to join your team</CardTitle>
            </CardHeader>
            <CardContent>
              <Card>
                <CardHeader>
                  <CardTitle>What is the email address of this new user?</CardTitle>
                  <form onSubmit={checkEmail}>
                    <CardContent>
                      <Input
                        type='email'
                        placeholder='Email address'
                        value={email}
                        onChange={(ev) => setEmail(ev.target.value)}
                        disabled={validNewEmail}
                        required
                      />
                      {error && <p className='text-red-500 text-sm'>{error}</p>}
                      <div className='flex gap-2 items-center mt-4 -mb-8'>
                        <Button type='submit' variant='outline' className='ml-2' disabled={!email || validNewEmail}>
                          Check email
                        </Button>
                        {!validNewEmail && (
                          <p className='text-sm opacity-80'>
                            <ArrowLeftCircle className='inline-block w-4 h-4 mr-1 stroke-red-600' />
                            Let&apos;s check to see if this person has an account already{' '}
                          </p>
                        )}
                        {validNewEmail && (
                          <p className='text-sm opacity-80'>
                            <CheckCircle2 className='inline-block w-4 h-4 mr-1 stroke-green-600' />
                            Looks good!
                          </p>
                        )}
                      </div>
                    </CardContent>
                  </form>
                </CardHeader>
              </Card>
              <AnimatePresence mode='wait'>
                {validNewEmail && (
                  <motion.div className='mt-4' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <Card className='mt-4'>
                      <CardHeader>
                        <CardTitle>What stores should they have access to?</CardTitle>
                        <CardContent>
                          {stores.data
                            .filter((store) => store.PermissionLevel > 1)
                            .map((store) => (
                              <div key={store.CompanyId} className='flex flex-col gap-1 mb-4'>
                                <Label>{store.Name}</Label>
                                <Select
                                  onValueChange={(newValue) => {
                                    setAuthorizations((prev) => {
                                      const idx = prev.findIndex((auth) => auth.CompanyId === store.CompanyId)
                                      if (idx === -1) {
                                        return [
                                          ...prev.filter((e) => e.PermissionLevel >= 0),
                                          { CompanyId: store.CompanyId, PermissionLevel: Number(newValue) }
                                        ]
                                      }
                                      prev[idx].PermissionLevel = Number(newValue)
                                      return [...prev.filter((e) => e.PermissionLevel >= 0)]
                                    })
                                  }}
                                >
                                  <SelectTrigger>
                                    <SelectValue placeholder='No access' />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      <SelectItem value='-1'>No access</SelectItem>
                                    </SelectGroup>
                                    <SelectGroup>
                                      <SelectItem value='0'>Ambiguous (store names redacted)</SelectItem>
                                      <SelectItem value='1'>View</SelectItem>
                                      <SelectItem value='2'>Edit</SelectItem>
                                      <SelectItem value='3'>Administrator</SelectItem>
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            ))}
                        </CardContent>
                      </CardHeader>
                    </Card>
                  </motion.div>
                )}
              </AnimatePresence>
              <Button
                className='mt-4'
                onClick={sendInvite}
                disabled={!validNewEmail || authorizations.filter((auth) => auth.PermissionLevel >= 0).length === 0}
                loading={loading}
              >
                Send invite
              </Button>
            </CardContent>
          </>
        )}
      </Card>
    </div>
  )
}
