import axios from "@/lib/axios"

export async function getInventoryByCompanyId(id: number | null | undefined) {
  try {
    if (!id) return null
    const stores = await axios.get('/inventory', {
      params: {
        CompanyId: id,
      }
    })
    return stores.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}

export async function insertInventoryDto(inventoryDto: SaleVehicleDto) {
  const response = await axios.put('/inventory', inventoryDto)
  return response.data
}

export async function getInventoryByVin(Vin: string) {
  if (!Vin) return null
  if (Vin.length !== 17) return null
  try {
    const inventory = await axios.get('/inventory/one', {
      params: {
        Vin,
      }
    })
    return inventory.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}

export async function getInventoryById(SaleVehicleId: number) {
  if (!SaleVehicleId) return null
  try {
    const inventory = await axios.get('/inventory/one', {
      params: {
        SaleVehicleId,
      }
    })
    return inventory.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}

export async function getMakeModelByVin(Vin: string) {
  if (!Vin) return null
  if (Vin.length !== 17) return null
  try {
    const inventory = await axios.get('/details/nhtsa', {
      params: {
        Vin,
      }
    })
    return inventory.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}