import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import dayjs, { Dayjs } from 'dayjs'

type DatePickerProps = {
  date: Date | Dayjs | string
  setDate: (date: string) => void
  size?: 'default' | 'sm' | 'lg' | 'icon' | null | undefined
  disabled?: boolean
}

export default function DatePicker({ date, setDate, size, disabled = false }: DatePickerProps) {
  let inputDate: Date = new Date(dayjs(date).format('MM/DD/YYYY'))
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn('justify-start text-left font-normal', !date && 'text-muted-foreground', 'text-sm')}
          size={size}
          disabled={disabled}
        >
          <CalendarIcon className='mr-2 h-4 w-4' />
          {date ? dayjs(date).format('MMM D, YYYY ') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0'>
        <Calendar
          mode='single'
          selected={inputDate}
          onSelect={(newDate: Date | undefined) => {
            if (newDate) setDate(dayjs(newDate).format('MM/DD/YYYY'))
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
