import { Badge } from '@/components/ui'
import { StoreContext } from '@/services/providers/store-context-provider'
import { motion } from 'framer-motion'

type CargurusBarProps = {
  cargurus: {
    amountToMoveIndicatorFromLeft: number
    amountToMoveIndicatorFromPreset: number
    averageSalePriceOffsetFromCarGurusChoice: number
    isHigher: boolean
  }
  marketData: {
    greatPrice: number
    goodPrice: number
    fairPrice: number
    highPrice: number
    overPrice: number
  }
  stores: StoreContext[]
}

export default function CargurusBar({ cargurus, marketData, stores }: CargurusBarProps) {
  const carGurusMap = ['Great Price', 'Good Price', 'Fair Price', 'High Price', 'Overpriced']
  return (
    <div className='w-full relative flex flex-col gap-2 '>
      <div className='text-xs w-full flex justify-around items-center'>
        <span>
          Great <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          Good <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          Fair <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          High <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          Over<p className='hidden md:inline-block'>priced</p>
        </span>
      </div>

      <div className='h-2 rounded-full w-full relative'>
        <BackgroundBar />
        {cargurus && (
          <motion.div
            className='absolute top-0 h-5 w-2 -mt-1.5 border-2 border-background rounded-full bg-blue-500 z-10'
            style={{
              left: `calc(${Math.max(
                Math.min(cargurus?.amountToMoveIndicatorFromLeft + cargurus?.amountToMoveIndicatorFromPreset, 100),
                0
              )}% - 0.25rem)`
            }}
            layout
          ></motion.div>
        )}
      </div>
      <div className='text-xs w-full flex justify-around items-center'>
        <span>${marketData.greatPrice.toLocaleString()}</span>
        <span>${marketData.goodPrice.toLocaleString()}</span>
        <span>${marketData.fairPrice.toLocaleString()}</span>
        <span>${marketData.highPrice.toLocaleString()}</span>
        <span>${marketData.overPrice.toLocaleString()}</span>
      </div>
      <div className='w-11/12 mx-auto flex flex-col md:flex-row'>
        {cargurus ? (
          <div className='w-full flex gap-2 justify-center items-center text-xs md:text-sm'>
            <span>
              $
              {(cargurus?.averageSalePriceOffsetFromCarGurusChoice ?? 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              {cargurus?.isHigher ? ' Above ' : ' Below '}
              {(carGurusMap[stores[0].DefaultCarGurusPreference] ?? '')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
            <Badge variant={cargurus?.isHigher ? 'default' : 'destructive'} className='hidden md:block'>
              {cargurus?.isHigher ? 'Above' : 'Below'}
            </Badge>
          </div>
        ) : (
          <div className='w-full h-full opacity-50 flex justify-center items-center text-sm'>No Data</div>
        )}
      </div>
    </div>
  )
}

function BackgroundBar() {
  return (
    <div className='absolute top-0 left-0 bottom-0 right-0 flex h-full w-full z-[0]'>
      <div className='bg-[#006100] w-full h-full rounded-l-full'></div>
      <div className='bg-[#009100] w-full h-full'></div>
      <div className='bg-[#00bd00] w-full h-full'></div>
      <div className='bg-[#ff8400] w-full h-full'></div>
      <div className='bg-[#f60000] w-full h-full rounded-r-full'></div>
    </div>
  )
}
