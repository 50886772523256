import { editSaleById, getSaleById } from '@/services/api/sales'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from './components/header'
import EditForm from './components/edit-form'
import { Card, CardContent } from '@/components/ui'

type SaleEdit = {
  field: keyof SaleVehicle
  oldValue: string
  newValue: string
}

export default function Sale() {
  const [edits, setEdits] = useState<SaleEdit[]>([])
  const params = useParams()
  const id = params.id
  const { data, isPending, isError } = useQuery({
    queryKey: ['sale', id],
    queryFn: async () => getSaleById(Number(id))
  })

  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      const tookATrade = edits.find((edit) => edit.field === 'TookATrade')
      if (tookATrade) {
        // @ts-ignore
        tookATrade.newValue = Number(tookATrade.newValue)
      }
      const IsCertified = edits.find((edit) => edit.field === 'IsCertified')
      if (IsCertified) {
        // @ts-ignore
        IsCertified.newValue = Number(IsCertified.newValue)
      }
      const IsRollback = edits.find((edit) => edit.field === 'IsRollback')
      if (IsRollback) {
        // @ts-ignore
        IsRollback.newValue = Number(IsRollback.newValue)
      }
      await editSaleById(Number(id), edits)
      window.location.reload()
    }
  })

  const title = isPending ? 'Loading...' : isError ? 'Error' : data?.FullVehicleName
  return (
    <div className='container pt-4 flex flex-col space-y-4'>
      <Header title={title} edits={edits} setEdits={setEdits} onSave={mutateAsync} SaleVehicleId={+id!!} />
      <Card>
        <CardContent>
          <EditForm sale={data} edits={edits} setEdits={setEdits} />
        </CardContent>
      </Card>
    </div>
  )
}
