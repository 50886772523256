import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui'
import { cn } from '@/lib/utils'
import dayjs from 'dayjs'
import { UploadIcon } from 'lucide-react'
import { useCallback, useState } from 'react'
import Dropzone from 'react-dropzone'

export default function UpdateFromFile() {
  const [displayChanges, setDisplayChanges] = useState<any>(null)
  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        // log as csv
        const uint8Array = new Uint8Array(binaryStr as ArrayBuffer)
        const csv = new TextDecoder().decode(uint8Array)
        const rows = csv.split('\n')
        const fields = rows[0].split(',').map((e) => e.trim())
        const newObjects = rows.slice(1).map((row) => {
          const values = row.split(',')
          return fields.reduce((acc, field, index) => {
            if (field === 'SoldAt') values[index] = dayjs(values[index]).format('YYYY-MM-DD 00:00:00')
            // @ts-ignore
            acc[field] = values[index]
            return acc
          }, {})
        })
        setDisplayChanges({
          fields,
          newObjects
        })
        console.log({ fields, newObjects })
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  return (
    <main className='container'>
      <Card>
        <CardHeader>
          <CardTitle>Update From CSV File</CardTitle>
          <Button variant='ghost'>Download Template</Button>
        </CardHeader>
        <CardContent>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={cn(
                  'group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25',
                  'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                  isDragActive && 'border-muted-foreground/50'
                )}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                    <div className='rounded-full border border-dashed p-3'>
                      <UploadIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                    </div>
                    <p className='font-medium text-muted-foreground'>Drop the file here</p>
                  </div>
                ) : (
                  <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                    <div className='rounded-full border border-dashed p-3'>
                      <UploadIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                    </div>
                    <div className='space-y-px'>
                      <p className='font-medium text-muted-foreground'>
                        Drag {`'n'`} drop a file here, or click to select a file
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          <Table>
            <TableHeader>
              <TableRow>
                {displayChanges.fields?.map((field: string) => (
                  <TableHead key={field}>{field}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {displayChanges.newObjects?.map((object: any, index: number) => (
                <TableRow key={index}>
                  {displayChanges.fields?.map((field: string) => (
                    <td key={field}>{object[field]}</td>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </main>
  )
}
