import DataCard from '../data-card'
import SectionCard from '../section-card'
import { Card, CardContent, CardHeader, CardTitle, TooltipProvider } from '@/components/ui'
import CargurusStackedPie from './cargurus-stacked-pie'
import CargurusBar from './cargurus-bar'
import CargurusTable from './cargurus-table'
import { Skeleton } from '@mui/material'
import ByAgeStackedPie from './by-age-stacked-pie'
import ByAgeTable from './by-age-table'
import BySourceTable from './by-source-table'
import useInventoryAnalytics from '../../hooks/use-inventory-analytics'

type InventoryOverviewTabProps = {
  stores: any
}

export default function InventoryOverviewTab({ stores }: InventoryOverviewTabProps) {
  const { data, loading } = useInventoryAnalytics(stores)
  return (
    <div className='flex flex-col gap-4 pb-32'>
      <SectionCard title='Inventory Overview' description={`All current inventory data`}>
        <TooltipProvider>
          <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
            <DataCard
              title='Total inventory'
              data={data}
              dataKey='TotalInventory'
              type='number'
              loading={loading}
              color='primary'
            />
            <DataCard
              title='Total investment'
              data={data}
              dataKey='TotalInvestment'
              type='currency'
              loading={loading}
            />
            <DataCard
              title='Avg investment'
              data={data}
              dataKey='AverageInvestment'
              type='currency'
              loading={loading}
            />
            <DataCard
              title='Days supply'
              data={data}
              dataKey='DaysSupply'
              type='number'
              loading={loading}
              explanation='Days supply in inventory based on the last 30 days of sales'
            />
            <DataCard title='Avg age' data={data} dataKey='AverageAge' type='number' loading={loading} />
          </div>
        </TooltipProvider>
      </SectionCard>
      <SectionCard title='Cargurus at a glance' description='Cargurus pricing data for all stores' className='relative'>
        <div className='w-full flex flex-col md:flex-row space-evenly gap-4'>
          <CargurusStackedPie
            title='Inventory'
            data={data}
            loading={loading}
            legendAlign={data?.length && data.length > 1 ? 'center' : 'left'}
            legendPosition={data?.length && data.length > 1 ? 'bottom' : 'middle'}
          />
          <CargurusTable data={data} loading={loading} />
        </div>
        {data?.map((entry, index) => (
          <Card key={index} className='mt-2'>
            <CardHeader>
              <CardTitle>
                {loading ? (
                  <Skeleton width='40%' height={20} className='inline-block' />
                ) : (
                  `Cargurus pricing for ${entry.Store}`
                )}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CargurusBar
                data={entry?.CargurusRating}
                carGurusPreference={entry?.CargurusPreference}
                loading={loading || stores.loading}
              />
            </CardContent>
          </Card>
        ))}
      </SectionCard>
      <SectionCard title='Inventory by age' description='Inventory data by age of vehicle'>
        <div className='w-full flex flex-col md:flex-row space-evenly gap-4'>
          <ByAgeStackedPie
            title='Inventory'
            data={data}
            loading={loading}
            legendAlign={data?.length && data.length > 1 ? 'center' : 'left'}
            legendPosition={data?.length && data.length > 1 ? 'bottom' : 'middle'}
          />
          <ByAgeTable data={data} loading={loading} />
        </div>
      </SectionCard>
      <SectionCard title='Inventory by source' description='Inventory data by source of vehicle'>
        <BySourceTable data={data} loading={loading} />
      </SectionCard>
    </div>
  )
}
