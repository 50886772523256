import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  Switch
} from '@/components/ui'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { useState } from 'react'

type FilterOption = {
  label: string
  field: keyof SaleVehicle
  type: 'text' | 'range' | 'select' | 'checkbox' | 'radio'
}

export default function SalesTableFilters() {
  const [newFilters, setNewFilters] = useState<ListFilter<SaleVehicle>[]>([])

  const preferences = useLocalAppPreferences()

  const initFilterSession = () => {
    const vehicleFilters: AppPreferences['lists']['vehicles']['filters'] =
      preferences.preferences.lists.vehicles.filters
    setNewFilters(vehicleFilters)
  }

  const filterOptions: FilterOption[] = [
    { label: 'Rollback', field: 'IsRollback', type: 'checkbox' },
    { label: 'Certified', field: 'IsCertified', type: 'checkbox' },
    { label: 'Took a trade', field: 'TookATrade', type: 'checkbox' },
    { label: 'Days in inventory', field: 'DaysInInventory', type: 'range' },
    { label: 'Odometer', field: 'Odometer', type: 'range' },
    { label: 'Gross', field: 'Gross', type: 'range' },
    { label: 'Notes', field: 'Notes', type: 'text' }
  ]

  const applyFilters = () => {
    preferences.setPreference('lists.vehicles.filters', newFilters)
  }

  const clearFilters = () => {
    preferences.setPreference('lists.vehicles.filters', [])
    setNewFilters([])
  }

  const toggleFilter = (filter: FilterOption, isActive: boolean) => {
    if (isActive) {
      setNewFilters(newFilters.filter((f) => f.key !== filter.field))
      return
    }

    const operator = filter.type === 'text' ? 'contains' : filter.type === 'range' ? 'between' : 'eq'

    let defaultValues: ListFilter<SaleVehicle> = {
      key: filter.field,
      operator,
      value: null
    } as ListFilter<SaleVehicle>

    if (filter.type === 'text') defaultValues.value = ''
    if (filter.type === 'range') defaultValues.value = [0, 100]
    if (filter.type === 'checkbox') defaultValues.value = 1

    setNewFilters([...newFilters, defaultValues])
  }

  const changeFilterValue = (field: string, value: any) => {
    const filter = newFilters.find((f) => f.key === field)
    if (!filter) return
    filter.value = value
    setNewFilters([...newFilters])
  }

  return (
    <DropdownMenu onOpenChange={initFilterSession}>
      <DropdownMenuTrigger>
        <Button variant='outline'>Filters</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className='p-4 space-y-4'>
          {filterOptions.map((option) => {
            return (
              <FilterOption
                key={option.field}
                option={option}
                filters={newFilters}
                toggleFilter={toggleFilter}
                changeFilterValue={changeFilterValue}
              />
            )
          })}
        </div>
        <DropdownMenuItem onClick={clearFilters}>
          <Button className='w-full' variant='outline'>
            Clear All
          </Button>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={applyFilters}>
          <Button className='w-full'>Apply</Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function FilterOption({
  option,
  filters,
  toggleFilter,
  changeFilterValue
}: {
  option: FilterOption
  filters: ListFilter<SaleVehicle>[]
  toggleFilter: (filter: FilterOption, isActive: boolean) => void
  changeFilterValue: (field: string, value: any) => void
}) {
  const active = filters.some((filter) => filter.key === option.field)
  const filter = active ? filters.find((f) => f.key === option.field) : null
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex gap-2 justify-between items-center'>
        <div className='flex gap-2 items-center'>
          <Switch checked={active} onCheckedChange={() => toggleFilter(option, active)} />
          {option.label}
        </div>
        <div className={`text-sm text-gray-500`}>{active ? 'ON' : 'OFF'}</div>
      </div>
      {option.type === 'text' && <Input name={option.field} disabled={!active} />}
      {option.type === 'range' && (
        <div className='flex gap-2 items-center'>
          <Input
            type='number'
            name={`${option.field}-min`}
            disabled={!active}
            // @ts-ignore
            value={active ? filter.value[0] : ''}
            onChange={(e) => {
              // @ts-ignore
              changeFilterValue(option.field, [e.target.value, filter.value[1]])
            }}
          />
          -
          <Input
            type='number'
            name={`${option.field}-max`}
            disabled={!active}
            // @ts-ignore
            value={active ? filter.value[1] : ''}
            onChange={(e) => {
              // @ts-ignore
              changeFilterValue(option.field, [filter.value[0], e.target.value])
            }}
          />
        </div>
      )}
      {option.type === 'select' && <Input type='select' name={option.field} disabled={!active} />}
    </div>
  )
}
