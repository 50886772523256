import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui'
import UserAvatar from '@/components/user-avatar'
import useStores from '@/hooks/use-stores'
import { proper } from '@/lib/utils'
import { motion } from 'framer-motion'
import { useMemo } from 'react'

type DashboardHeaderProps = {
  user: {
    data: User | null
    loading: boolean
  }
}

type BreadcrumbItemType = {
  label: string
  selectOptions?: any
  onClick?: () => void
}

export default function DashboardHeader({ user }: DashboardHeaderProps) {
  const stores = useStores()
  const storesData = stores.data || []

  const storesSelected = useMemo(() => storesData.filter((store) => store.IsSelected), [storesData])
  const groups = useMemo(() => Array.from(new Set(storesSelected.map((store) => store.GroupName))), [storesSelected])

  const breadcrumbItems = useMemo((): BreadcrumbItemType[] => {
    const items: BreadcrumbItemType[] = [{ label: 'Stores' }]

    if (groups.length === 1) {
      items.push({ label: String(groups[0]) })
    } else if (groups.length > 1) {
      items.push({ label: 'Multiple Groups...' })
    }

    if (storesSelected.length === 1) {
      items.push({
        label: storesSelected[0].Name,
        selectOptions: storesData.map((store) => ({
          ...store,
          onClick: () => stores.setSelectedStores([store.CompanyId])
        }))
      })
    } else if (storesSelected.length > 1) {
      items.push({
        label: `${storesSelected[0].Name} and ${storesSelected.length - 1} others...`,
        selectOptions: storesData.map((store) => ({
          ...store,
          onClick: () => stores.setSelectedStores([store.CompanyId])
        }))
      })
    }

    return items
  }, [groups, storesSelected, storesData, stores])

  return (
    <motion.div
      className='flex items-center gap-2'
      initial={{ opacity: 0, translateX: -10 }}
      animate={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -10 }}
      transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
    >
      <UserAvatar user={user.data} color='muted' />
      <h1>Welcome, {proper(user.data?.FirstName)}</h1>
      <Breadcrumb className='ml-4'>
        <BreadcrumbList>
          {breadcrumbItems.map((breadcrumb, index) => {
            return (
              <BreadcrumbItem key={index}>
                {index > 0 && <BreadcrumbSeparator />}
                <BreadcrumbItem>
                  <span>{breadcrumb.label}</span>
                </BreadcrumbItem>
              </BreadcrumbItem>
            )
          })}
        </BreadcrumbList>
      </Breadcrumb>
    </motion.div>
  )
}
