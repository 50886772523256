import LinkWithQuery from '@/components/link-with-query'
import { Badge, Button, Input, Label } from '@/components/ui'
import { FormEvent, useState } from 'react'
import ForgotPasswordArt from '@/assets/images/forgot-password-art.jpg'
import axios from '@/lib/axios'
import { useMutation } from '@tanstack/react-query'
import { CheckSquare } from 'lucide-react'

export default function ForgotPassword() {
  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget as HTMLFormElement
    const EmailAddress = form.email.value
    await mutateAsync({ EmailAddress })
  }

  const [successful, setSuccessful] = useState(false)
  const [error, setError] = useState('')

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: { EmailAddress: string }) => {
      return await axios.get('/users/password-reset', {
        params: data
      })
    },
    onSuccess: () => {
      setSuccessful(true)
    },
    onError: () => {
      setError('An error occurred. Please try again.')
    }
  })

  return (
    <div className='w-full lg:grid h-screen lg:grid-cols-2 '>
      <div className='flex items-center justify-center py-12 w-full lg:border-r'>
        <div className='mx-auto grid w-[350px] gap-6'>
          <div className='grid gap-2 text-center'>
            {error && <Badge variant='destructive'>{error}</Badge>}
            {successful ? (
              <h1 className='text-3xl font-bold'>
                <CheckSquare className='w-8 h-8 inline-block stroke-green-500' /> Password reset sent!
              </h1>
            ) : (
              <h1 className='text-3xl font-bold'>Forgot Password</h1>
            )}
            {!successful && (
              <p className='text-balance text-muted-foreground'>Enter your email below to request a link</p>
            )}
            <div className='grid gap-4'>
              {successful ? (
                <div className='opacity-80'>
                  An email has been sent to your inbox. Please allow up to 5 minutes for it to be delivered. If you do
                  not see the email in your inbox after 5 minutes, check your spam folder.
                </div>
              ) : (
                <form className='grid gap-4' onSubmit={onSubmit}>
                  <div className='grid gap-2'>
                    <Label htmlFor='email' className='w-full text-start'>
                      Email
                    </Label>
                    <Input id='email' type='email' placeholder='m@example.com' required name='email' />
                  </div>
                  <div className='grid gap-2'>
                    <div className='flex items-center'>
                      <LinkWithQuery to='/login' className='ml-auto inline-block text-sm underline'>
                        Back to login
                      </LinkWithQuery>
                    </div>
                  </div>
                  <Button name='submit' type='submit' className='w-full' loading={isPending}>
                    Request a link
                  </Button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='hidden lg:flex bg-background items-center justify-center '>
        <img
          src={ForgotPasswordArt}
          alt='Image'
          width='1920'
          height='1080'
          className='h-fit w-full object-cover dark:brightness-[0.2] dark:grayscale'
        />
      </div>
    </div>
  )
}
