import { Button, Card, CardHeader, CardTitle } from '@/components/ui'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import axios from '@/lib/axios'
import { ArrowRightFromLine } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

type HeaderProps = {
  title: string
  edits: SaleEdit[]
  setEdits: (edits: SaleEdit[]) => void
  onSave: () => void
  SaleVehicleId: number
}

type SaleEdit = {
  field: keyof SaleVehicle
  oldValue: string
  newValue: string
}

export default function Header({ title, edits, setEdits, onSave, SaleVehicleId }: HeaderProps) {
  const resetChanges = () => {
    setEdits([])
  }
  const removeNoChanges = () => {
    setEdits(edits.filter((edit) => edit.oldValue !== edit.newValue))
  }

  const navigate = useNavigate()

  const deleteSale = async () => {
    await axios.delete(`/sales/${SaleVehicleId}`)
    navigate('/sales' + window.location.search)
  }

  return (
    <Card className='w-full'>
      <CardHeader className='w-full flex items-center justify-between flex-row'>
        <CardTitle>{title}</CardTitle>
        <div className='flex items-center space-x-2'>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant='destructive'>Delete this sale</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you want to delete this sale?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. Are you sure you want to delete this sale? The data will remain in our
                  system, but will be marked as deleted.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant='secondary'>No, I don&apos;t want to delete this sale</Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild className='bg-red-500' onClick={() => deleteSale()}>
                  <Button variant='destructive'>Yes, delete this sale</Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant='secondary' disabled={edits.length === 0} onClick={removeNoChanges}>
                Reset changes
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you want to undo the following changes?</AlertDialogTitle>
              </AlertDialogHeader>
              {edits.map((edit, index) => (
                <AlertDialogDescription key={index}>
                  <div>
                    <span className='font-bold'>{edit.field}</span>
                    <br /> {edit.oldValue} <ArrowRightFromLine className='inline w-4 mx-4' /> {edit.newValue}
                  </div>
                </AlertDialogDescription>
              ))}
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant='secondary'>No, keep my changes</Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button variant='default' onClick={resetChanges}>
                    Yes, reset my changes
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant='default' disabled={edits.length === 0} onClick={removeNoChanges}>
                Save changes
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you want to save the following changes?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. Are you sure you want to save the following changes?
                </AlertDialogDescription>
              </AlertDialogHeader>
              {edits.map((edit, index) => (
                <AlertDialogDescription key={index}>
                  <div>
                    <span className='font-bold'>{edit.field}</span>
                    <br /> {edit.oldValue} <ArrowRightFromLine className='inline w-4 mx-4' /> {edit.newValue}
                  </div>
                </AlertDialogDescription>
              ))}
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant='secondary'>No, I&apos;m not done here</Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button variant='default' onClick={onSave}>
                    Yes, save my changes
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </CardHeader>
    </Card>
  )
}
