import { createContext } from 'react'
import { authenticateUserWithJWT } from '../api/authentication'
import LoadingSplash from '@/components/loading-splash'
import { AnimatePresence, motion } from 'framer-motion'
import { useQuery } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

declare interface UserContextType {
  data: User | null
  loading: boolean
}

export const UserContext = createContext({ data: null, loading: true } as UserContextType)

type UserContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

export default function UserContextProvider({ children }: UserContextProviderProps) {
  const getData = async () => {
    const token = localStorage.getItem('Authorization')
    if (!token) return
    try {
      const userData = await authenticateUserWithJWT(token)
      Sentry.setUser(userData.EmailAddress)
      return userData
    } catch (error) {
      console.error(error)
      return
    }
  }

  const { status, data } = useQuery({
    queryKey: ['users'],
    queryFn: getData,
    refetchInterval: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  })

  const loading = status === 'pending'

  const ChildrenComponent = (
    <AnimatePresence mode='wait'>
      {loading ? (
        <motion.div
          key='loading'
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.99 }}
          className='absolute inset overflow-hidden'
        >
          <LoadingSplash message='Loading your stores...' />
        </motion.div>
      ) : (
        <motion.div
          key='loaded'
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.99 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )

  return <UserContext.Provider value={{ data, loading }}>{ChildrenComponent}</UserContext.Provider>
}
