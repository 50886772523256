import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import dayjs, { Dayjs } from 'dayjs'
import { DateRange } from 'react-day-picker'
import { useState } from 'react'

type DateRangePickerProps = {
  dates: [Date, Date] | [Dayjs, Dayjs] | [string, string]
  setDates: (date: [string, string]) => void
}

export default function DateRangePicker({ dates, setDates }: DateRangePickerProps) {
  const [newDates, setNewDates] = useState<DateRange>({
    from: new Date(dayjs(dates[0]).format('MM/DD/YYYY')),
    to: new Date(dayjs(dates[1]).format('MM/DD/YYYY'))
  })

  const [datepickerOpen, setDatePickerOpen] = useState(false)

  return (
    <Popover open={datepickerOpen} onOpenChange={setDatePickerOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn('w-[280px] justify-start text-left font-normal', !dates && 'text-muted-foreground')}
        >
          <CalendarIcon className='mr-2 h-4 w-4' />
          {dates ? (
            `${dayjs(dates[0]).format('MMM D, YYYY ')} - ${dayjs(dates[1]).format('MMM D, YYYY ')}`
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0 flex flex-col gap-2'>
        <Calendar
          mode='range'
          selected={newDates}
          onSelect={(newRange: DateRange | undefined, e) => {
            if (dayjs(e).date() === dayjs(newDates.from).date()) {
              setNewDates({
                from: e,
                to: e
              })
              return
            }
            if (dayjs(newRange?.to) < dayjs(newRange?.from)) {
              setNewDates({
                from: newRange?.from || new Date(),
                to: newRange?.from || new Date()
              })
              return
            }
            setNewDates({
              from: newRange?.from || new Date(),
              to: newRange?.to || new Date()
            })
          }}
          initialFocus
        />
        <div className='p-2 w-full'>
          <Button
            onClick={() => {
              if (newDates.from && newDates.to) {
                setDates([dayjs(newDates.from).format('MM/DD/YYYY'), dayjs(newDates.to).format('MM/DD/YYYY')])
                setDatePickerOpen(false)
              }
            }}
            className='w-full'
          >
            Apply
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
