import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import AddAdjustment from './add-adjustment'
import UploadAdjustments from './upload-adjustments'
import { Button } from '@/components/ui'
import { DownloadIcon, TrashIcon } from '@radix-ui/react-icons'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { useQueryClient } from '@tanstack/react-query'
import { deleteAdjustment } from '@/services/api/adjustments'

type InventoryTableHeaderProps = {
  store: Company
  selectedRows: number[]
}

export default function AdjustmentsTableHeader({ store, selectedRows }: InventoryTableHeaderProps) {
  const queryClient = useQueryClient()

  async function handleDelete() {
    for (let id of selectedRows) {
      await deleteAdjustment(store.CompanyId, id)
    }
    queryClient.invalidateQueries({
      queryKey: ['adjustments-list']
    })
  }

  function downloadTemplate() {
    const csv = 'Value,Notes,CreatedAt,IsGAdjustment\n1000,This is a test adjustment,2022-01-01,0'
    const element = document.createElement('a')
    const file = new Blob([csv], { type: 'text/csv' })
    element.href = URL.createObjectURL(file)
    element.download = 'adjustments-template.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
    element.remove()
  }

  return (
    <GridToolbarContainer
      style={{
        borderBottom: '1px solid var(--background-tertiary)',
        paddingBottom: '0.25rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem'
      }}
    >
      <div className='flex justify-between items-center w-full'>
        <div className='flex items-center w-full'>
          <div className='flex items-center '>
            <GridToolbarQuickFilter
              style={{
                paddingLeft: '1rem',
                paddingTop: 4,
                marginRight: 8,
                width: 500
              }}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '6px',
                  margin: 0,
                  height: 36
                },
                '& .MuiInputBase-input': {
                  border: 'none',
                  boxShadow: 'none'
                },
                '& .MuiInputBase-input:focus': {
                  boxShadow: 'none'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid rgb(226, 232, 240)',
                  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)'
                }
              }}
              size='small'
              variant='outlined'
            />
            <AddAdjustment store={store} />
            <UploadAdjustments store={store} />
            <Button variant='outline' className='ml-2' onClick={downloadTemplate}>
              <DownloadIcon /> Download template
            </Button>
          </div>
        </div>
        <div className='flex items-center gap-2 text-black fill-black'>
          <GridToolbarColumnsButton
            slotProps={{
              button: {
                className:
                  'shadow-sm inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 p-2',
                style: {
                  color: 'var(--text-primary)',
                  border: '1px solid rgb(226, 232, 240)',
                  borderRadius: '0.3rem',
                  padding: '0.34rem 0.5rem',
                  fontFamily: 'inherit',
                  textTransform: 'none'
                }
              }
            }}
          />
          <div className='hidden lg:flex items-center'>
            <GridToolbarDensitySelector
              slotProps={{
                button: {
                  className:
                    'shadow-sm inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 p-2',
                  style: {
                    color: 'var(--text-primary)',
                    border: '1px solid rgb(226, 232, 240)',
                    borderRadius: '0.3rem',
                    padding: '0.34rem 0.5rem',
                    fontFamily: 'inherit',
                    textTransform: 'none'
                  }
                }
              }}
            />
          </div>
          <div className='hidden lg:flex items-center gap-2'>
            <GridToolbarExport
              slotProps={{
                button: {
                  className:
                    'shadow-sm inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 p-2',
                  style: {
                    color: 'var(--text-primary)',
                    border: '1px solid rgb(226, 232, 240)',
                    borderRadius: '0.3rem',
                    padding: '0.34rem 0.5rem',
                    fontFamily: 'inherit',
                    textTransform: 'none'
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex items-center w-full pl-4'>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant='outline' size='sm' disabled={!selectedRows || selectedRows.length === 0}>
              <TrashIcon /> Delete
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete adjustments?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete these adjustments and remove their data from
                our servers.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete}>Continue</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </GridToolbarContainer>
  )
}
