// import { useParams } from 'react-router-dom'

export default function SendOutReports() {
  // const { 'company-id': companyId } = useParams<{ 'company-id': string }>()
  return (
    <div>
      <h1>Send Out Reports</h1>
    </div>
  )
}
