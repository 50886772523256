import { getSalesByCompanyIdWithDates } from '@/services/api/sales'
import { useQuery } from '@tanstack/react-query'

export const useSales = (CompanyId: number | null | undefined, startDate: string, endDate: string) => {
  const fetchSales = async () => {
    const sales = await getSalesByCompanyIdWithDates(CompanyId, startDate, endDate)
    return sales
  }

  const { isPending, data, isError, error } = useQuery({
    queryKey: ['sales-list', CompanyId, startDate, endDate],
    queryFn: fetchSales,
    refetchOnWindowFocus: false,
  })
  return { data, loading: isPending, error: isError ? error : null }
}
