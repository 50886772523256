import axios from "@/lib/axios"

export async function getSourcesByCompanyId(id: number | null | undefined, excludeHiddenFromSuggestions?: boolean) {
  try {
    if (!id) return null
    const stores = await axios.get('/sources', {
      params: {
        CompanyId: id,
        excludeHiddenFromSuggestions
      }
    })
    return stores.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}

export async function updateSource(sourceDto: VehicleSource) {
  try {
    console.log(sourceDto)
    const response = await axios.patch('/sources', sourceDto)
    return response.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}