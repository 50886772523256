import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Input,
  Label,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui'
import { InventoryFootprintItem } from '.'
import { Skeleton } from '@mui/material'

type FootprintTableProps = {
  label: string
  children: any
  loading: boolean
  data: InventoryFootprintItem[] | null
  filter: {
    value: string
    setValue: (value: string) => void
  }
}

export default function FootprintTable({ label, children, loading, data, filter }: FootprintTableProps) {
  return (
    <Card className='rounded-t-none'>
      <CardHeader>
        <CardTitle>{label}</CardTitle>
        <CardContent>
          <div className='my-2 max-w-72 ml-auto'>
            <Label>Search for a model...</Label>
            <Input
              placeholder='Search for a model...'
              onChange={(ev) => filter.setValue(ev.target.value)}
              value={String(filter.value)}
            />
          </div>
          <div className='max-h-[36rem] overflow-y-auto'>
            {loading ? (
              <Skeleton variant='rounded' height={720} className='w-full h-full' />
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead />
                    <TableHead>Model</TableHead>
                    <TableHead className='whitespace-nowrap'>Sales last 6 months</TableHead>
                    <TableHead className='whitespace-nowrap'>Target days supply</TableHead>
                    <TableHead className='whitespace-nowrap'>Days supply</TableHead>
                    <TableHead className='whitespace-nowrap'>Units in stock</TableHead>
                    <TableHead>Unit measurement</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>{children}</TableBody>
              </Table>
            )}
          </div>
        </CardContent>
        <CardTitle>To optimize inventory...</CardTitle>
        <CardFooter className='grid grid-cols-3 items-center space-x-4'>
          <Card>
            <CardHeader>
              <CardTitle>Investment delta needed</CardTitle>
              <CardContent>
                <h1 className='text-xl font-bold w-full text-center mt-4'>${getInvestmentDelta(data ?? [])}</h1>
              </CardContent>
            </CardHeader>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Unit delta needed</CardTitle>
              <CardContent>
                <h1 className='text-xl font-bold w-full text-center mt-4'>{getUnitDelta(data ?? [])}</h1>
              </CardContent>
            </CardHeader>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Potential revenue gain/loss</CardTitle>
              <CardContent>
                <h1 className='text-xl font-bold w-full text-center mt-4'>${getRevenueDelta(data ?? [])}</h1>
              </CardContent>
            </CardHeader>
          </Card>
        </CardFooter>
      </CardHeader>
    </Card>
  )
}

function getInvestmentDelta(data: InventoryFootprintItem[]) {
  return Math.round(
    data?.reduce((acc: number, item: InventoryFootprintItem) => {
      if (!item.amountOffset) return acc
      if (!item.averageInvestment) return acc
      if (Math.round(item.amountOffset) === 0) return acc
      if (item.amountOffset > 0 && item.unitsInStock <= 1) return acc
      const correction = -item.amountOffset * item.averageInvestment
      return acc + correction
    }, 0) ?? 0
  ).toLocaleString()
}

function getUnitDelta(data: InventoryFootprintItem[]) {
  return Math.round(
    data?.reduce((acc: number, item: InventoryFootprintItem) => {
      if (!item.amountOffset) return acc
      if (Math.round(item.amountOffset) === 0) return acc
      if (item.amountOffset > 0 && item.unitsInStock <= 1) return acc
      return acc - item.amountOffset
    }, 0) ?? 0
  ).toLocaleString()
}

function getRevenueDelta(data: InventoryFootprintItem[]) {
  return Math.round(
    data?.reduce((acc: number, item: InventoryFootprintItem) => {
      if (!item.amountOffset) return acc
      if (!item.totalGross) return acc
      if (Math.round(item.amountOffset) === 0) return acc
      if (item.amountOffset > 0 && item.unitsInStock <= 1) return acc
      const correction = -item.amountOffset * item.totalGross
      return acc + correction
    }, 0) ?? 0
  ).toLocaleString()
}
