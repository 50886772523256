import { getAdjustmentsByCompanyIdWithDates } from '@/services/api/adjustments'
import { useQuery } from '@tanstack/react-query'

export const useAdjustments = (CompanyId: number | null | undefined, startDate: string, endDate: string) => {
  const fetchAdjustments = async () => {
    const adjustments = getAdjustmentsByCompanyIdWithDates(CompanyId, startDate, endDate)
    return adjustments
  }

  const { isPending, data, isError, error } = useQuery({
    queryKey: ['adjustments-list', CompanyId, startDate, endDate],
    queryFn: fetchAdjustments
  })

  return { data, loading: isPending, error: isError ? error : null }
}
