import { Button, Input, Label, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { cn, proper } from '@/lib/utils'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { useState } from 'react'

type AddAutomationRuleFormProps = {
  loading: boolean
  onSubmit: (event: HTMLFormElement) => void
}

export default function AddAutomationRuleForm({ loading, onSubmit }: AddAutomationRuleFormProps) {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [checkMethod, setCheckMethod] = useState<string>('')
  const [checkColor, setCheckColor] = useState<{
    name: string
    color: {
      red?: number
      green?: number
      blue?: number
    }
  } | null>(null)
  const validate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const form = ev.currentTarget as HTMLFormElement
    let hasError = false
    const errors: Record<string, string> = {}
    if (!form.FromColumn.value) {
      errors.FromColumn = 'You must choose a column to source from'
      hasError = true
    }
    if (!checkMethod) {
      errors.CheckMethod = 'You must choose a check method'
      hasError = true
    } else {
      if (checkMethod === 'ValueIsNotEmpty') {
        form.CheckValue.value = 1
      }
      if (checkMethod === 'ValueIsEmpty') {
        form.CheckValue.value = 1
      }
      if (checkMethod === 'BackgroundColor') {
        if (!checkColor) {
          errors.CheckValue = 'You must choose a color'
          hasError = true
        } else {
          if (checkColor.color.red === 0) {
            delete checkColor.color.red
          }
          if (checkColor.color.green === 0) {
            delete checkColor.color.green
          }
          if (checkColor.color.blue === 0) {
            delete checkColor.color.blue
          }
          if (Object.keys(checkColor.color).length === 0) {
            errors.CheckValue = 'You must choose a color'
            hasError = true
          }
          if (checkColor.color.red) {
            form.BackgroundColorRed = { value: checkColor.color.red }
          }
          if (checkColor.color.green) {
            form.BackgroundColorGreen = { value: checkColor.color.green }
          }
          if (checkColor.color.blue) {
            form.BackgroundColorBlue = { value: checkColor.color.blue }
          }
          if (form.BackgroundColorRed?.value || form.BackgroundColorGreen?.value || form.BackgroundColorBlue?.value) {
            form.CheckValue = { value: true }
          }
        }
      }
    }

    if (!form.CheckValue?.value) {
      errors.CheckValue = 'You must choose a value to check against'
      hasError = true
    }
    form[checkMethod] = { value: form.CheckValue.value }
    setErrors(errors)
    if (!hasError) {
      onSubmit(form)
    }
  }

  const methodInputTypes = {
    BackgroundColor: 'color',
    ValueIsNotEmpty: 'hidden',
    ValueIsEmpty: 'hidden',
    ValueEqualTo: 'text',
    ValueNotEqualTo: 'text',
    ValueGreaterThan: 'number',
    ValueLessThan: 'number'
  }

  const letters = []
  // add from A to AZ like a google sheet column
  for (let i = 65; i <= 90; i++) {
    letters.push(String.fromCharCode(i))
  }
  for (let i = 65; i <= 90; i++) {
    letters.push('A' + String.fromCharCode(i))
  }

  return (
    <form className='grid grid-cols-3 gap-4' onSubmit={validate}>
      <DisplayField
        label='From column'
        description='This is the letter of the column in which this data is found in your spreadsheet'
      >
        <Select name='FromColumn'>
          <SelectTrigger>
            <SelectValue placeholder='Select a column' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Column letter</SelectLabel>
              {letters.map((letter) => (
                <SelectItem key={letter} value={letter}>
                  {letter}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <span className='text-red-500 text-sm'>{errors.FromColumn}</span>
      </DisplayField>
      <DisplayField
        label='Check method'
        description='This is the method that will be used to validate that the data in the column is correct'
      >
        <Select name='CheckMethod' value={checkMethod} onValueChange={(value) => setCheckMethod(value)}>
          <SelectTrigger>
            <SelectValue placeholder='Select a method' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Method</SelectLabel>
              <SelectItem value='BackgroundColor'>Background color is</SelectItem>
              <SelectItem value='ValueIsNotEmpty'>Value is not empty</SelectItem>
              <SelectItem value='ValueIsEmpty'>Value is empty</SelectItem>
              <SelectItem value='ValueEqualTo'>Value is equal to</SelectItem>
              <SelectItem value='ValueNotEqualTo'>Value is not equal to</SelectItem>
              <SelectItem value='ValueGreaterThan'>Value is greater than</SelectItem>
              <SelectItem value='ValueLessThan'>Value is less than</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <span className='text-red-500 text-sm'>{errors.CheckMethod}</span>
      </DisplayField>

      <DisplayField
        label='Check value'
        description='This is the value that will be checked against the value in the column'
      >
        {checkMethod &&
          (checkMethod === 'BackgroundColor' ? (
            <div className='flex flex-col items-center gap-2'>
              <Label>{checkColor ? proper(checkColor.name) : 'Select a color'}</Label>
              <div className='flex flex-col gap-1'>
                {getColorValues().map((colorRow, index) => (
                  <div key={index} className='flex gap-1'>
                    {colorRow.map((color) => (
                      <div
                        key={color.name}
                        className={cn(
                          'h-6 w-6 rounded-full',
                          checkColor?.name === color.name && 'border-2 border-primary'
                        )}
                        style={{ backgroundColor: color.color }}
                        onClick={() => {
                          const colorValues = color.color
                            .replace(/[^\d,]/g, '')
                            .split(',')
                            .map((v) => parseInt(v))
                          setCheckColor({
                            name: color.name,
                            color: { red: colorValues[0], green: colorValues[1], blue: colorValues[2] }
                          })
                        }}
                      ></div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Input
              name='CheckValue'
              // @ts-ignore
              type={Object.keys(methodInputTypes).includes(checkMethod) ? methodInputTypes[checkMethod] : 'text'}
              placeholder={`Enter a value to check for ${checkMethod}`}
            />
          ))}
        <span className='text-red-500 text-sm'>{errors.CheckValue}</span>
      </DisplayField>

      <Button type='submit' className='col-span-5' size='sm' loading={loading}>
        Create new validation rule for automation
      </Button>
    </form>
  )
}

type DisplayFieldProps = {
  colspan?: number
  label: string
  children: React.ReactNode
  description?: string
}

const DisplayField = ({ label, children, colspan, description }: DisplayFieldProps) => {
  const colspanString = colspan ? `col-span-${colspan}` : ''
  return (
    <div className={cn('flex flex-col gap-2', colspanString)}>
      <div className='flex items-center space-x-2 justify-start w-full whitespace-nowrap'>
        <span className='font-semibold'>{label}</span>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <div className='flex items-center text-sm opacity-80'>
                <QuestionMarkCircledIcon /> What is this?
              </div>
            </TooltipTrigger>
            <TooltipContent>{description}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      {children}
    </div>
  )
}

function getColorValues() {
  const colors = [
    [
      {
        name: 'black',
        color: 'rgb(0, 0, 0)'
      },
      {
        name: 'dark gray 4',
        color: 'rgb(67, 67, 67)'
      },
      {
        name: 'dark gray 3',
        color: 'rgb(102, 102, 102)'
      },
      {
        name: 'dark gray 2',
        color: 'rgb(153, 153, 153)'
      },
      {
        name: 'dark gray 1',
        color: 'rgb(183, 183, 183)'
      },
      {
        name: 'gray',
        color: 'rgb(204, 204, 204)'
      },
      {
        name: 'light gray 1',
        color: 'rgb(217, 217, 217)'
      },
      {
        name: 'light gray 2',
        color: 'rgb(239, 239, 239)'
      },
      {
        name: 'light gray 3',
        color: 'rgb(243, 243, 243)'
      },
      {
        name: 'white',
        color: 'rgb(255, 255, 255)'
      }
    ],
    [
      {
        name: 'red berry',
        color: 'rgb(152, 0, 0)'
      },
      {
        name: 'red',
        color: 'rgb(255, 0, 0)'
      },
      {
        name: 'orange',
        color: 'rgb(255, 153, 0)'
      },
      {
        name: 'yellow',
        color: 'rgb(255, 255, 0)'
      },
      {
        name: 'green',
        color: 'rgb(0, 255, 0)'
      },
      {
        name: 'cyan',
        color: 'rgb(0, 255, 255)'
      },
      {
        name: 'cornflower blue',
        color: 'rgb(74, 134, 232)'
      },
      {
        name: 'blue',
        color: 'rgb(0, 0, 255)'
      },
      {
        name: 'purple',
        color: 'rgb(153, 0, 255)'
      },
      {
        name: 'magenta',
        color: 'rgb(255, 0, 255)'
      }
    ],
    [
      {
        name: 'light red berry 3',
        color: 'rgb(230, 184, 175)'
      },
      {
        name: 'light red 3',
        color: 'rgb(244, 204, 204)'
      },
      {
        name: 'light orange 3',
        color: 'rgb(252, 229, 205)'
      },
      {
        name: 'light yellow 3',
        color: 'rgb(255, 242, 204)'
      },
      {
        name: 'light green 3',
        color: 'rgb(217, 234, 211)'
      },
      {
        name: 'light cyan 3',
        color: 'rgb(208, 224, 227)'
      },
      {
        name: 'light cornflower blue 3',
        color: 'rgb(201, 218, 248)'
      },
      {
        name: 'light blue 3',
        color: 'rgb(207, 226, 243)'
      },
      {
        name: 'light purple 3',
        color: 'rgb(217, 210, 233)'
      },
      {
        name: 'light magenta 3',
        color: 'rgb(234, 209, 220)'
      }
    ],
    [
      {
        name: 'light red berry 2',
        color: 'rgb(221, 126, 107)'
      },
      {
        name: 'light red 2',
        color: 'rgb(234, 153, 153)'
      },
      {
        name: 'light orange 2',
        color: 'rgb(249, 203, 156)'
      },
      {
        name: 'light yellow 2',
        color: 'rgb(255, 229, 153)'
      },
      {
        name: 'light green 2',
        color: 'rgb(182, 215, 168)'
      },
      {
        name: 'light cyan 2',
        color: 'rgb(162, 196, 201)'
      },
      {
        name: 'light cornflower blue 2',
        color: 'rgb(164, 194, 244)'
      },
      {
        name: 'light blue 2',
        color: 'rgb(159, 197, 232)'
      },
      {
        name: 'light purple 2',
        color: 'rgb(180, 167, 214)'
      },
      {
        name: 'light magenta 2',
        color: 'rgb(213, 166, 189)'
      }
    ],
    [
      {
        name: 'light red berry 1',
        color: 'rgb(204, 65, 37)'
      },
      {
        name: 'light red 1',
        color: 'rgb(224, 102, 102)'
      },
      {
        name: 'light orange 1',
        color: 'rgb(246, 178, 107)'
      },
      {
        name: 'light yellow 1',
        color: 'rgb(255, 217, 102)'
      },
      {
        name: 'light green 1',
        color: 'rgb(147, 196, 125)'
      },
      {
        name: 'light cyan 1',
        color: 'rgb(118, 165, 175)'
      },
      {
        name: 'light cornflower blue 1',
        color: 'rgb(109, 158, 235)'
      },
      {
        name: 'light blue 1',
        color: 'rgb(111, 168, 220)'
      },
      {
        name: 'light purple 1',
        color: 'rgb(142, 124, 195)'
      },
      {
        name: 'light magenta 1',
        color: 'rgb(194, 123, 160)'
      }
    ],
    [
      {
        name: 'dark red berry 1',
        color: 'rgb(166, 28, 0)'
      },
      {
        name: 'dark red 1',
        color: 'rgb(204, 0, 0)'
      },
      {
        name: 'dark orange 1',
        color: 'rgb(230, 145, 56)'
      },
      {
        name: 'dark yellow 1',
        color: 'rgb(241, 194, 50)'
      },
      {
        name: 'dark green 1',
        color: 'rgb(106, 168, 79)'
      },
      {
        name: 'dark cyan 1',
        color: 'rgb(69, 129, 142)'
      },
      {
        name: 'dark cornflower blue 1',
        color: 'rgb(60, 120, 216)'
      },
      {
        name: 'dark blue 1',
        color: 'rgb(61, 133, 198)'
      },
      {
        name: 'dark purple 1',
        color: 'rgb(103, 78, 167)'
      },
      {
        name: 'dark magenta 1',
        color: 'rgb(166, 77, 121)'
      }
    ],
    [
      {
        name: 'dark red berry 2',
        color: 'rgb(133, 32, 12)'
      },
      {
        name: 'dark red 2',
        color: 'rgb(153, 0, 0)'
      },
      {
        name: 'dark orange 2',
        color: 'rgb(180, 95, 6)'
      },
      {
        name: 'dark yellow 2',
        color: 'rgb(191, 144, 0)'
      },
      {
        name: 'dark green 2',
        color: 'rgb(56, 118, 29)'
      },
      {
        name: 'dark cyan 2',
        color: 'rgb(19, 79, 92)'
      },
      {
        name: 'dark cornflower blue 2',
        color: 'rgb(17, 85, 204)'
      },
      {
        name: 'dark blue 2',
        color: 'rgb(11, 83, 148)'
      },
      {
        name: 'dark purple 2',
        color: 'rgb(53, 28, 117)'
      },
      {
        name: 'dark magenta 2',
        color: 'rgb(116, 27, 71)'
      }
    ],
    [
      {
        name: 'dark red berry 3',
        color: 'rgb(91, 15, 0)'
      },
      {
        name: 'dark red 3',
        color: 'rgb(102, 0, 0)'
      },
      {
        name: 'dark orange 3',
        color: 'rgb(120, 63, 4)'
      },
      {
        name: 'dark yellow 3',
        color: 'rgb(127, 96, 0)'
      },
      {
        name: 'dark green 3',
        color: 'rgb(39, 78, 19)'
      },
      {
        name: 'dark cyan 3',
        color: 'rgb(12, 52, 61)'
      },
      {
        name: 'dark cornflower blue 3',
        color: 'rgb(28, 69, 135)'
      },
      {
        name: 'dark blue 3',
        color: 'rgb(7, 55, 99)'
      },
      {
        name: 'dark purple 3',
        color: 'rgb(32, 18, 77)'
      },
      {
        name: 'dark magenta 3',
        color: 'rgb(76, 17, 48)'
      }
    ],
    [
      {
        name: 'custom pink 1',
        color: 'rgb(246, 174, 255)'
      }
    ]
  ]
  return colors
}
