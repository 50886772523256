import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Input,
  Label,
  Popover,
  PopoverTrigger,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import axios from '@/lib/axios'
import { PopoverClose, PopoverContent } from '@radix-ui/react-popover'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { PencilLine, PlusSquare } from 'lucide-react'

type UserCardProps = {
  CompanyId: number
}

type ReportReceiverDto = Omit<ReportReceiver, 'ReportReceiverId' | 'CompanyId'>

export default function UserCards({ CompanyId }: UserCardProps) {
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: ['report-receivers', { CompanyId }],
    queryFn: async () => {
      const { data } = await axios.get('/reports/receivers', { params: { CompanyId } })
      return data
    }
  })

  const { mutateAsync: updateReceiver } = useMutation({
    mutationFn: async ({ ReportReceiverId, receiver }: { ReportReceiverId: number; receiver: ReportReceiverDto }) => {
      await axios.patch(`/reports/receivers/${ReportReceiverId}`, receiver)
      queryClient.invalidateQueries({
        queryKey: ['report-receivers', { CompanyId }]
      })
    }
  })

  const { mutateAsync: addReceiver } = useMutation({
    mutationFn: async ({ receiver }: { receiver: ReportReceiverDto }) => {
      const receiverObject = {
        CompanyId,
        ...receiver
      }
      await axios.put(`/reports/receivers`, receiverObject)
      queryClient.invalidateQueries({
        queryKey: ['report-receivers', { CompanyId }]
      })
    }
  })

  const buildReceiver = (ev: React.FormEvent<HTMLFormElement>, ReportReceiverId: number) => {
    ev.preventDefault()
    const form = ev.currentTarget as HTMLFormElement
    const receiver: ReportReceiverDto = {
      ReceivingUserFullName: form.ReceivingUserFullName.value,
      ReceivingUserEmailAddress: form.ReceivingUserEmailAddress.value,
      WeeklySummaryReport: form.WeeklySummaryReport.checked ? 1 : 0,
      IntoTheWeekendReport: form.IntoTheWeekendReport.checked ? 1 : 0
    }
    updateReceiver({ ReportReceiverId, receiver })
  }

  const buildNewReceiver = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const form = ev.currentTarget as HTMLFormElement
    const receiver: ReportReceiverDto = {
      ReceivingUserFullName: form.ReceivingUserFullName.value,
      ReceivingUserEmailAddress: form.ReceivingUserEmailAddress.value,
      WeeklySummaryReport: form.WeeklySummaryReport.checked ? 1 : 0,
      IntoTheWeekendReport: form.IntoTheWeekendReport.checked ? 1 : 0
    }
    addReceiver({ receiver })
  }

  return (
    <div className='flex flex-col gap-4'>
      <Popover>
        <PopoverTrigger>
          <Button variant='outline' className='w-full'>
            <PlusSquare className='opacity-60 mr-2' /> Add a new receiver
          </Button>
        </PopoverTrigger>
        <PopoverContent className='z-50'>
          <Card className='z-50'>
            <CardHeader>
              <CardTitle>Add a new receiver</CardTitle>
            </CardHeader>
            <form onSubmit={(ev) => buildNewReceiver(ev)}>
              <CardContent className='flex flex-col gap-2'>
                <Label>Receiver Name</Label>
                <Input className='mb-2' name='ReceivingUserFullName' />
                <Label>Receiver Email Address</Label>
                <Input className='mb-2' name='ReceivingUserEmailAddress' />
                <Label>Receives Weekly Summary Report?</Label>
                <Switch className='mb-2' name='WeeklySummaryReport' />
                <Label>Receives Into The Weekend Report?</Label>
                <Switch className='mb-2' name='IntoTheWeekendReport' />
              </CardContent>
              <CardFooter className='flex justify-end gap-2'>
                <PopoverClose className='text-sm font-medium border shadow-sm rounded-md p-2'>
                  Cancel and discard changes
                </PopoverClose>
                <Button variant='default' type='submit'>
                  Save new receiver
                </Button>
              </CardFooter>
            </form>
          </Card>
        </PopoverContent>
      </Popover>
      <div className='grid grid-cols-3 gap-2 overflow-y-auto max-h-96'>
        <TooltipProvider>
          {data?.map((receiver: ReportReceiver) => (
            <Card key={receiver.ReportReceiverId} className='flex flex-row items-center justify-between'>
              <div className='p-3'>
                <div>{receiver.ReceivingUserFullName}</div>
                <div className='opacity-60  text-xs'>{receiver.ReceivingUserEmailAddress}</div>
                <div className='opacity-60 text-xs'>Weekly Summary: {receiver.WeeklySummaryReport ? 'Yes' : 'No'}</div>
                <div className='opacity-60 text-xs'>
                  Into The Weekend: {receiver.IntoTheWeekendReport ? 'Yes' : 'No'}
                </div>
              </div>
              <Popover>
                <PopoverTrigger>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <div className='p-3 opacity-60'>
                        <PencilLine className='w-4' />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side='bottom'>Edit {receiver.ReceivingUserFullName}</TooltipContent>
                  </Tooltip>
                </PopoverTrigger>
                <PopoverContent className='z-50'>
                  <Card className='z-50'>
                    <CardHeader>
                      <CardTitle>Editting {receiver.ReceivingUserFullName}</CardTitle>
                    </CardHeader>
                    <form onSubmit={(ev) => buildReceiver(ev, receiver.ReportReceiverId)}>
                      <CardContent className='flex flex-col gap-2'>
                        <Label>Receiver Name</Label>
                        <Input
                          defaultValue={receiver.ReceivingUserFullName}
                          className='mb-2'
                          name='ReceivingUserFullName'
                        />
                        <Label>Receiver Email Address</Label>
                        <Input
                          defaultValue={receiver.ReceivingUserEmailAddress}
                          className='mb-2'
                          name='ReceivingUserEmailAddress'
                        />
                        <Label>Receives Weekly Summary Report?</Label>
                        <Switch
                          defaultChecked={receiver.WeeklySummaryReport === 1}
                          className='mb-2'
                          name='WeeklySummaryReport'
                        />
                        <Label>Receives Into The Weekend Report?</Label>
                        <Switch
                          defaultChecked={receiver.IntoTheWeekendReport === 1}
                          className='mb-2'
                          name='IntoTheWeekendReport'
                        />
                      </CardContent>
                      <CardFooter className='flex justify-end gap-2'>
                        <PopoverClose className='text-sm font-medium border shadow-sm rounded-md p-2'>
                          Cancel and discard changes
                        </PopoverClose>
                        <Button variant='default' type='submit'>
                          Save changes
                        </Button>
                      </CardFooter>
                    </form>
                  </Card>
                </PopoverContent>
              </Popover>
            </Card>
          ))}
        </TooltipProvider>
      </div>
    </div>
  )
}
