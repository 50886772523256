import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import useQueryDates from '@/hooks/use-query-dates'
import useStores from '@/hooks/use-stores'
import { getOverview } from '@/services/api/v2/companies/:company-id/analytics'
import { useQuery } from '@tanstack/react-query'

export default function useOverviewAnalytics(stores: ReturnType<typeof useStores>) {
  const preferences = useLocalAppPreferences()
  const excludeSourceIds = preferences.preferences.dashboard.excludeSources
  const { startDate, endDate } = useQueryDates()

  const queryFn = async () => {
    try {
      if (stores.loading || !stores.data || stores.data.length === 0) return
      const promises = stores.data
        .filter(store => store.IsSelected)
        .map(store => getOverview(store.CompanyId, { startDate, endDate, excludeSources: excludeSourceIds }))

      const analyticsArray = await Promise.all(promises)
      return analyticsArray
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const { isPending, data } = useQuery({
    queryKey: ['overview-analytics', stores.data, excludeSourceIds.join(','), startDate, endDate],
    queryFn
  })

  return { loading: isPending, data: data }
}
