import { useEffect, useState } from 'react'

export default function useDebounce<T>(value: T, delay: number, debounceFn?: (newValue: any) => void): { value: T; setValue: (value: T) => void } {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(async () => {
      let newValue: any = value
      if (debounceFn) {
        newValue = await debounceFn(value)
      }
      setDebouncedValue(newValue)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return {
    value: debouncedValue,
    setValue: setDebouncedValue
  }
}