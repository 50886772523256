import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

export const useAdjustmentDto = () => {
  const AdjustmentDto: AdjustmentDto = z.object({
    Notes: z.string().nullable(),
    Value: z
      .string()
      .refine((value) => !isNaN(Number(value)) && value !== '', { message: 'Value must be a number' })
      .transform((value) => parseFloat(value)),
    CompanyId: z.number(),
    UserId: z.number(),
    CreatedAt: z.date(),
    IsGAdjustment: z.boolean()
  })

  const defaultValues = {
    Notes: null,
    Value: null,
    CompanyId: 0,
    UserId: 0,
    CreatedAt: '',
    IsGAdjustment: false
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
    getValues,
    watch,
    reset
  } = useForm<AdjustmentDto>({
    resolver: zodResolver(AdjustmentDto),
    defaultValues
  })

  watch()

  return {
    register,
    handleSubmit,
    errors,
    isValid,
    setValue,
    getValues,
    reset,
    isSubmitting
  }
}
