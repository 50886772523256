import {
  Badge,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import { useSaleVehicleDto } from '@/hooks/use-sale-vehicle-dto'
import { SubmitHandler } from 'react-hook-form'
import { cn } from '@/lib/utils'
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import useSources from '@/layouts/header/hooks/use-sources'
import dayjs from 'dayjs'
import DatePicker from '@/components/date-picker'
import useQueryDates from '@/hooks/use-query-dates'
import { insertSaleDto } from '@/services/api/sales'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import useDebounce from '@/hooks/use-debounce'
import { getInventoryByVin, getMakeModelByVin } from '@/services/api/inventory'

type AddSaleProps = {
  store: Company | undefined
}

export default function AddSale({ store }: AddSaleProps) {
  const { register, handleSubmit, errors, setValue, getValues, reset } = useSaleVehicleDto()
  const sources = useSources([store?.CompanyId ?? 0], true)
  const { endDate } = useQueryDates()
  const queryClient = useQueryClient()
  const [dialogOpen, setDialogOpen] = useState(false)

  const getVehicleDetailsByVin = async (vin: string) => {
    if (!vin) return null
    if (vin.length !== 17) return null
    const data = await getInventoryByVin(vin)
    if (data) return data
    const details = await getMakeModelByVin(vin)
    setValue('Make', details?.Make)
    setValue('Model', details?.Model)
    setValue('ModelYear', Number(details?.ModelYear))
    setValue('VehicleTrimLevel', details?.VehicleTrimLevel?.slice(0, 64))
    return null
  }

  const existingData = useDebounce(getValues('Vin'), 500, getVehicleDetailsByVin)

  const onSubmit: SubmitHandler<SaleVehicleDto> = async (data) => {
    data.IsCertified = data.IsCertified === 'on'
    data.TookATrade = data.TookATrade === 'on'
    await insertSaleDto(data)
    return data
  }

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async (data) => onSubmit(data),
    onSuccess: async () => {
      if (!store) return
      const queryKey = ['sales-list']
      await queryClient.invalidateQueries({ queryKey, stale: true })
      setDialogOpen(false)
    }
  })

  const availableYears = Array.from({ length: 50 }, (_, i) => {
    const year = dayjs().subtract(i, 'year').year()
    return { value: year, label: String(year) }
  })

  const initializeControlledValues = () => {
    setValue('CompanyId', store?.CompanyId)
    setValue('IsActiveInventory', false)
    setValue('SoldAt', new Date(endDate))
  }

  const copyValues = () => {
    if (!existingData.value) return
    const data = existingData.value
    setValue('SaleVehicleId', data.SaleVehicleId)
    setValue('StockNumber', data.StockNumber)
    setValue('Vin', data.Vin)
    setValue('IsCertified', data.IsCertified ? 'on' : 'off')
    setValue('TookATrade', data.TookATrade ? 'on' : 'off')
    setValue('ModelYear', data.ModelYear)
    setValue('Make', data.Make)
    setValue('Model', data.Model)
    setValue('VehicleTrimLevel', data.VehicleTrimLevel)
    setValue('VehicleSourceId', data.VehicleSourceId)
    setValue('Odometer', data.Odometer)
    setValue('InitialAskingPrice', data.InitialAskingPrice)
    setValue('SellPrice', data.SellPrice)
    setValue('DaysInInventory', data.DaysInInventory)
    setValue('PercentOfMarket', data.PercentOfMarket)
    setValue('MSRP', data.MSRP)
    setValue('InitialManheimWholesale', data.InitialManheimWholesale)
    setValue('FinalManheimWholesale', data.FinalManheimWholesale)
    setValue('InitialACV', data.InitialACV)
    setValue('FinalACV', data.FinalACV)
    setValue('AppraisedValue', data.AppraisedValue)
    setValue('Notes', data.Notes)
    existingData.setValue(null)
  }

  const putFromExtension = () => {
    const element = document.getElementById('invisible-data-input')
    if (!element) return
    const data = element.getAttribute('data')
    if (!data) return
    const parsedData = JSON.parse(data)
    Object.keys(parsedData).forEach((key) => {
      if (!getValues(key)) setValue(key, parsedData[key])
    })
    if (parsedData.SourceName) {
      const matchedSource = sources.data?.[0]?.find((source) => source.SourceName === parsedData.SourceName)
      if (matchedSource) setValue('VehicleSourceId', matchedSource.VehicleSourceId)
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(newValue) => {
        setDialogOpen(newValue)
        if (newValue) initializeControlledValues()
        else reset()
      }}
    >
      <button
        id='add-sale-btn'
        className='hidden'
        onClick={() => {
          setDialogOpen(true)
          initializeControlledValues()
        }}
      />
      <DialogTrigger>
        <Button variant='outline'>Add Sale +</Button>
      </DialogTrigger>
      <DialogContent style={{ maxWidth: '48rem', width: '48rem' }}>
        <div id='invisible-data-input' className='hidden' onClick={putFromExtension} />
        <form onSubmit={handleSubmit((data) => mutateAsync(data))} className='flex flex-col gap-2'>
          <div className='flex justify-between items-center'>
            <DialogTitle>
              Add Sale
              {isPending && (
                <Badge className='ml-2 ' variant='secondary'>
                  Adding Sale <CircularProgress size={12} className='ml-2' />
                </Badge>
              )}
              {isError && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <Badge className='ml-2 ' variant='destructive'>
                        Error adding sale !
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent side='bottom'>{error?.message}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </DialogTitle>
            <Button
              type='submit'
              className='mt-4'
              variant='outline' /* disabled={isSubmitting || isSubmitSuccessful} */
              loading={isPending}
            >
              Finish and save for {store?.Name}
            </Button>
          </div>
          {existingData.value && (
            <Card>
              <Button variant='ghost' className='font-normal underline text-center w-full' onClick={copyValues}>
                We found an existing vehicle with the same VIN. Would you like to load the data?
              </Button>
            </Card>
          )}
          <Label className='opacity-60 mt-4'>Vehicle Information</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-5 gap-4'>
            <FormField
              title='Stock Number'
              fieldName='StockNumber'
              register={register}
              error={errors.StockNumber}
              placeholder='BZ239460'
            />
            <FormField
              title='Vin'
              fieldName='Vin'
              register={register}
              error={errors.Vin}
              colSpan={'2'}
              placeholder='1GCRKSE31BZ239460'
            />
            <div className='flex flex-col w-full gap-3 justify-start h-full'>
              <Label>Certified?</Label>
              <Switch {...register('IsCertified')} />
              <Label className={cn('text-red-500 font-light text-xs', !errors.IsCertified?.message && 'opacity-0')}>
                {errors.IsCertified?.message?.toString() ?? '.'}
              </Label>
            </div>
            <div className='flex flex-col item w-full gap-3 justify-start h-full'>
              <Label>Took a trade?</Label>
              <Switch {...register('TookATrade')} />
              <Label className={cn('text-red-500 font-light text-xs', !errors.TookATrade?.message && 'opacity-0')}>
                {errors.TookATrade?.message?.toString() ?? '.'}
              </Label>
            </div>
          </div>
          <div className='grid grid-cols-4 gap-x-4'>
            <FormSelect
              title='Year'
              fieldName='ModelYear'
              setValue={setValue}
              error={errors.ModelYear}
              placeholder='2021...'
              listItems={availableYears}
              getValues={getValues}
            />
            <FormField
              title='Make'
              fieldName='Make'
              register={register}
              error={errors.Make}
              placeholder='TOYOTA...'
              required
            />
            <FormField
              title='Model'
              fieldName='Model'
              register={register}
              error={errors.Model}
              placeholder='CAMRY...'
              required
            />
            <FormField
              title='Trim'
              fieldName='VehicleTrimLevel'
              register={register}
              error={errors.VehicleTrimLevel}
              placeholder='LE...'
            />
          </div>
          <Label className='opacity-60 mt-4'>General Pricing</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-3 gap-x-4'>
            <FormField
              title='Gross'
              fieldName='Gross'
              register={register}
              error={errors.Gross}
              placeholder='$...'
              required
            />
            <FormSelect
              title='Source'
              fieldName='VehicleSourceId'
              setValue={setValue}
              error={errors.VehicleSourceId}
              placeholder='Source...'
              listItems={sources.data?.[0]?.map((source) => ({
                value: source.VehicleSourceId,
                label: source.SourceName
              }))}
              getValues={getValues}
            />
            <FormField
              title='Odometer'
              fieldName='Odometer'
              register={register}
              error={errors.Odometer}
              placeholder='10000...'
            />
            <FormField
              title='Start price'
              fieldName='InitialAskingPrice'
              register={register}
              error={errors.InitialAskingPrice}
              placeholder='12345...'
            />
            <FormField
              title='Sell price'
              fieldName='SellPrice'
              register={register}
              error={errors.SellPrice}
              placeholder='23456...'
            />
            <FormField
              title='Days to sell'
              fieldName='DaysInInventory'
              register={register}
              error={errors.DaysInInventory}
              placeholder='4...'
            />
          </div>
          <Label className='opacity-60 mt-4'>Market Pricing</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-4 gap-x-4'>
            <FormField
              title='Percent of market'
              fieldName='PercentOfMarket'
              register={register}
              error={errors.PercentOfMarket}
              placeholder='99...'
            />
            <FormField title='MSRP' fieldName='MSRP' register={register} error={errors.MSRP} placeholder='12345...' />
            <FormField
              title='Initial MMR'
              fieldName='InitialManheimWholesale'
              register={register}
              error={errors.ManheimWholesale}
              placeholder='12345...'
            />
            <FormField
              title='Final MMR'
              fieldName='FinalManheimWholesale'
              register={register}
              error={errors.FinalManheimWholesale}
              placeholder='23456...'
            />
            <FormField
              title='Estimated Cost'
              fieldName='InitialACV'
              register={register}
              error={errors.InitialACV}
              placeholder='12345...'
            />
            <FormField
              title='Real Cost'
              fieldName='FinalACV'
              register={register}
              error={errors.FinalACV}
              placeholder='23456...'
            />
            <FormField
              title='Appraised Value'
              fieldName='AppraisedValue'
              register={register}
              error={errors.AppraisedValue}
              placeholder='99...'
            />
          </div>
          <Label className='opacity-60 mt-4'>Metadata</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-4 gap-x-4'>
            <FormField
              title='Notes'
              fieldName='Notes'
              register={register}
              error={errors.Notes}
              colSpan='3'
              placeholder='in transit...'
            />
            <div className='flex flex-col w-full gap-1'>
              <Label>Sold at</Label>
              <DatePicker
                date={getValues('SoldAt')}
                setDate={(data: string) => setValue('SoldAt', new Date(data))}
                size='sm'
              />
              <Label
                className={cn('text-red-500 font-light text-xs', !errors.SoldAt?.message?.toString() && 'opacity-0')}
              >
                {errors.SoldAt?.message?.toString() ?? '.'}
              </Label>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

type FormFieldProps = {
  title: string
  fieldName: keyof SaleVehicle
  register: any
  error: any
  colSpan?: '1' | '2' | '3' | '4'
  placeholder?: string
  required?: boolean
}

var FormField = ({ title, fieldName, register, error, colSpan = '1', placeholder, required }: FormFieldProps) => {
  let colspanString = ''
  if (colSpan === '2') {
    colspanString = 'col-span-2'
  } else if (colSpan === '4') {
    colspanString = 'col-span-4'
  } else if (colSpan === '3') {
    colspanString = 'col-span-3'
  } else {
    colspanString = 'col-span-1'
  }
  return (
    <div className={`flex flex-col w-full gap-1 ${colspanString}`}>
      <Label>
        {title}
        {required && <span className='text-red-500'>*</span>}
      </Label>
      <Input type='text' {...register(fieldName)} placeholder={placeholder} size='sm' />
      <Label className={cn('text-red-500 font-light text-xs', !error?.message?.toString() && 'opacity-0')}>
        {error?.message?.toString() ?? '.'}
      </Label>
    </div>
  )
}

type FormSelectProps = Omit<FormFieldProps, 'register'> & {
  listItems: any[] | undefined
  setValue: any
  getValues: any
}

var FormSelect = ({
  title,
  fieldName,
  setValue,
  error,
  colSpan = '1',
  placeholder,
  listItems,
  getValues
}: FormSelectProps) => {
  const colspanString = `col-span-${String(colSpan)}`
  const activeValue = getValues(fieldName)
  const valueRef = listItems?.find((item) => item.value === activeValue)
  return (
    <div className={`flex flex-col w-full gap-1 ${colspanString}`}>
      <Label>
        {title} <span className='text-red-500'>*</span>
      </Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant='outline' className='w-full justify-start text-sm font-normal' size='sm'>
            <span className={cn('opacity-100', !valueRef && 'opacity-40')}>{valueRef?.label ?? placeholder}</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <Command>
            <CommandInput placeholder='Search...' />
            <CommandEmpty>No Options Found.</CommandEmpty>
            <CommandList>
              {listItems?.map((item) => (
                <CommandItem
                  className={cn('cursor-pointer', activeValue === item.value && 'bg-primary/30')}
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    setValue(fieldName, item.value)
                  }}
                >
                  <span>{item.label}</span>
                </CommandItem>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Label className={cn('text-red-500 font-light text-xs', !error?.message && 'opacity-0')}>
        {error?.message?.toString() ?? '.'}
      </Label>
    </div>
  )
}
