import { cn } from '@/lib/utils'
import { useLocation } from 'react-router-dom'
import { routes } from '@/setup/routing/routes'
import BaseUrlButtons from './components/base-url-buttons'
import DateSelector from './components/date-selector'
import ReportOptions from './components/report-options'
import useUser from '@/hooks/use-user'
import UserAvatar from '@/components/user-avatar'
import StoreSelector from './components/store-selector'

enum HeaderVariant {
  BaseUrl,
  Hidden,
  Full
}

export default function Header() {
  const location = useLocation()
  const user = useUser()

  const { displayVariant, widthVariant } = getVariant(location.pathname)
  if (displayVariant === HeaderVariant.Hidden) return null
  return (
    <header
      className={cn(
        'p-4 flex justify-end bg-border items-center border-b border-border h-12 sticky top-0 z-[999]',
        widthVariant
      )}
    >
      {displayVariant === HeaderVariant.BaseUrl && <BaseUrlButtons />}
      {displayVariant === HeaderVariant.Full && (
        <div className='items-center h-12 p-2 flex'>
          <ReportOptions />
        </div>
      )}
      {displayVariant === HeaderVariant.Full && (
        <div className='items-center h-12 p-2 flex'>
          <DateSelector />
        </div>
      )}
      {displayVariant === HeaderVariant.Full && (
        <div className='items-center h-12 p-2 flex'>
          <StoreSelector />
        </div>
      )}
      {displayVariant === HeaderVariant.Full && (
        <div className='items-center aspect-square h-12 w-12 p-2 flex'>
          <UserAvatar user={user.data} />
        </div>
      )}
    </header>
  )
}

function getVariant(urlPathname: string) {
  const currentRouteObject = routes.find((route) => route.path === urlPathname)
  const displayVariant =
    currentRouteObject?.properties?.layout?.header === 'base'
      ? HeaderVariant.BaseUrl
      : currentRouteObject?.properties?.layout?.header === 'full'
      ? HeaderVariant.Full
      : HeaderVariant.Hidden
  const widthVariant = currentRouteObject?.properties?.layout?.sidebar === 'hidden' ? 'w-screen' : 'w-full'
  return { displayVariant, widthVariant }
}
