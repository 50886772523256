import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Switch
} from '@/components/ui'
import { useSaleVehicleDto } from '@/hooks/use-sale-vehicle-dto'
import { SubmitHandler } from 'react-hook-form'
import { cn } from '@/lib/utils'
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import useSources from '@/layouts/header/hooks/use-sources'
import dayjs from 'dayjs'
import DatePicker from '@/components/date-picker'
import { insertInventoryDto } from '@/services/api/inventory'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material'
import { useState } from 'react'

type AddInventoryProps = {
  store: Company | undefined
}

export default function AddInventory({ store }: AddInventoryProps) {
  const { register, handleSubmit, errors, setValue, getValues, reset } = useSaleVehicleDto()
  const sources = useSources([store?.CompanyId ?? 0])
  const queryClient = useQueryClient()
  const [dialogOpen, setDialogOpen] = useState(false)

  const onSubmit: SubmitHandler<SaleVehicleDto> = async (data) => {
    data.IsCertified = data.IsCertified === 'on'
    data.TookATrade = data.TookATrade === 'on'
    await insertInventoryDto(data)
    return data
  }

  const { mutateAsync, isPending, isError, status } = useMutation({
    mutationFn: async (data) => onSubmit(data),
    onSuccess: () => {
      if (!store) return
      const queryKey = ['inventory-list']
      queryClient.invalidateQueries({ queryKey })
      setDialogOpen(false)
    }
  })
  console.log(status)

  const availableYears = Array.from({ length: 50 }, (_, i) => {
    const year = dayjs().subtract(i, 'year').year()
    return { value: year, label: String(year) }
  })

  const initializeControlledValues = () => {
    setValue('CompanyId', store?.CompanyId)
    setValue('IsActiveInventory', true)
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(newValue) => {
        setDialogOpen(newValue)
        if (newValue) initializeControlledValues()
        else reset()
      }}
    >
      <DialogTrigger>
        <Button variant='outline'>Add Inventory +</Button>
      </DialogTrigger>
      <DialogContent style={{ maxWidth: '48rem', width: '48rem' }}>
        <form onSubmit={handleSubmit((data) => mutateAsync(data))} className='flex flex-col gap-2'>
          <div className='flex justify-between items-center'>
            <DialogTitle>
              Add Inventory
              {isPending && (
                <Badge className='ml-2 ' variant='secondary'>
                  Adding Inventory <CircularProgress size={12} className='ml-2' />
                </Badge>
              )}
              {isError && (
                <Badge className='ml-2 ' variant='destructive'>
                  Error adding inventory !
                </Badge>
              )}
            </DialogTitle>
            <Button type='submit' className='mt-4' variant='outline' loading={isPending}>
              Finish and save for {store?.Name}
            </Button>
          </div>
          <Label className='opacity-60 mt-4'>Vehicle Information</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-5 gap-4'>
            <FormField
              title='Stock Number'
              fieldName='StockNumber'
              register={register}
              error={errors.StockNumber}
              placeholder='BZ239460'
            />
            <FormField
              title='Vin'
              fieldName='Vin'
              register={register}
              error={errors.Vin}
              colSpan={'2'}
              placeholder='1GCRKSE31BZ239460'
            />
            <div className='flex flex-col w-full gap-3 justify-start h-full'>
              <Label>Certified?</Label>
              <Switch {...register('IsCertified')} />
              <Label className={cn('text-red-500 font-light text-xs', !errors.IsCertified?.message && 'opacity-0')}>
                {errors.IsCertified?.message?.toString() ?? '.'}
              </Label>
            </div>
            <div className='flex flex-col item w-full gap-3 justify-start h-full'>
              <Label>Took a trade?</Label>
              <Switch {...register('TookATrade')} />
              <Label className={cn('text-red-500 font-light text-xs', !errors.TookATrade?.message && 'opacity-0')}>
                {errors.TookATrade?.message?.toString() ?? '.'}
              </Label>
            </div>
          </div>
          <div className='grid grid-cols-4 gap-x-4'>
            <FormSelect
              title='Year'
              fieldName='ModelYear'
              setValue={setValue}
              error={errors.ModelYear}
              placeholder='2021...'
              listItems={availableYears}
              getValues={getValues}
            />
            <FormField
              title='Make'
              fieldName='Make'
              register={register}
              error={errors.Make}
              placeholder='TOYOTA...'
              required
            />
            <FormField
              title='Model'
              fieldName='Model'
              register={register}
              error={errors.Model}
              placeholder='CAMRY...'
              required
            />
            <FormField
              title='Trim'
              fieldName='VehicleTrimLevel'
              register={register}
              error={errors.VehicleTrimLevel}
              placeholder='LE...'
            />
          </div>
          <Label className='opacity-60 mt-4'>General Pricing</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-3 gap-x-4'>
            <FormField
              title='Gross'
              fieldName='Gross'
              register={register}
              error={errors.Gross}
              placeholder='$...'
              required
            />
            <FormSelect
              title='Source'
              fieldName='VehicleSourceId'
              setValue={setValue}
              error={errors.VehicleSourceId}
              placeholder='Source...'
              listItems={sources.data?.[0]?.map((source) => ({
                value: source.VehicleSourceId,
                label: source.SourceName
              }))}
              getValues={getValues}
            />
            <FormField
              title='Odometer'
              fieldName='Odometer'
              register={register}
              error={errors.Odometer}
              placeholder='10000...'
            />
            <FormField
              title='Start price'
              fieldName='InitialAskingPrice'
              register={register}
              error={errors.InitialAskingPrice}
              placeholder='12345...'
            />
            <FormField
              title='Sell price'
              fieldName='SellPrice'
              register={register}
              error={errors.SellPrice}
              placeholder='23456...'
            />
            <FormField
              title='Days to sell'
              fieldName='DaysInInventory'
              register={register}
              error={errors.DaysInInventory}
              placeholder='4...'
            />
          </div>
          <Label className='opacity-60 mt-4'>Market Pricing</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-4 gap-x-4'>
            <FormField
              title='Percent of market'
              fieldName='PercentOfMarket'
              register={register}
              error={errors.PercentOfMarket}
              placeholder='99...'
            />
            <FormField title='MSRP' fieldName='MSRP' register={register} error={errors.MSRP} placeholder='12345...' />
            <FormField
              title='Initial MMR'
              fieldName='InitialManheimWholesale'
              register={register}
              error={errors.ManheimWholesale}
              placeholder='12345...'
            />
            <FormField
              title='Final MMR'
              fieldName='FinalManheimWholesale'
              register={register}
              error={errors.FinalManheimWholesale}
              placeholder='23456...'
            />
            <FormField
              title='Estimated Cost'
              fieldName='InitialACV'
              register={register}
              error={errors.InitialACV}
              placeholder='12345...'
            />
            <FormField
              title='Real Cost'
              fieldName='FinalACV'
              register={register}
              error={errors.FinalACV}
              placeholder='23456...'
            />
            <FormField
              title='Appraised Value'
              fieldName='AppraisedValue'
              register={register}
              error={errors.AppraisedValue}
              placeholder='99...'
            />
          </div>
          <Label className='opacity-60 mt-4'>Metadata</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-4 gap-x-4'>
            <FormField
              title='Notes'
              fieldName='Notes'
              register={register}
              error={errors.Notes}
              colSpan='3'
              placeholder='in transit...'
            />
            <div className='flex flex-col w-full gap-1'>
              <Label>Sold at</Label>
              <DatePicker
                date={getValues('SoldAt')}
                setDate={(data: string) => setValue('SoldAt', new Date(data))}
                size='sm'
              />
              <Label
                className={cn('text-red-500 font-light text-xs', !errors.SoldAt?.message?.toString() && 'opacity-0')}
              >
                {errors.SoldAt?.message?.toString() ?? '.'}
              </Label>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

type FormFieldProps = {
  title: string
  fieldName: keyof SaleVehicle
  register: any
  error: any
  colSpan?: '1' | '2' | '3' | '4'
  placeholder?: string
  required?: boolean
}

var FormField = ({ title, fieldName, register, error, colSpan = '1', placeholder, required }: FormFieldProps) => {
  let colspanString = ''
  if (colSpan === '2') {
    colspanString = 'col-span-2'
  } else if (colSpan === '4') {
    colspanString = 'col-span-4'
  } else if (colSpan === '3') {
    colspanString = 'col-span-3'
  } else {
    colspanString = 'col-span-1'
  }
  return (
    <div className={`flex flex-col w-full gap-1 ${colspanString}`}>
      <Label>
        {title}
        {required && <span className='text-red-500'>*</span>}
      </Label>
      <Input type='text' {...register(fieldName)} placeholder={placeholder} size='sm' />
      <Label className={cn('text-red-500 font-light text-xs', !error?.message?.toString() && 'opacity-0')}>
        {error?.message?.toString() ?? '.'}
      </Label>
    </div>
  )
}

type FormSelectProps = Omit<FormFieldProps, 'register'> & {
  listItems: any[] | undefined
  setValue: any
  getValues: any
}

var FormSelect = ({
  title,
  fieldName,
  setValue,
  error,
  colSpan = '1',
  placeholder,
  listItems,
  getValues
}: FormSelectProps) => {
  const colspanString = `col-span-${String(colSpan)}`
  const activeValue = getValues(fieldName)
  const valueRef = listItems?.find((item) => item.value === activeValue)
  return (
    <div className={`flex flex-col w-full gap-1 ${colspanString}`}>
      <Label>
        {title} <span className='text-red-500'>*</span>
      </Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant='outline' className='w-full justify-start text-sm font-normal' size='sm'>
            <span className={cn('opacity-100', !valueRef && 'opacity-40')}>{valueRef?.label ?? placeholder}</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <Command>
            <CommandInput placeholder='Search...' />
            <CommandEmpty>No Options Found.</CommandEmpty>
            <CommandList>
              {listItems?.map((item) => (
                <CommandItem
                  className='cursor-pointer'
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    setValue(fieldName, item.value)
                  }}
                >
                  <span>{item.label}</span>
                </CommandItem>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Label className={cn('text-red-500 font-light text-xs', !error?.message && 'opacity-0')}>
        {error?.message?.toString() ?? '.'}
      </Label>
    </div>
  )
}
