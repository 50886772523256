import { StoreContext } from '@/services/providers/store-context-provider'
import { DataGridPro } from '@mui/x-data-grid-pro'
import useDataGridProperties from '../hooks/use-data-grid-properties'
import InventoryTableHeader from './inventory-table-header'
import { memo, useMemo } from 'react'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { useInventory } from '../hooks/use-inventory'
import { Badge } from '@/components/ui'

type InventoryTableProps = {
  store: StoreContext
}

export default function InventoryTable({ store }: InventoryTableProps) {
  const { getDataGridColumns, getDataGridRows, getDataGridRowClassnames, getDataGridCellClassnames } =
    useDataGridProperties()
  const inventory = useInventory(store.CompanyId)
  const preferences = useLocalAppPreferences()

  const filteredDocuments = useMemo(
    () => filterDocuments(inventory.data),
    [inventory.data, preferences.preferences.lists.vehicles.filters]
  )

  function filterDocuments(documents: SaleVehicle[] | Adjustment[]) {
    const vehicleFilters: AppPreferences['lists']['vehicles']['filters'] =
      preferences.preferences.lists.vehicles.filters
    if (!vehicleFilters) return documents
    if (vehicleFilters.length === 0) return documents
    if (!documents) return []

    return documents.filter((document) => {
      const vehicle = document as SaleVehicle
      return vehicleFilters.every((filter: ListFilter<SaleVehicle>) => {
        const key: keyof SaleVehicle = filter.key
        const value: SaleVehicle[keyof SaleVehicle] = vehicle[key] as SaleVehicle[keyof SaleVehicle]
        switch (filter.operator) {
          case 'eq':
            return value === filter.value
          case 'ne':
            return value !== filter.value
          case 'contains':
            return String(value).includes(filter.value)
          case 'ncontains':
            return !String(value).includes(filter.value)
          case 'gt':
            return Number(value) > filter.value
          case 'lt':
            return Number(value) < filter.value
          case 'gte':
            return Number(value) >= filter.value
          case 'lte':
            return Number(value) <= filter.value
          case 'between':
            return Number(value) >= filter.value[0] && Number(value) <= filter.value[1]
          case 'exists':
            return value !== undefined
          case 'in':
            return filter.value.includes(value)
          case 'nin':
            return !filter.value.includes(value)

          default:
            return false
        }
      })
    })
  }

  const filters = preferences.preferences.lists.vehicles.filters
  const displayFilters = filters.map((filter) => filter.key + ' - ' + filter.operator + ' ' + filter.value)
  const removeFilter = (key: string) => {
    preferences.setPreference(
      'lists.vehicles.filters',
      filters.filter((filter) => filter.key !== key)
    )
  }

  const Toolbar = memo(() => <InventoryTableHeader store={store} />)

  return (
    <div className='h-full pb-9'>
      <div className='p-2 text-xs'>
        Inventory Filters:
        {displayFilters.map((filter, index) => {
          return (
            <Badge
              key={index}
              className='ml-2 font-normal cursor-pointer hover:underline'
              variant='secondary'
              onClick={() => removeFilter(filter.split(' - ')[0])}
            >
              {filter}
            </Badge>
          )
        })}
        {displayFilters.length === 0 && (
          <Badge className='ml-2 font-normal' variant='secondary'>
            None
          </Badge>
        )}
      </div>
      <DataGridPro
        rows={getDataGridRows(filteredDocuments as SaleVehicle[])}
        columns={getDataGridColumns()}
        loading={inventory.loading}
        getRowClassName={getDataGridRowClassnames}
        getCellClassName={(params) => getDataGridCellClassnames(params, store.TargetMargin)}
        density='standard'
        slots={{
          toolbar: Toolbar
        }}
      />
    </div>
  )
}
