import BurgerIcon from '@/assets/icons/burger-icon'
import LogoIcon from '@/assets/icons/logo-icon'
import LinkWithQuery from '@/components/link-with-query'
import { routes } from '@/setup/routing/routes'
import { useLocation } from 'react-router-dom'

enum LogoVariant {
  Hidden,
  Full
}

export default function Logo() {
  const location = useLocation()
  const currentRouteObject = routes.find((route) => route.path === location.pathname)
  const isShown = currentRouteObject?.properties?.layout?.sidebar === 'hidden' ? LogoVariant.Hidden : LogoVariant.Full
  const toggleSidebar = () => {
    const sidebar = document.querySelector('nav[data-open]')
    if (!sidebar) return
    sidebar.setAttribute('data-open', sidebar.getAttribute('data-open') === 'true' ? 'false' : 'true')
  }
  if (isShown === LogoVariant.Hidden) return null
  return (
    <>
      <LinkWithQuery to='/dashboard' className='z-[9999]'>
        <div className='items-center aspect-square h-12 w-12 p-2 border-b border-r border-border bg-border hidden md:flex z-[9999]'>
          <LogoIcon />
        </div>
      </LinkWithQuery>
      <div className='w-12 h-12 bg-border md:hidden z-[999] flex items-center justify-center'>
        <div
          className='items-center justify-center aspect-square h-10 w-10 p-2 bg-background rounded-full flex md:hidden z-[999] my-auto ml-1'
          onClick={toggleSidebar}
        >
          <BurgerIcon />
        </div>
      </div>
    </>
  )
}
