import { Button, Card, CardContent, CardHeader, CardTitle } from '@/components/ui'
import axios from '@/lib/axios'
import { useState } from 'react'

type Option = {
  name: string
  value: string
  authKey: string
}

type LoginAuthOptionsProps = {
  options: Option[]
  onSuccess: (option: Option) => void
}

export default function LoginAuthOptions({ options, onSuccess }: LoginAuthOptionsProps) {
  const [loading, setLoading] = useState(false)
  return (
    <div className='grid gap-4'>
      {options.map((option) => (
        <Card key={option.authKey}>
          <CardHeader>
            <CardTitle>Use your {option.name}</CardTitle>
          </CardHeader>
          <CardContent className='flex items-center justify-between'>
            <p className='text-muted-foreground'>{option.value}</p>
            <Button loading={loading} onClick={() => onGetCode(option)}>
              Use this
            </Button>
          </CardContent>
        </Card>
      ))}
    </div>
  )

  async function onGetCode(option: Option) {
    setLoading(true)
    const response = await axios.post('/auth/otp/' + option.name.toLowerCase(), {
      PreAuthToken: option.authKey
    })
    if (response.status > 201) throw new Error(response.statusText)
    setLoading(false)
    onSuccess(option)
  }
}
