import {
  Button,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  TableCell,
  TableRow
} from '@/components/ui'
import { CircleMinusIcon, Ellipsis, PlusCircleIcon } from 'lucide-react'
import CarsSymbols from './car-symbols'
import StockingIndicator from './stocking-indicator'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { v2 } from '@/lib/axios'

type FootprintTableRowProps = {
  item: any
  isExpanded: boolean
  setExpanded: any
  index: number
  CompanyId: number
  zipCode: string
}

export default function FootprintTableRow({
  item,
  isExpanded,
  setExpanded,
  index,
  CompanyId,
  zipCode
}: FootprintTableRowProps) {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const getCargurusListLink = async () => {
    const { data } = await v2.get(`/vehicle-details/cargurus-identifier?make=${item.make}&model=${item.name}`)
    const link = `https://www.cargurus.com/Cars/inventorylisting/viewDetailsFilterViewInventoryListing.action?zip=${zipCode}&distance=75&entitySelectingHelper.selectedEntity=${data}`
    window.open(link, '_blank')
  }
  return (
    <TableRow>
      <TableCell>
        {isExpanded ? (
          <CircleMinusIcon className='stroke-stone-500 cursor-pointer' onClick={() => setExpanded(null)} />
        ) : (
          <PlusCircleIcon className='stroke-stone-500 cursor-pointer' onClick={() => setExpanded(index)} />
        )}
      </TableCell>
      <TableCell>
        <div className='flex items-center gap-2'>
          {item.name}
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger asChild>
              <Ellipsis className='stroke-stone-500 cursor-pointer h-4 w-4' />
            </PopoverTrigger>
            <PopoverContent>
              <EditModal item={item} setPopoverOpen={setPopoverOpen} CompanyId={CompanyId} />
              <Separator />
              <Button variant='default' onClick={getCargurusListLink} className='w-full'>
                View market
              </Button>
            </PopoverContent>
          </Popover>
        </div>
      </TableCell>
      <TableCell>{item.unitsSoldLast90days}</TableCell>
      <TableCell>{item.targetDaysSupply} days</TableCell>
      <TableCell>{Math.round(item.daysSupply)} days</TableCell>
      <TableCell>{item.unitsInStock}</TableCell>
      <TableCell className='flex flex-col gap-2'>
        <div className='grid grid-cols-10'>
          <CarsSymbols item={item} />
        </div>
        <StockingIndicator item={item} />
      </TableCell>
    </TableRow>
  )
}

function EditModal({ item, setPopoverOpen, CompanyId }: any) {
  const queryClient = useQueryClient()
  const { data: footprintPreference } = useQuery({
    queryKey: ['footprint-preferences', CompanyId],
    queryFn: async () => {
      const { data } = await axios.get(`/inventory-footprint-preference/${CompanyId}`)
      return data[0]
    }
  })

  const { data: itemPreference, isPending } = useQuery({
    queryKey: ['maker-model-preferences', item.name],
    queryFn: async () => {
      const { data } = await axios.get(`/maker-model-preference/all/${CompanyId}`)
      const newItem = data.find((e: any) => e.Model.toUpperCase() === item.name.toUpperCase())
      return newItem ?? null
    }
  })

  const { mutateAsync: updatePreference, isPending: updatePending } = useMutation({
    mutationFn: async (data: any) => {
      await axios.patch(`/maker-model-preference/${itemPreference.MakerModelPreferenceId}`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['footprint-preferences']
      })
      queryClient.invalidateQueries({
        queryKey: ['footprint']
      })
      setPopoverOpen(false)
    }
  })

  const { mutateAsync: addPreference, isPending: addPending } = useMutation({
    mutationFn: async (data: any) => {
      await axios.post('/maker-model-preference', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['footprint-preferences']
      })
      queryClient.invalidateQueries({
        queryKey: ['footprint']
      })
      setPopoverOpen(false)
    }
  })

  const onSubmit = async (form: React.FormEvent<HTMLFormElement>) => {
    setPopoverOpen(false)
    form.preventDefault()
    const formData = new FormData(form.currentTarget)
    const data = {
      TargetDaysSupply: formData.get('TargetDaysSupply')
    }
    if (itemPreference) {
      await updatePreference(data)
    } else {
      await addPreference({
        ...data,
        Model: item.name,
        InventoryFootprintPreferenceId: footprintPreference?.InventoryFootprintPreferenceId
      })
    }
  }
  return (
    <form className='flex flex-col gap-1' onSubmit={onSubmit}>
      <Label>
        {itemPreference ? 'Edit' : 'Add preference for'} {item.name}
      </Label>
      <Label>Target days supply</Label>
      <Input
        key={isPending ? 'loading' : 'loaded'}
        type='number'
        placeholder='15'
        required
        name='TargetDaysSupply'
        defaultValue={itemPreference?.TargetDaysSupply ?? 15}
      />
      <Button variant='default' className='w-full' loading={updatePending || isPending || addPending}>
        {itemPreference ? 'Save changes and refresh data' : 'Add preference and refresh data'}
      </Button>
    </form>
  )
}
