import { useEffect } from 'react'

export default function useCancelScroll() {
  useEffect(() => {
    document.addEventListener('scroll', (ev) => {
      ev.preventDefault()
    })
    return () => {
      document.removeEventListener('scroll', (ev) => {
        ev.preventDefault()
      })
    }
  }, [])
}
