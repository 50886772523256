import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

export const useSaleVehicleDto = () => {
  const SaleVehicleDto: SaleVehicleDto = z.object({
    CompanyId: z.number(),
    Notes: z.string().nullable(),
    InitialACV: z.string().or(z.number()).nullable(),
    DaysInInventory: z.string().or(z.number()).nullable(),
    FinalManheimWholesale: z.string().or(z.number()).nullable(),
    IsCertified: z.string().refine(
      (value) => {
        return value === 'on' || value === 'off'
      },
      {
        message: 'IsCertified must be a boolean'
      }
    ),
    Gross: z.string().refine(
      (value) => {
        return value !== null && value !== undefined && value !== '' && !isNaN(Number(value))
      },
      {
        message: 'Gross must be a number'
      }
    ),
    PercentOfMarket: z.string().or(z.number()).nullable(),
    Odometer: z.string().or(z.number()).nullable(),
    MSRP: z.string().or(z.number()).nullable(),
    SellPrice: z.string().or(z.number()).nullable(),
    VehicleSourceId: z.number({
      message: 'Must pick a valid source'
    }),
    InitialAskingPrice: z.string().or(z.number()).nullable(),
    StockNumber: z.string().nullable(),
    Make: z
      .string()
      .refine(
        (value) => {
          return value !== null && value !== undefined && value !== ''
        },
        {
          message: 'Make must not be empty'
        }
      )
      .transform((value) => value.toUpperCase()),
    Model: z
      .string()
      .refine(
        (value) => {
          return value !== null && value !== undefined && value !== ''
        },
        {
          message: 'Model must not be empty'
        }
      )
      .transform((value) => value.toUpperCase()),
    ModelYear: z.number({
      message: 'Year must be a number'
    }),
    // vin can be null, but if its not null it must be 17 characters
    Vin: z
      .string()
      .nullable()
      .refine((value) => value === null || value.length === 17 || value === '', {
        message: 'Vin must be 17 characters'
      }),
    VehicleTrimLevel: z
      .string()
      .nullable()
      .refine((value) => (value?.length && value?.length <= 32) || !value, {
        message: 'Trim level must be less than 32 characters'
      }),
    InitialManheimWholesale: z.string().or(z.number()).nullable(),
    SoldAt: z.date({ message: 'Please use a valid date' }).nullable(),
    TookATrade: z.string().refine(
      (value) => {
        return value === 'on' || value === 'off'
      },
      {
        message: 'IsCertified must be a boolean'
      }
    ),
    IsActiveInventory: z.boolean(),
    CostToMarket: z.string().or(z.number()).nullable(),
    AppraisedValue: z.string().or(z.number()).nullable()
  })

  const defaultValues = {
    CompanyId: 0,
    Notes: null,
    InitialACV: null,
    DaysInInventory: null,
    FinalManheimWholesale: null,
    IsCertified: 'off',
    Gross: '',
    PercentOfMarket: null,
    Odometer: null,
    MSRP: null,
    SellPrice: null,
    VehicleSourceId: null,
    InitialAskingPrice: null,
    StockNumber: null,
    Make: '',
    Model: '',
    ModelYear: null, // or any default year
    Vin: null,
    VehicleTrimLevel: null,
    InitialManheimWholesale: null,
    SoldAt: new Date(), // or any valid default date
    TookATrade: 'off',
    IsActiveInventory: false,
    CostToMarket: null,
    AppraisedValue: null
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
    getValues,
    watch,
    reset
  } = useForm<SaleVehicleDto>({
    resolver: zodResolver(SaleVehicleDto),
    defaultValues
  })

  watch()

  return {
    register,
    handleSubmit,
    errors,
    isValid,
    setValue,
    getValues,
    reset,
    isSubmitting
  }
}
