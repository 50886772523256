import { Button } from '@/components/ui'
import ArrowBackIcon from '@/assets/icons/arrow-back-icon'
import LinkWithQuery from '@/components/link-with-query'
import LoginForm from './components/login-form'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import LoginOTPForm from './components/login-otp-form'
import LoginArt from '@/assets/images/login-art.jpg'
import LoginAuthOptions from './components/login-auth-options'

type Option = {
  name: string
  value: string
  authKey: string
}

export function Login() {
  const [step, setStep] = useState(0)
  const [twoFactorOptions, setTwoFactorOptions] = useState<any[]>([])
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)
  return (
    <div className='w-full lg:grid h-screen lg:grid-cols-2 '>
      <div className='flex items-center justify-center py-12 w-full lg:border-r'>
        <div className='mx-auto grid w-[350px] gap-6'>
          <div className='grid gap-2 text-center'>
            <h1 className='text-3xl font-bold'>Login</h1>
            <p className='text-balance text-muted-foreground'>Enter your email below to login to your account</p>
          </div>
          <div className='grid gap-4'>
            <AnimatePresence mode='wait'>
              {step === 1 ? (
                <motion.div
                  key='post'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <LoginAuthOptions
                    options={twoFactorOptions}
                    onSuccess={(option: Option) => {
                      setSelectedOption(option)
                      setStep(2)
                    }}
                  />
                </motion.div>
              ) : step === 2 ? (
                <motion.div
                  key='post'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <LoginOTPForm option={selectedOption} />
                </motion.div>
              ) : (
                <motion.div
                  key='pre'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <LoginForm
                    onSuccess={(options) => {
                      setTwoFactorOptions(options)
                      setStep(1)
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <LinkWithQuery to='/'>
              <Button className='w-full flex gap-2' variant='outline'>
                <ArrowBackIcon /> Go Back
              </Button>
            </LinkWithQuery>
          </div>
        </div>
      </div>
      <div className='hidden lg:flex bg-background items-center justify-center '>
        <img src={LoginArt} alt='Image' width='1920' height='1080' className='h-fit w-full object-cover' />
      </div>
    </div>
  )
}
