export const findAutomationMappingByName = (name: string, mappings: AutomationMapping[]) => {
  return mappings.find(mapping => mapping.ToKey.toUpperCase() === name.toUpperCase())
}

export const conversionFnToSemantic = (fn: 'to-date' | 'to-boolean' | 'to-number' | 'none', FromColumn: string) => {
  const semantic = {
    'to-date': `Converts format of date entered in column ${FromColumn} to proper date time`,
    'to-boolean': `Converts data in column ${FromColumn} from [not empty/empty] to [true/false]`,
    'to-number': `Converts column ${FromColumn} to pure number (removes all non-numeric characters)`,
    none: `Leaves column ${FromColumn} as is`
  }
  return semantic[fn]
}

// semantic date: MM/DD/YYYY
// proper dateTIME: YYYY-MM-DD HH:MM:SS