import axios from '@/lib/axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export function useAutomations(CompanyId: number) {
  const queryClient = useQueryClient()
  const { data: automations, isPending } = useQuery({
    queryKey: ['automations', CompanyId],
    queryFn: async () => (await axios.get(`/automations/${CompanyId}`)).data
  })

  const { mutateAsync: createAutomation } = useMutation({
    mutationFn: async (
      newAutomation: Omit<AutomationPreference, 'AutomationPreferenceId' | 'AutomationMappings' | 'AutomationRules' | 'LastRunDate'>
    ) => axios.put('/automations', newAutomation),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automations', CompanyId]
      })
  })

  const { mutateAsync: updateAutomation, isPending: updateLoading } = useMutation({
    mutationFn: async (newAutomation: Omit<AutomationPreference, 'AutomationMappings' | 'AutomationRules' | 'LastRunDate'>) =>
      axios.patch(`/automations/${newAutomation.AutomationPreferenceId}`, newAutomation),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automations', CompanyId]
      })
  })

  const onCreateAutomation = async (form: HTMLFormElement, AllowOverrideRequiredFields: boolean) => {
    const newAutomation: Omit<
      AutomationPreference,
      'AutomationPreferenceId' | 'AutomationMappings' | 'AutomationRules' | 'LastRunDate'
    > = {
      Name: form.Name?.value || null,
      IsActive: false,
      TripLogGoogleSheetId: form.TripLogGoogleSheetId?.value || null,
      EmailAddressesOnFailed: form.EmailAddressesOnFailed?.value || null,
      ExistingCompareValue: form.ExistingCompareValue.value,
      AffectedTable: 'SaleVehicle',
      CompanyId,
      AllowOverrideRequiredFields: AllowOverrideRequiredFields ? true : false
    }
    await createAutomation(newAutomation)
  }

  const onUpdateAutomation = async (form: HTMLFormElement, AutomationPreferenceId: number, IsActive: boolean, AllowOverrideRequiredFields: boolean) => {
    const newAutomation: Omit<AutomationPreference, 'AutomationMappings' | 'AutomationRules' | 'LastRunDate'> = {
      AutomationPreferenceId: +AutomationPreferenceId,
      Name: form.Name?.value || null,
      IsActive,
      TripLogGoogleSheetId: form.TripLogGoogleSheetId?.value || null,
      EmailAddressesOnFailed: form.EmailAddressesOnFailed?.value || null,
      ExistingCompareValue: form.ExistingCompareValue.value,
      AffectedTable: 'SaleVehicle',
      CompanyId,
      AllowOverrideRequiredFields: AllowOverrideRequiredFields ? true : false
    }
    await updateAutomation(newAutomation)
  }

  const { mutateAsync: createAutomationMapping, isPending: mappingCreateLoading } = useMutation({
    mutationFn: async (
      newAutomationMapping: Omit<
        AutomationMapping,
        'AutomationMappingId' | 'AutomationPreference'
      >
    ) => axios.put(`/automations/${newAutomationMapping.AutomationPreferenceId}/mappings`, newAutomationMapping),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automations', CompanyId]
      })
  })

  const { mutateAsync: updateAutomationMapping, isPending: mappingUpdateLoading } = useMutation({
    mutationFn: async (newAutomationMapping: Omit<
      AutomationMapping,
      'AutomationPreference'
    >) =>
      axios.patch(`/automations/${newAutomationMapping.AutomationPreferenceId}/mappings/${newAutomationMapping.AutomationMappingId}`, newAutomationMapping),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automations', CompanyId]
      })
  })

  const onCreateAutomationMapping = async (AutomationPreferenceId: number, form: HTMLFormElement) => {
    // console.log any values the form has
    const newAutomation: Omit<
      AutomationMapping,
      'AutomationPreference' | 'AutomationMappingId'
    > = {
      FromColumn: form.FromColumn?.value || null,
      ConvertionFn: form.ConvertionFn.value,
      ToKey: form.ToKey.value,
      AutomationPreferenceId: +AutomationPreferenceId,
    }
    await createAutomationMapping(newAutomation)
  }

  const onUpdateAutomationMapping = async (AutomationPreferenceId: number, AutomationMappingId: number, form: HTMLFormElement) => {
    const newAutomation: Omit<
      AutomationMapping,
      'AutomationPreference'
    > = {
      AutomationMappingId: +AutomationMappingId,
      FromColumn: form.FromColumn?.value || null,
      ConvertionFn: form.ConvertionFn.value,
      ToKey: form.ToKey.value,
      AutomationPreferenceId: +AutomationPreferenceId
    }
    await updateAutomationMapping(newAutomation)
  }

  const { mutateAsync: createAutomationRule, isPending: ruleCreateLoading } = useMutation({
    mutationFn: async (
      newAutomationRule: Omit<
        AutomationRule,
        'AutomationRuleId' | 'AutomationPreference'
      >
    ) => axios.put(`/automations/${newAutomationRule.AutomationPreferenceId}/rules`, newAutomationRule),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automations', CompanyId]
      })
  })

  const { mutateAsync: updateAutomationRule, isPending: ruleUpdateLoading } = useMutation({
    mutationFn: async (newAutomationRule: Omit<
      AutomationRule,
      'AutomationPreference'
    >) =>
      axios.patch(`/automations/${newAutomationRule.AutomationPreferenceId}/rules/${newAutomationRule.AutomationRuleId}`, newAutomationRule),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['automations', CompanyId]
      })
  })

  const onCreateAutomationRule = async (AutomationPreferenceId: number, form: HTMLFormElement) => {
    const newRule: AutomationRule = {} as AutomationRule
    if (form.CheckMethod.value === 'BackgroundColor') {
      const BackgroundColorRed = form.BackgroundColorRed
      if (BackgroundColorRed) {
        newRule.BackgroundColorRed = BackgroundColorRed.value / 255
      }
      const BackgroundColorGreen = form.BackgroundColorGreen
      if (BackgroundColorGreen) {
        newRule.BackgroundColorGreen = BackgroundColorGreen.value / 255
      }
      const BackgroundColorBlue = form.BackgroundColorBlue
      if (BackgroundColorBlue) {
        newRule.BackgroundColorBlue = BackgroundColorBlue.value / 255
      }
    }
    const newAutomation: Omit<
      AutomationRule,
      'AutomationPreference' | 'AutomationRuleId'
    > = {
      FromColumn: form.FromColumn?.value || null,
      BackgroundColorRed: newRule.BackgroundColorRed || null,
      BackgroundColorGreen: newRule.BackgroundColorGreen || null,
      BackgroundColorBlue: newRule.BackgroundColorBlue || null,
      ValueIsNotEmpty: form.ValueIsNotEmpty?.value || null,
      ValueEqualTo: form.ValueEqualTo?.value || null,
      ValueNotEqualTo: form.ValueNotEqualTo?.value || null,
      ValueGreaterThan: form.ValueGreaterThan?.value || null,
      ValueLessThan: form.ValueLessThan?.value || null,
      ValueIsEmpty: form.ValueIsEmpty?.value || null,
      AutomationPreferenceId: +AutomationPreferenceId,
    }
    await createAutomationRule(newAutomation)
  }

  const onUpdateAutomationRule = async (AutomationPreferenceId: number, AutomationRuleId: number, form: HTMLFormElement) => {
    const newRule: AutomationRule = {
    } as AutomationRule
    if (form.CheckMethod.value === 'BackgroundColor') {
      const BackgroundColorRed = form.BackgroundColorRed
      if (BackgroundColorRed) {
        newRule.BackgroundColorRed = BackgroundColorRed.value / 255
      }
      const BackgroundColorGreen = form.BackgroundColorGreen
      if (BackgroundColorGreen) {
        newRule.BackgroundColorGreen = BackgroundColorGreen.value / 255
      }
      const BackgroundColorBlue = form.BackgroundColorBlue
      if (BackgroundColorBlue) {
        newRule.BackgroundColorBlue = BackgroundColorBlue.value / 255
      }
    } else {
    }
    const newAutomation: Omit<
      AutomationRule,
      'AutomationPreference'
    > = {
      FromColumn: form.FromColumn?.value || null,
      BackgroundColorRed: newRule.BackgroundColorRed || null,
      BackgroundColorGreen: newRule.BackgroundColorGreen || null,
      BackgroundColorBlue: newRule.BackgroundColorBlue || null,
      ValueIsNotEmpty: form.ValueIsNotEmpty?.value || null,
      ValueEqualTo: form.ValueEqualTo?.value || null,
      ValueNotEqualTo: form.ValueNotEqualTo?.value || null,
      ValueGreaterThan: form.ValueGreaterThan?.value || null,
      ValueLessThan: form.ValueLessThan?.value || null,
      ValueIsEmpty: form.ValueIsEmpty?.value || null,
      AutomationPreferenceId: +AutomationPreferenceId,
      AutomationRuleId: +AutomationRuleId
    }
    await updateAutomationRule(newAutomation)
  }

  return {
    reload: () => queryClient.invalidateQueries({
      queryKey: ['automations', CompanyId]
    }),
    automationPreferences: {
      data: automations,
      loading: isPending || updateLoading,
      create: onCreateAutomation,
      update: onUpdateAutomation,
    },
    automationMappings: {
      create: onCreateAutomationMapping,
      update: onUpdateAutomationMapping,
      loading: mappingCreateLoading || mappingUpdateLoading
    },
    automationRules: {
      create: onCreateAutomationRule,
      update: onUpdateAutomationRule,
      loading: ruleCreateLoading || ruleUpdateLoading
    }
  }
}
