import CalendarIcon from '@/assets/icons/calendar-icon'
import {
  Button,
  CardTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import useQueryDates from '@/hooks/use-query-dates'
import dayjs, { Dayjs } from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import { Separator } from '@/components/ui'
import DateRangePicker from '@/components/date-range-picker'
import { toast } from 'sonner'

export default function DateSelector() {
  const { startDate, endDate } = useQueryDates()
  const navigate = useNavigate()
  const location = useLocation()

  const onDateChange = (dates: [Dayjs, Dayjs]) => {
    const newParams = new URLSearchParams(location.search)
    newParams.set('startDate', dates[0].format('MM/DD/YYYY'))
    newParams.set('endDate', dates[1].format('MM/DD/YYYY'))
    navigate({ search: newParams.toString() })
  }

  const dateString = `${dayjs(startDate).format('MMM D')} - ${dayjs(endDate).format('MMM D')}`

  return (
    <Popover>
      <PopoverTrigger>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                variant='secondary'
                className='flex items-center gap-2 h-10 rounded-full md:rounded-md aspect-square md:aspect-auto bg-background'
              >
                <CalendarIcon /> <span className='hidden md:block'>{dateString}</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent side='bottom'>Change Dates</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </PopoverTrigger>
      <PopoverContent className='w-80 mr-6 flex flex-col gap-4'>
        <CardTitle className='text-center'>Change Date Range</CardTitle>
        <Separator className='w-full' />
        <DateRangePicker
          dates={[startDate, endDate]}
          setDates={(data: [string, string]) => onDateChange([dayjs(data[0]), dayjs(data[1])])}
        />
        <Separator className='w-full' />
        <DatePreset
          label='MTD'
          dates={[dayjs().startOf('month').format('MM/DD/YYYY'), dayjs().subtract(1, 'day').format('MM/DD/YYYY')]}
          onDateChange={onDateChange}
        />
        <DatePreset
          label='Last Month MTD'
          dates={[
            dayjs().startOf('month').subtract(1, 'month').format('MM/DD/YYYY'),
            dayjs().subtract(1, 'day').subtract(1, 'month').format('MM/DD/YYYY')
          ]}
          onDateChange={onDateChange}
        />
        <DatePreset
          label='Last Month'
          dates={[
            dayjs().subtract(1, 'month').startOf('month').format('MM/DD/YYYY'),
            dayjs().subtract(1, 'month').endOf('month').format('MM/DD/YYYY')
          ]}
          onDateChange={onDateChange}
        />
        <DatePreset
          label='YTD'
          dates={[dayjs().startOf('year').format('MM/DD/YYYY'), dayjs().subtract(1, 'day').format('MM/DD/YYYY')]}
          onDateChange={onDateChange}
        />
        <DatePreset
          label='Today'
          dates={[dayjs().format('MM/DD/YYYY'), dayjs().format('MM/DD/YYYY')]}
          onDateChange={onDateChange}
        />
        <DatePreset
          label='Yesterday'
          dates={[dayjs().subtract(1, 'day').format('MM/DD/YYYY'), dayjs().subtract(1, 'day').format('MM/DD/YYYY')]}
          onDateChange={onDateChange}
        />
        <DatePreset
          label='Last 7 Days'
          dates={[dayjs().subtract(8, 'day').format('MM/DD/YYYY'), dayjs().subtract(1, 'day').format('MM/DD/YYYY')]}
          onDateChange={onDateChange}
        />
        <DatePreset
          label='Last 30 Days'
          dates={[dayjs().subtract(31, 'day').format('MM/DD/YYYY'), dayjs().subtract(1, 'day').format('MM/DD/YYYY')]}
          onDateChange={onDateChange}
        />
      </PopoverContent>
    </Popover>
  )
}

type DatePresetProps = {
  label: string
  dates: [string, string]
  onDateChange: (dates: [Dayjs, Dayjs]) => void
}

function DatePreset({ label, dates, onDateChange }: DatePresetProps) {
  return (
    <Button
      variant='secondary'
      className='w-full'
      onClick={() => {
        onDateChange([dayjs(dates[0]), dayjs(dates[1])])
        toast.info('Date range updated to ' + label)
      }}
    >
      {label}
    </Button>
  )
}
