import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui'
import { cn } from '@/lib/utils'
import { useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { UploadIcon } from '@radix-ui/react-icons'
import Dropzone from 'react-dropzone'
import { insertAdjustment } from '@/services/api/adjustments'
import useUser from '@/hooks/use-user'
import { useQueryClient } from '@tanstack/react-query'

type UploadAdjustmentsProps = {
  store: Company | undefined
}

export default function UploadAdjustments({ store }: UploadAdjustmentsProps) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [uploads, setUploads] = useState<AdjustmentDto[]>([])
  const [uploadIndex, setUploadIndex] = useState(-1)
  const user = useUser()
  const queryClient = useQueryClient()

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        // log as csv
        const uint8Array = new Uint8Array(binaryStr as ArrayBuffer)
        const csv = new TextDecoder().decode(uint8Array)
        const rows = csv.split('\n')
        const fields = rows[0].split(',').map((e) => e.trim())
        if (!fields.includes('Value')) return
        if (!fields.includes('CreatedAt')) return
        if (!fields.includes('Notes')) return
        const newObjects = rows.slice(1).map((row) => {
          const values = row.split(',')
          return fields.reduce((acc, field, index) => {
            if (field === 'CreatedAt') values[index] = dayjs(values[index]).format('YYYY-MM-DD 06:00:00')
            // @ts-ignore
            acc[field] = values[index]
            return acc
          }, {})
        })
        setUploads(newObjects)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const onUpload = async () => {
    console.log(uploads, 'uploads')
    for (let i = 0; i < uploads.length; i++) {
      console.log('uploading', i, 'of', uploads.length, 'uploads')
      const data = uploads[i]
      data.CompanyId = store?.CompanyId
      data.UserId = user.data?.UserId
      if (!data.CompanyId || !data.UserId) return
      data.Value = parseFloat(data.Value)
      data.IsGAdjustment = Boolean(data.IsGAdjustment)
      setUploadIndex(i)
      console.log(data)
      await insertAdjustment(data.CompanyId, data)
    }
    setUploadIndex(uploads.length)
    queryClient.invalidateQueries({
      queryKey: ['adjustments-list']
    })
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(newValue) => {
        setDialogOpen(newValue)
        setUploads([])
        setUploadIndex(-1)
      }}
    >
      <DialogTrigger>
        <Button variant='outline' className='ml-2'>
          <UploadIcon /> Upload csv
        </Button>
      </DialogTrigger>
      <DialogContent style={{ maxWidth: '48rem', width: '48rem' }}>
        <div className='flex justify-between items-center'>
          <DialogTitle>Add adjustments</DialogTitle>
          <Button variant='outline' loading={uploadIndex !== -1} onClick={onUpload}>
            Start upload for {store?.Name}
          </Button>
        </div>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={cn(
                'group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25',
                'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                isDragActive && 'border-muted-foreground/50'
              )}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                  <div className='rounded-full border border-dashed p-3'>
                    <UploadIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                  </div>
                  <p className='font-medium text-muted-foreground'>Drop the file here</p>
                </div>
              ) : (
                <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                  <div className='rounded-full border border-dashed p-3'>
                    <UploadIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                  </div>
                  <div className='space-y-px'>
                    <p className='font-medium text-muted-foreground'>
                      Drag {`'n'`} drop a file here, or click to select a file
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
        <div className='max-h-96 overflow-y-auto'>
          <Table>
            <TableHeader>
              <TableRow>
                {uploads.length > 0 &&
                  Object.keys(uploads[0]).map((field) => <TableHead key={field}>{field}</TableHead>)}
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {uploads.map((object, index) => (
                <TableRow key={index}>
                  {Object.keys(object).map((field) => (
                    <TableCell key={field}>{object[field]}</TableCell>
                  ))}
                  <TableCell>{uploadIndex < index ? 'Ready' : uploadIndex === index ? 'Uploading' : 'Done'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  )
}
