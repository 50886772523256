import OptionsIcon from '@/assets/icons/options-icon'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import {} from '@/components/ui/popover'
import { useRef } from 'react'
import LinkWithQuery from './link-with-query'

type UserAvatarProps = {
  user: User | null
  color?: string
}

export default function UserAvatar({ user, color }: UserAvatarProps) {
  const avatarPositionRef = useRef<HTMLDivElement>(null)
  if (!user) return null
  const avatarFallback = `${user.FirstName[0].toUpperCase()}${user.LastName[0].toUpperCase()}`
  const logout = () => {
    localStorage.removeItem('Authorization')
    window.location.reload()
  }
  const colorString = color ? `bg-${color}` : 'bg-background'
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Avatar ref={avatarPositionRef} className='cursor-pointer border border-black/20 dark:border-white'>
                <AvatarImage src='#' />
                <AvatarFallback className={colorString}>{avatarFallback}</AvatarFallback>
              </Avatar>
            </TooltipTrigger>
            <TooltipContent side='bottom'>Settings</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-60'>
        <DropdownMenuLabel>Settings</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <LinkWithQuery to='/profile'>
          <DropdownMenuItem className='cursor-pointer'>
            Profile
            <DropdownMenuShortcut>
              <OptionsIcon />
            </DropdownMenuShortcut>
          </DropdownMenuItem>
        </LinkWithQuery>
        <LinkWithQuery to='/stores'>
          <DropdownMenuItem className='cursor-pointer'>Stores</DropdownMenuItem>
        </LinkWithQuery>
        <DropdownMenuItem className='text-destructive cursor-pointer' onClick={logout}>
          Sign Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
