import DataCard from '../data-card'
import SectionCard from '../section-card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import useOverviewAnalytics from '../../hooks/use-overview-analytics'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import BarChart from '@/components/charts/bar-chart'
import { CircleAlert, TriangleAlert } from 'lucide-react'
import useStores from '@/hooks/use-stores'

type OverviewTabProps = {
  stores: ReturnType<typeof useStores>
}

export default function OverviewTab({ stores }: OverviewTabProps) {
  const { data, loading } = useOverviewAnalytics(stores)
  const preferences = useLocalAppPreferences()
  const setTab = (tab: string) => preferences.setPreference('dashboard.defaultTab', tab)
  return (
    <div className='flex flex-col gap-4 pb-32'>
      <TooltipProvider>
        <div className='flex gap-4 items-center'>
          <SectionCard
            title='Sales overview'
            description={
              <div onClick={() => setTab('sales-breakdown')} className='cursor-pointer text-primary'>
                View detailed sales data
              </div>
            }
          >
            <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
              <DataCard
                title='Total sales'
                data={data}
                dataKey='TotalSales'
                type='number'
                loading={loading}
                color='primary'
              />
              <DataCard
                title='Average PVR'
                data={data}
                dataKey='AverageGrossPerSale'
                type='currency'
                loading={loading}
              />
              <DataCard title='Sales pace' data={data} dataKey='SalesPace' type='number' loading={loading} />
            </div>
          </SectionCard>
          <SectionCard
            title='Inventory overview'
            description={
              <div onClick={() => setTab('inventory-breakdown')} className='cursor-pointer text-primary'>
                View detailed inventory data
              </div>
            }
          >
            <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
              <DataCard
                title='Total inventory'
                data={data}
                dataKey='TotalInventory'
                type='number'
                loading={loading}
                color='primary'
              />
              <DataCard
                title='Total investment'
                data={data}
                dataKey='TotalInvestment'
                type='currency'
                loading={loading}
              />
              <DataCard title='Days supply' data={data} dataKey='DaysSupply' type='number' loading={loading} />
            </div>
          </SectionCard>
        </div>
        <SectionCard
          title='Action items'
          description='Action items are generated based on your inventory and sales data'
        >
          <div className='w-full p-0 pt-4'>
            <BarChart
              data={data?.map((item) => item.ActionItems.ChartData)}
              loading={loading}
              dataKey='label'
              valueKey='value'
              valueName='Total items'
              title='Action items'
              legendValues={data?.map((item) => item.Store)}
              cancelFormatTicks
              tickRotation={0}
              hideY
            />
          </div>
          <SectionCard
            title='Aged inventory'
            description='Aged inventory is inventory that has been in stock for a long time'
          >
            <Tabs defaultValue='0'>
              <TabsList>
                {stores?.data
                  .filter((e) => e.IsSelected)
                  .map((store, index) => (
                    <TabsTrigger key={store.CompanyId} value={String(index)}>
                      {store.Name}
                    </TabsTrigger>
                  ))}
              </TabsList>
              {stores?.data
                .filter((e) => e.IsSelected)
                .map((_, index) => (
                  <TabsContent value={String(index)} key={index}>
                    <div className='w-full h-72 overflow-auto'>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Vin</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Age</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data?.[index]?.ActionItems.AgedInventory.map((item: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>
                                {item.severity === 'critical' ? (
                                  <Tooltip delayDuration={0}>
                                    <TooltipTrigger>
                                      <CircleAlert size={16} className='text-red-500 inline-block mr-1' />
                                    </TooltipTrigger>
                                    <TooltipContent side='right'>
                                      This vehicle has been in stock for a long time
                                    </TooltipContent>
                                  </Tooltip>
                                ) : (
                                  <Tooltip delayDuration={0}>
                                    <TooltipTrigger>
                                      <TriangleAlert size={16} className='text-yellow-500 inline-block mr-1' />
                                    </TooltipTrigger>
                                    <TooltipContent side='right'>
                                      This vehicle is approaching a critical age
                                    </TooltipContent>
                                  </Tooltip>
                                )}
                                {item.data.Vin}
                              </TableCell>
                              <TableCell>{item.data.Name}</TableCell>
                              <TableCell>{item.data.DaysInInventory} days</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabsContent>
                ))}
            </Tabs>
          </SectionCard>
          <br />
          <SectionCard
            title='Low price:investment ratio'
            description='Low price:investment ratio vehicles have a low sell price compared to their final cost'
          >
            <Tabs defaultValue='0'>
              <TabsList>
                {stores?.data
                  .filter((e) => e.IsSelected)
                  .map((store, index) => (
                    <TabsTrigger key={store.CompanyId} value={String(index)}>
                      {store.Name}
                    </TabsTrigger>
                  ))}
              </TabsList>
              {stores?.data
                .filter((e) => e.IsSelected)
                .map((_, index) => (
                  <TabsContent value={String(index)} key={index}>
                    <div className='w-full h-72 overflow-auto'>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Vin</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>List price</TableHead>
                            <TableHead>Cost</TableHead>
                            <TableHead>Difference</TableHead>
                            <TableHead>Age</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data?.[index]?.ActionItems.LowPriceInvestmentRatio.map((item: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>
                                {item.severity === 'critical' ? (
                                  <Tooltip delayDuration={0}>
                                    <TooltipTrigger>
                                      <CircleAlert size={16} className='text-red-500 inline-block mr-1' />
                                    </TooltipTrigger>
                                    <TooltipContent side='right'>
                                      This vehicle is priced less than its cost
                                    </TooltipContent>
                                  </Tooltip>
                                ) : (
                                  <Tooltip delayDuration={0}>
                                    <TooltipTrigger>
                                      <TriangleAlert size={16} className='text-yellow-500 inline-block mr-1' />
                                    </TooltipTrigger>
                                    <TooltipContent side='right'>
                                      This vehicle isn&apos;t priced very high above its cost
                                    </TooltipContent>
                                  </Tooltip>
                                )}
                                {item.data.Vin}
                              </TableCell>
                              <TableCell>{item.data.Name}</TableCell>
                              <TableCell>${item.data.SellPrice.toLocaleString()}</TableCell>
                              <TableCell>${item.data.FinalACV.toLocaleString()}</TableCell>
                              <TableCell>${item.data.Difference?.toLocaleString()}</TableCell>
                              <TableCell>{item.data.Age} days</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabsContent>
                ))}
            </Tabs>
          </SectionCard>
          <br />
          <SectionCard
            title='Negative gross sales'
            description='Negative gross sales are sales where the gross is less than 0'
          >
            <Tabs defaultValue='0'>
              <TabsList>
                {stores?.data
                  .filter((e) => e.IsSelected)
                  .map((store, index) => (
                    <TabsTrigger key={store.CompanyId} value={String(index)}>
                      {store.Name}
                    </TabsTrigger>
                  ))}
              </TabsList>
              {stores?.data
                .filter((e) => e.IsSelected)
                .map((_, index) => (
                  <TabsContent value={String(index)} key={index}>
                    <div className='w-full h-72 overflow-auto'>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Vin</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Gross</TableHead>
                            <TableHead>Age</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data?.[index]?.ActionItems.NegativeGross.map((item: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>{item.data.Vin}</TableCell>
                              <TableCell>{item.data.Name}</TableCell>
                              <TableCell>${item.data.Gross.toLocaleString()}</TableCell>
                              <TableCell>{item.data.DaysInInventory ?? 1} days</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabsContent>
                ))}
            </Tabs>
          </SectionCard>
          <br />
          <SectionCard
            title='Overstocked models'
            description='Overstocked models are models in inventory which you have a higher days supply of than you want'
          >
            <Tabs defaultValue='0'>
              <TabsList>
                {stores?.data
                  .filter((e) => e.IsSelected)
                  .map((store, index) => (
                    <TabsTrigger key={store.CompanyId} value={String(index)}>
                      {store.Name}
                    </TabsTrigger>
                  ))}
              </TabsList>
              {stores?.data
                .filter((e) => e.IsSelected)
                .map((_, index) => (
                  <TabsContent value={String(index)} key={index}>
                    <div className='w-full h-72 overflow-auto'>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Model</TableHead>
                            <TableHead>Count in inventory</TableHead>
                            <TableHead>Target days supply</TableHead>
                            <TableHead>Current days supply</TableHead>
                            <TableHead>Offset</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data?.[index]?.ActionItems.HighUnitCountForModel.map((item: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>{item.data.name}</TableCell>
                              <TableCell>{item.data.unitsInStock}</TableCell>
                              <TableCell>{item.data.targetDaysSupply}</TableCell>
                              <TableCell>{item.data.daysSupply}</TableCell>
                              <TableCell>{Math.abs(item.data.amountOffset)} too many</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabsContent>
                ))}
            </Tabs>
          </SectionCard>
          <br />
          <SectionCard
            title='Understocked models'
            description='Understocked models are models in inventory which you have a lower days supply of than you want'
          >
            <Tabs defaultValue='0'>
              <TabsList>
                {stores?.data
                  .filter((e) => e.IsSelected)
                  .map((store, index) => (
                    <TabsTrigger key={store.CompanyId} value={String(index)}>
                      {store.Name}
                    </TabsTrigger>
                  ))}
              </TabsList>
              {stores?.data
                .filter((e) => e.IsSelected)
                .map((_, index) => (
                  <TabsContent value={String(index)} key={index}>
                    <div className='w-full h-72 overflow-auto'>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Model</TableHead>
                            <TableHead>Count in inventory</TableHead>
                            <TableHead>Target days supply</TableHead>
                            <TableHead>Current days supply</TableHead>
                            <TableHead>Offset</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data?.[index]?.ActionItems.LowUnitCountForModel.map((item: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>{item.data.name}</TableCell>
                              <TableCell>{item.data.unitsInStock}</TableCell>
                              <TableCell>{item.data.targetDaysSupply}</TableCell>
                              <TableCell>{item.data.daysSupply}</TableCell>
                              <TableCell>{Math.abs(item.data.amountOffset)} too few</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabsContent>
                ))}
            </Tabs>
          </SectionCard>
        </SectionCard>
      </TooltipProvider>
    </div>
  )
}
