import ArrowForwardIcon from '@/assets/icons/arrow-forward-icon'
import LinkWithQuery from '@/components/link-with-query'
import { Button } from '@/components/ui'

export default function BaseUrlButtons() {
  return (
    <>
      <div></div>
      <div className='flex gap-2'>
        <div className='flex gap-2'>
          <LinkWithQuery to='/login'>
            <Button variant='outline'>
              Go To App <ArrowForwardIcon />
            </Button>
          </LinkWithQuery>
        </div>
      </div>
    </>
  )
}
