import { StoreContext } from '@/services/providers/store-context-provider'
import { DataGridPro } from '@mui/x-data-grid-pro'
import useLocalAppPreferences from '@/hooks/use-local-app-preferences'
import { Badge } from '@/components/ui'
import { useAdjustments } from '../hooks/use-adjustments'
import useQueryDates from '@/hooks/use-query-dates'
import useDataGridProperties from '../hooks/use-data-grid-properties'
import { memo, useState } from 'react'
import AdjustmentsTableHeader from './adjustments-table-header'

type AdjustmentsTableProps = {
  store: StoreContext
}

export default function AdjustmentsTable({ store }: AdjustmentsTableProps) {
  const { getDataGridColumns, getDataGridRows, getDataGridRowClassnames, getDataGridCellClassnames } =
    useDataGridProperties()
  const { startDate, endDate } = useQueryDates()
  const adjustments = useAdjustments(store.CompanyId, startDate, endDate)
  const preferences = useLocalAppPreferences()

  const filters = preferences.preferences.lists.adjustments.filters
  const displayFilters = filters.map((filter) => filter.key + ' - ' + filter.operator + ' ' + filter.value)
  const removeFilter = (key: string) => {
    preferences.setPreference(
      'lists.adjustments.filters',
      filters.filter((filter) => filter.key !== key)
    )
  }

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const Toolbar = memo(() => <AdjustmentsTableHeader store={store} selectedRows={selectedRows} />)

  return (
    <div className='h-full pb-9'>
      <div className='p-2 text-xs'>
        Adjustment Filters:
        {displayFilters.map((filter, index) => {
          return (
            <Badge
              key={index}
              className='ml-2 font-normal cursor-pointer hover:underline'
              variant='secondary'
              onClick={() => removeFilter(filter.split(' - ')[0])}
            >
              {filter}
            </Badge>
          )
        })}
        {displayFilters.length === 0 && (
          <Badge className='ml-2 font-normal' variant='secondary'>
            None
          </Badge>
        )}
      </div>
      <DataGridPro
        rows={getDataGridRows(adjustments.data as Adjustment[])}
        columns={getDataGridColumns()}
        loading={adjustments.loading}
        getRowClassName={getDataGridRowClassnames}
        getCellClassName={(params) => getDataGridCellClassnames(params)}
        density='standard'
        disableRowSelectionOnClick
        checkboxSelection
        slots={{
          toolbar: Toolbar
        }}
        onRowSelectionModelChange={(params) => setSelectedRows(params as number[])}
      />
    </div>
  )
}
