import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  Separator,
  Switch
} from '@/components/ui'
import { SubmitHandler } from 'react-hook-form'
import { cn } from '@/lib/utils'
import DatePicker from '@/components/date-picker'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import { insertAdjustment } from '@/services/api/adjustments'
import useUser from '@/hooks/use-user'
import useQueryDates from '@/hooks/use-query-dates'
import dayjs from 'dayjs'
import { useAdjustmentDto } from '../hooks/use-adjustment-dto'

type AddAdjustmentProps = {
  store: Company | undefined
}

export default function AddAdjustment({ store }: AddAdjustmentProps) {
  const { register, handleSubmit, errors, setValue, getValues, reset } = useAdjustmentDto()
  const user = useUser()
  const queryClient = useQueryClient()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { endDate } = useQueryDates()

  console.log(errors)

  const onSubmit: SubmitHandler<AdjustmentDto> = async (data) => {
    data.CreatedAt = dayjs(data.CreatedAt).format('YYYY-MM-DD HH:mm:ss')
    await insertAdjustment(store?.CompanyId, data)
    return data
  }

  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: async (data) => onSubmit(data),
    onSuccess: () => {
      if (!store) return
      const queryKey = ['adjustments-list']
      queryClient.invalidateQueries({ queryKey })
      setDialogOpen(false)
    }
  })

  const initializeControlledValues = () => {
    setValue('CompanyId', store?.CompanyId)
    setValue('UserId', user.data?.UserId)
    setValue('CreatedAt', dayjs(endDate).toDate())
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(newValue) => {
        setDialogOpen(newValue)
        if (newValue) initializeControlledValues()
        else reset()
      }}
    >
      <DialogTrigger>
        <Button variant='outline'>Add Adjustment +</Button>
      </DialogTrigger>
      <DialogContent style={{ maxWidth: '48rem', width: '48rem' }}>
        <form onSubmit={handleSubmit((data) => mutateAsync(data))} className='flex flex-col gap-2'>
          <div className='flex justify-between items-center'>
            <DialogTitle>
              Add adjustment
              {isPending && (
                <Badge className='ml-2 ' variant='secondary'>
                  Adding adjustment <CircularProgress size={12} className='ml-2' />
                </Badge>
              )}
              {isError && (
                <Badge className='ml-2 ' variant='destructive'>
                  Error adding adjustment !
                </Badge>
              )}
            </DialogTitle>
            <Button type='submit' className='mt-4' variant='outline' loading={isPending}>
              Finish and save for {store?.Name}
            </Button>
          </div>
          <Label className='opacity-60 mt-4'>Information</Label>
          <Separator className='my-1' />
          <div className='grid grid-cols-5 gap-4'>
            <FormField
              title='Amount'
              fieldName='Value'
              register={register}
              error={errors.Value}
              placeholder='1000...'
            />
            <FormField
              title='Notes'
              fieldName='Notes'
              register={register}
              colSpan='2'
              error={errors.Notes}
              placeholder='Late payment...'
            />
            <div className='flex flex-col w-full gap-1'>
              <Label>Created at</Label>
              <DatePicker
                date={getValues('CreatedAt')}
                setDate={(data: string) => setValue('CreatedAt', new Date(data))}
                size='sm'
              />
              <Label
                className={cn('text-red-500 font-light text-xs', !errors.CreatedAt?.message?.toString() && 'opacity-0')}
              >
                {errors.CreatedAt?.message?.toString() ?? '.'}
              </Label>
            </div>
            <div className='flex flex-col w-full gap-3 justify-start h-full'>
              <Label>G adjustment?</Label>
              <Switch {...register('IsGAdjustment')} />
              <Label className={cn('text-red-500 font-light text-xs', !errors.IsGAdjustment?.message && 'opacity-0')}>
                {errors.IsGAdjustment?.message?.toString() ?? '.'}
              </Label>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

type FormFieldProps = {
  title: string
  fieldName: keyof Adjustment
  register: any
  error: any
  colSpan?: '1' | '2' | '3' | '4'
  placeholder?: string
  required?: boolean
}

var FormField = ({ title, fieldName, register, error, colSpan = '1', placeholder, required }: FormFieldProps) => {
  let colspanString = ''
  if (colSpan === '2') {
    colspanString = 'col-span-2'
  } else if (colSpan === '4') {
    colspanString = 'col-span-4'
  } else if (colSpan === '3') {
    colspanString = 'col-span-3'
  } else {
    colspanString = 'col-span-1'
  }
  return (
    <div className={`flex flex-col w-full gap-1 ${colspanString}`}>
      <Label>
        {title}
        {required && <span className='text-red-500'>*</span>}
      </Label>
      <Input type='text' {...register(fieldName)} placeholder={placeholder} size='sm' />
      <Label className={cn('text-red-500 font-light text-xs', !error?.message?.toString() && 'opacity-0')}>
        {error?.message?.toString() ?? '.'}
      </Label>
    </div>
  )
}
