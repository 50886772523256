import LogoIcon from '@/assets/icons/logo-icon'

type LoadingSplashProps = {
  message?: string
}

export default function LoadingSplash({ message = '' }: LoadingSplashProps) {
  return (
    <div className='fixed top-0 left-0 w-screen h-screen background flex flex-col justify-center items-center animate-pulse'>
      <div className='flex items-center justify-center gap-2'>
        <div className='items-center aspect-square h-12 w-12 p-2 border-b border-r border-border bg-border relative'>
          <LogoIcon />
        </div>
        <h1 className='text-2xl font-bold'>Max</h1>
        <h1 className='text-2xl font-bold'>Autolytics</h1>
      </div>
      <p className='text-muted-foreground'>{message}</p>
    </div>
  )
}
