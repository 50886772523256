import LinkWithQuery from '@/components/link-with-query'
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui'
import { CardContent } from '@mui/material'
import { GridActionsCellItem, GridCellParams, GridRowClassNameParams, GridRowParams } from '@mui/x-data-grid-pro'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { BarChartBigIcon, EllipsisVerticalIcon } from 'lucide-react'

export default function useDataGridProperties() {
  return {
    getDataGridColumns,
    getDataGridRows,
    getDataGridRowClassnames,
    getDataGridCellClassnames
  }
}

function getDataGridCellClassnames(params: GridCellParams, targetMargin: number | null): string {
  if (params.field === 'Gross') {
    if (!params.value || typeof params.value !== 'string') return ''
    let actual = Number(params.value.replace('$', '').replace(',', ''))
    if (actual > (targetMargin ?? 0)) return 'text-green-500'
    else if (actual > 0) return 'text-yellow-500'
    else return 'text-red-600'
  } else if (params.field === 'TradesCount') {
    if (params.value === 0) return 'text-black/50'
  } else if (params.value === 'N/A') return 'text-black/50'
  return ''
}

function getDataGridRowClassnames(params: GridRowClassNameParams) {
  if (params.row.DisplayedRollback === true) return 'bg-red-100 text-xs'
  if (params.indexRelativeToCurrentPage % 2 === 0) return 'bg-white text-xs'
  return 'bg-slate-100 text-xs'
}

function getDataGridColumns() {
  return [
    {
      field: 'StockNumber',
      headerName: 'Stock Number',
      flex: 80,
      minWidth: 80
    },
    {
      field: 'FullVehicleName',
      headerName: 'Vehicle',
      flex: 150,
      minWidth: 150
    },
    { field: 'Vin', headerName: 'Vin', flex: 150, minWidth: 120, renderCell: renderVinCell },
    { field: 'Gross', headerName: 'Gross', flex: 100, minWidth: 80 },
    { field: 'DaysInInventory', headerName: 'Age', flex: 100 },
    { field: 'SourceName', headerName: 'Source', flex: 100 },
    { field: 'Odometer', headerName: 'Mileage', flex: 100 },
    { field: 'FinalInstantMarketValue', headerName: 'IMV', flex: 100 },
    { field: 'InitialACV', headerName: 'Estimated Cost', flex: 100 },
    { field: 'FinalACV', headerName: 'Cost', flex: 100 },
    { field: 'MSRP', headerName: 'MSRP', flex: 100 },
    { field: 'IsCertified', headerName: 'Certified', flex: 100 },
    { field: 'TookATrade', headerName: 'Trade?', flex: 100 },
    { field: 'InitialAskingPrice', headerName: 'Start Price', flex: 100 },
    { field: 'SellPrice', headerName: 'Sell Price', flex: 100 },
    { field: 'PercentOfMarket', headerName: '% of Market', flex: 100 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (row: GridRowParams) => [
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <GridActionsCellItem icon={<EllipsisVerticalIcon />} label='Options' />
          </DropdownMenuTrigger>
          <DropdownMenuContent className='flex-col flex gap-1'>
            <LinkWithQuery to={`/sale/${row.row['id']}`}>
              <Button variant='ghost' className='w-full'>
                <Pencil1Icon className='mr-2' /> Edit
              </Button>
            </LinkWithQuery>
            <Separator />
            <Button variant='ghost'>
              <BarChartBigIcon className='mr-2' /> Analyze
            </Button>
          </DropdownMenuContent>
        </DropdownMenu>
      ]
    }
  ]
}

interface RowObject {
  id: number
  StockNumber: string | null
  FullVehicleName: string | null
  Vin: string | null
  Gross: string | null
  DaysInInventory: number | null
  SourceName: string | null
  Odometer: string | null
  FinalInstantMarketValue: string | null
  InitialACV: string | null
  FinalACV: string | null
  MSRP: string | null
  IsCertified: string | null
  InitialAskingPrice: string | null
  SellPrice: string | null
  PercentOfMarket: string | null
  InitialCarGurus: string | null
  DisplayedRollback: boolean | null
  TookATrade: string | null
  cargurusMap: (number | undefined | null)[]
  cargurusTitle: string | null
  CarGurusIndex: number | null
  Discount: string | null
}
function getDataGridRows(sales: SaleVehicle[]) {
  if (!sales) return []
  const rows: (RowObject | null)[] = sales
    .filter((sale) => !Boolean(sale.DisplayedRollback))
    .map((sale) => {
      let carGurusIndex = null
      let cargurusMap: any[] = []
      let cargurusTitle = ''
      if (
        sale.FinalCarGurusGreatPrice &&
        sale.FinalCarGurusGoodPrice &&
        sale.FinalCarGurusFairPrice &&
        sale.FinalCarGurusHighPrice &&
        sale.FinalCarGurusOverPrice &&
        sale.SellPrice
      ) {
        cargurusMap = [
          sale.FinalCarGurusGreatPrice,
          sale.FinalCarGurusGoodPrice,
          sale.FinalCarGurusFairPrice,
          sale.FinalCarGurusHighPrice,
          sale.FinalCarGurusOverPrice
        ]
        let diff = cargurusMap.map((price) => Math.abs(Number(price) - Number(sale.SellPrice)))
        let min = Math.min(...diff)
        let index = diff.indexOf(min)
        carGurusIndex = index * 20
        let sumOfDiff = diff.reduce((sum, curr) => sum + curr, 0)
        let sumOfPrices = cargurusMap.reduce((sum, curr) => Number(sum) + Number(curr), 0)
        if (sumOfPrices) {
          carGurusIndex += (sumOfDiff / sumOfPrices) * 100
          switch (index) {
            case 0:
              cargurusTitle = 'Great Price'
              break
            case 1:
              cargurusTitle = 'Good Price'
              break
            case 2:
              cargurusTitle = 'Fair Price'
              break
            case 3:
              cargurusTitle = 'High Price'
              break
            case 4:
              cargurusTitle = 'Overpriced'
              break
            default:
              cargurusTitle = 'N/A'
              break
          }
        }
      }

      let rowObject = {
        id: sale.SaleVehicleId,
        StockNumber: sale.StockNumber,
        FullVehicleName: sale.FullVehicleName,
        Vin: sale.Vin,
        Gross: '$' + sale.Gross?.toLocaleString(),
        DaysInInventory: sale.DaysInInventory,
        SourceName: sale.SourceName,
        Odometer: sale.Odometer?.toLocaleString() ?? 'N/A',
        FinalInstantMarketValue: sale?.FinalInstantMarketValue ? '$' + sale.FinalInstantMarketValue : 'N/A',
        InitialACV: sale.InitialACV ? '$' + sale.InitialACV?.toLocaleString() : 'N/A',
        FinalACV: sale.FinalACV ? '$' + sale.FinalACV?.toLocaleString() : 'N/A',
        MSRP: sale.MSRP ? '$' + sale.MSRP?.toLocaleString() : 'N/A',
        IsCertified: sale.IsCertified ? 'Yes' : 'No',
        InitialAskingPrice: sale.InitialAskingPrice ? '$' + sale.InitialAskingPrice?.toLocaleString() : 'N/A',
        SellPrice: sale.SellPrice ? '$' + sale.SellPrice?.toLocaleString() : 'N/A',
        PercentOfMarket: (sale.PercentOfMarket ?? 'N/A')?.toLocaleString() + '%',
        InitialCarGurus: '',
        DisplayedRollback: false,
        TookATrade: sale.TookATrade ? 'Yes' : 'No',
        cargurusMap,
        cargurusTitle,
        CarGurusIndex: carGurusIndex,
        Discount:
          sale?.SellPrice && sale?.InitialAskingPrice
            ? (sale?.SellPrice ?? 0) - (sale?.InitialAskingPrice ?? 0) === 0
              ? 'N/A'
              : '$' + ((sale?.SellPrice ?? 0) - (sale?.InitialAskingPrice ?? 0)).toLocaleString()
            : 'N/A'
      }
      switch (Number(sale.InitialCarGurusChoice)) {
        case 0:
          rowObject.InitialCarGurus = String(sale.InitialCarGurusGreatPrice)
          break
        case 1:
          rowObject.InitialCarGurus = String(sale.InitialCarGurusGoodPrice)
          break
        case 2:
          rowObject.InitialCarGurus = String(sale.InitialCarGurusFairPrice)
          break
        case 3:
          rowObject.InitialCarGurus = String(sale.InitialCarGurusHighPrice)
          break
        case 4:
          rowObject.InitialCarGurus = String(sale.InitialCarGurusOverPrice)
          break
        default:
          rowObject.InitialCarGurus = ''
          break
      }
      rowObject.InitialCarGurus = '$' + rowObject.InitialCarGurus.toLocaleString()
      Object.keys(rowObject).forEach((key) => {
        // @ts-ignore
        if (rowObject[key] === null) rowObject[key] = 'N/A'
      })
      return rowObject
    })
  const rollbackRows: (RowObject | null)[] = sales
    .filter((sale) => String(sale.DisplayedRollback) === '1')
    .map((sale) => {
      let rowObject = {
        id: sale.SaleVehicleId * -1,
        StockNumber: sale.StockNumber,
        FullVehicleName: sale.FullVehicleName,
        Vin: sale.Vin,
        Gross: '$' + sale.Gross?.toLocaleString(),
        DaysInInventory: null,
        SourceName: null,
        Odometer: null,
        FinalInstantMarketValue: null,
        InitialACV: null,
        FinalACV: null,
        MSRP: null,
        IsCertified: null,
        InitialAskingPrice: null,
        SellPrice: null,
        PercentOfMarket: null,
        InitialCarGurus: null,
        DisplayedRollback: true,
        TookATrade: null,
        cargurusMap: [0, 0, 0, 0, 0],
        cargurusTitle: '',
        CarGurusIndex: 0,
        Discount: null
      }
      return rowObject
    })
  const filteredRows = rows.filter((row) => row !== null) as RowObject[]
  const filteredRollbackRows = rollbackRows.filter((row) => row !== null) as RowObject[]
  return [...filteredRows, ...filteredRollbackRows]
}

function renderVinCell(params: GridCellParams) {
  const row: SaleVehicle = params.row as SaleVehicle

  let carGurusIndex = params.row.CarGurusIndex
  return (
    <Tooltip delayDuration={0} disableHoverableContent>
      <TooltipTrigger>
        <span className='underline cursor-pointer'>{params.value?.toString() ?? 'No VIN number'}</span>
      </TooltipTrigger>
      <TooltipContent side='bottom' className='bg-transparent p-0 text-base w-96'>
        <Card>
          <CardHeader>
            <CardTitle>{row.FullVehicleName}</CardTitle>
            <p className='opacity-80 text-xs'>{row.Vin}</p>
          </CardHeader>
          <Separator />
          <CardContent className='text-sm'>
            {carGurusIndex &&
            params.row.cargurusMap.every((e: number | null | undefined) => Number(e) !== 0) &&
            params.row.cargurusMap?.length === 5 ? (
              <div className='w-full h-2 relative mt-6 mb-2'>
                <div
                  className='absolute top-4 left-0 h-4 border-2 border-background w-2 bg-black rounded-full z-[999]'
                  style={{
                    left: `${carGurusIndex}%`
                  }}
                ></div>
                <div className='w-full flex justify-center text-center -mt-4'>
                  <div className='w-full h-full bg-transparent text-start'>
                    ${params.row.cargurusMap?.[0].toLocaleString('en-US')}
                  </div>
                  {params.row.cargurusTitle}
                  <div className='w-full h-full bg-transparent text-end'>
                    ${params.row.cargurusMap?.[4].toLocaleString('en-US')}
                  </div>
                </div>
                <div className='h-2 rounded-full w-full mx-auto relative z-0'>
                  <div className='absolute top-0 left-0 botton-0 right-0 flex h-full w-full'>
                    <div className='bg-[#006100] w-full h-full rounded-l-full'></div>
                    <div className='bg-[#009100] w-full h-full'></div>
                    <div className='bg-[#00bd00] w-full h-full'></div>
                    <div className='bg-[#ff8400] w-full h-full'></div>
                    <div className='bg-[#f60000] w-full h-full rounded-r-full'></div>
                  </div>
                </div>
              </div>
            ) : (
              <p className='opacity-80'>No CarGurus Data</p>
            )}
          </CardContent>
        </Card>
      </TooltipContent>
    </Tooltip>
  )
}
