import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function proper(inputString: string | undefined | null) {
  if (!inputString) return ''
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
}