import axios from "@/lib/axios"

export async function getSalesByCompanyIdWithDates(id: number | null | undefined, startDate: string, endDate: string) {
  try {
    if (!id) return null
    const stores = await axios.get('/sales', {
      params: {
        CompanyId: id,
        startDate,
        endDate
      }
    })
    return stores.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}

export async function insertSaleDto(saleDto: SaleVehicleDto) {
  const response = await axios.put('/sales', saleDto)
  return response.data
}

export async function getSaleById(SaleVehicleId: number | undefined | null) {
  if (!SaleVehicleId) return null
  try {
    const sale = await axios.get('/sales/' + SaleVehicleId)
    return sale.data
  } catch (error: unknown) {
    console.error(error)
    return null
  }
}

type SaleEdit = {
  field: keyof SaleVehicle
  oldValue: any
  newValue: any
}

export async function editSaleById(SaleVehicleId: number, edits: SaleEdit[]) {
  const response = await axios.patch('/sales/' + SaleVehicleId, {
    fields: edits.map(edit => edit.field),
    values: edits.map(edit => edit.newValue)
  })
  return response.data
}