import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui'
import axios from '@/lib/axios'
import { proper } from '@/lib/utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EllipsisVertical } from 'lucide-react'
import { useState } from 'react'

type EditPreferencesProps = {
  store: Company | undefined
}

export default function EditPreferences({ store }: EditPreferencesProps) {
  const queryClient = useQueryClient()
  const { data: footprintPreferences } = useQuery({
    queryKey: ['footprint-preferences', store?.CompanyId ?? 0],
    queryFn: async () => {
      if (!store) return { DefaultTargetDaysSupply: 12 }
      const { data } = await axios.get('/inventory-footprint-preference/' + store.CompanyId)
      return data[0]
    }
  })

  const [adding, setAdding] = useState(false)

  const { mutateAsync: addPreference, isPending: addPending } = useMutation({
    mutationFn: async (data: any) => {
      await axios.post('/maker-model-preference', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['footprint-preferences', store?.CompanyId ?? 0]
      })
      queryClient.invalidateQueries({
        queryKey: ['footprint']
      })
    }
  })

  const { mutateAsync: updateDefaultTargetDaysSupply, isPending: updatePending } = useMutation({
    mutationFn: async (newDefaultTargetDaysSupply: number) => {
      console.log(footprintPreferences)
      await axios.patch('/inventory-footprint-preference/' + footprintPreferences?.InventoryFootprintPreferenceId, {
        DefaultTargetDaysSupply: newDefaultTargetDaysSupply
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['footprint-preferences', store?.CompanyId ?? 0]
      })
      queryClient.invalidateQueries({
        queryKey: ['footprint']
      })
    }
  })

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.currentTarget as HTMLFormElement
    const formData = new FormData(form)
    const data = {
      CompanyId: store?.CompanyId,
      Model: formData.get('Model'),
      TargetDaysSupply: formData.get('TargetDaysSupply'),
      InventoryFootprintPreferenceId: footprintPreferences?.InventoryFootprintPreferenceId
    }
    await addPreference(data)
    setAdding(false)
  }

  const onSaveChanges = async () => {
    const newDefaultTargetDaysSupplyInput = document.getElementById('default-days-supply')!! as HTMLInputElement
    const newDefaultTargetDaysSupply = Number(newDefaultTargetDaysSupplyInput.value)
    await updateDefaultTargetDaysSupply(newDefaultTargetDaysSupply)
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant='outline' className='rounded-b-none'>
          Edit preferences
        </Button>
      </DialogTrigger>
      {footprintPreferences && (
        <DialogContent>
          <DialogTitle>Footprint preferences</DialogTitle>
          <div className='flex flex-col gap-1'>
            <Label>Default days supply</Label>
            <Input
              type='number'
              placeholder='15'
              defaultValue={footprintPreferences.DefaultTargetDaysSupply}
              id='default-days-supply'
            />
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Model</TableHead>
                <TableHead>Target days supply</TableHead>
                <TableHead>Edit</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {footprintPreferences?.MakerModelPreferences?.map((mmp: any) => (
                <TableRow key={mmp.MakerModelPreferenceId}>
                  <TableCell>{proper(mmp.Model)}</TableCell>
                  <TableCell>{mmp.TargetDaysSupply}</TableCell>
                  <TableCell>
                    <Popover>
                      <PopoverTrigger asChild>
                        <EllipsisVertical className='cursor-pointer' />
                      </PopoverTrigger>
                      <PopoverContent>
                        <Label className='block text-lg'>Edit {mmp.Model}</Label>
                        <Label>Target days supply</Label>
                        <Input
                          type='number'
                          placeholder='15'
                          required
                          name='TargetDaysSupply_update'
                          defaultValue={mmp.TargetDaysSupply}
                          className='mb-2'
                        />
                        <Button
                          variant='default'
                          size='sm'
                          className='w-full mb-2'
                          onClick={async () => {
                            await axios.patch('/maker-model-preference/' + mmp.MakerModelPreferenceId, {
                              TargetDaysSupply: (
                                document.querySelector('[name=TargetDaysSupply_update]') as HTMLInputElement
                              ).value
                            })
                            queryClient.invalidateQueries({
                              queryKey: ['footprint-preferences', store?.CompanyId ?? 0]
                            })
                            queryClient.invalidateQueries({
                              queryKey: ['footprint']
                            })
                          }}
                        >
                          Save changes
                        </Button>
                        <Button
                          variant='destructive'
                          size='sm'
                          className='w-full'
                          onClick={async () => {
                            await axios.delete('/maker-model-preference/' + mmp.MakerModelPreferenceId)
                            queryClient.invalidateQueries({
                              queryKey: ['footprint-preferences', store?.CompanyId ?? 0]
                            })
                            queryClient.invalidateQueries({
                              queryKey: ['footprint']
                            })
                          }}
                        >
                          Delete this preference
                        </Button>
                      </PopoverContent>
                    </Popover>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3}>
                  <Popover open={adding} onOpenChange={setAdding}>
                    <PopoverTrigger asChild>
                      <Button variant='secondary' size='sm' className='w-full'>
                        Add custom target for a model
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <form className='flex flex-col gap-1' onSubmit={onSubmit}>
                        <Label>Model</Label>
                        <Input type='text' placeholder='Model' required name='Model' />
                        <Label>Target days supply</Label>
                        <Input type='number' placeholder='15' required name='TargetDaysSupply' />
                        <Button variant='default' className='w-full' loading={addPending}>
                          Add
                        </Button>
                      </form>
                    </PopoverContent>
                  </Popover>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <DialogFooter>
            <Button variant='default' loading={updatePending} onClick={onSaveChanges}>
              Save preferences
            </Button>
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  )
}
