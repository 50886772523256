import { getInventoryByCompanyId } from '@/services/api/inventory'
import { useQuery } from '@tanstack/react-query'

export const useInventory = (CompanyId: number | null | undefined) => {
  const fetchInventory = async () => {
    const inventory = getInventoryByCompanyId(CompanyId)
    return inventory
  }

  const { isPending, data, isError, error } = useQuery({
    queryKey: ['inventory-list', CompanyId],
    queryFn: fetchInventory
  })

  return { data, loading: isPending, error: isError ? error : null }
}
