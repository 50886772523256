import { motion } from 'framer-motion'
import { AuroraBackground } from './aurora-background'
import { Button } from '@/components/ui'
import LinkWithQuery from '@/components/link-with-query'

export function HeroSection() {
  return (
    <AuroraBackground className='mt-8 h-[30rem] overflow-hidden rounded-3xl drop-shadow-xl md:h-[50rem]'>
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.8,
          ease: 'easeInOut'
        }}
        className='relative flex flex-col items-center justify-center gap-4 px-4'
      >
        <div className='text-center text-3xl font-bold md:text-7xl dark:text-white'>Max Autolytics</div>
        <div className='py-4 text-base font-extralight opacity-50 md:text-4xl dark:text-neutral-200'>
          Understand your dealership's market
        </div>
        <LinkWithQuery to='/login'>
          <Button variant='outline' className='w-56 dark:text-white'>
            Go to app
          </Button>
        </LinkWithQuery>
      </motion.div>
    </AuroraBackground>
  )
}
