import { Badge } from '@/components/ui'
import { SalesOverviewData } from '@/services/api/v2/companies/:company-id/analytics/sales-overview'
import { Skeleton } from '@mui/material'

type CargurusPricingBarProps = {
  data?: SalesOverviewData['CargurusRating']
  carGurusPreference: 0 | 1 | 2 | 3 | 4
  loading?: boolean
}

export default function CargurusBar({ data, loading, carGurusPreference }: CargurusPricingBarProps) {
  const carGurusMap = ['Great Price', 'Good Price', 'Fair Price', 'High Price', 'Overpriced']
  if (loading) {
    return <Skeleton variant='rounded' width='100%' height={62} />
  }
  return (
    <div className='w-full relative flex flex-col gap-2'>
      <div className='text-xs w-full flex justify-around items-center'>
        <span>
          Great <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          Good <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          Fair <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          High <p className='hidden md:inline-block'>Price</p>
        </span>
        <span>
          Over<p className='hidden md:inline-block'>priced</p>
        </span>
      </div>

      <div className='h-2 rounded-full w-full relative'>
        <BackgroundBar />
        {!loading && data && (
          <div
            className='absolute top-0 h-5 w-2 -mt-1.5 border-2 border-background rounded-full bg-blue-500 z-10'
            style={{
              left: `calc(${Math.max(
                Math.min(data?.amountToMoveIndicatorFromLeft + data?.amountToMoveIndicatorFromPreset, 100),
                0
              )}% - 0.25rem)`
            }}
          ></div>
        )}
      </div>
      <div className='w-11/12 mx-auto flex flex-col md:flex-row'>
        {data ? (
          <div className='w-full flex gap-2 justify-center items-center text-xs md:text-sm'>
            <div className='h-5 w-2 border-2 border-background rounded-full bg-blue-500'></div>
            <span>
              Transacting $
              {(data?.averageSalePriceOffsetFromCarGurusChoice ?? 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              {data?.isHigher ? ' Above ' : ' Below '}
              {(carGurusMap[carGurusPreference] ?? '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
            <Badge variant={data?.isHigher ? 'default' : 'destructive'} className='hidden md:block'>
              {data?.isHigher ? 'Above' : 'Below'}
            </Badge>
          </div>
        ) : (
          <div className='w-full h-full opacity-50 flex justify-center items-center text-sm'>No Sales Data</div>
        )}
      </div>
    </div>
  )
}

function BackgroundBar() {
  return (
    <div className='absolute top-0 left-0 bottom-0 right-0 flex h-full w-full z-[0]'>
      <div className='bg-[#006100] w-full h-full rounded-l-full'></div>
      <div className='bg-[#009100] w-full h-full'></div>
      <div className='bg-[#00bd00] w-full h-full'></div>
      <div className='bg-[#ff8400] w-full h-full'></div>
      <div className='bg-[#f60000] w-full h-full rounded-r-full'></div>
    </div>
  )
}
