import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import useStores from '@/hooks/use-stores'
import SalesTable from './components/sales-table'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

export default function Sales() {
  const stores = useStores()
  const location = useLocation()
  const tabIndex = Number(new URLSearchParams(location.search).get('tabIndex') ?? 0)
  const defaultTab = String(stores.data.filter((store) => store.IsSelected)[tabIndex]?.CompanyId ?? 0)
  const [tab, setTab] = useState(defaultTab)

  useEffect(() => {
    setTab(defaultTab)
  }, [defaultTab])

  return (
    <>
      <div className='flex flex-col items-end w-full h-full overflow-y-hidden md:pb-9'>
        <Tabs value={tab} onValueChange={setTab} className='w-full h-full'>
          <TabsList className='rounded-b-none w-full justify-start'>
            {stores.data
              .filter((store) => store.IsSelected)
              .map((store) => (
                <TabsTrigger key={store.CompanyId} value={String(store.CompanyId)}>
                  {store.Name}
                </TabsTrigger>
              ))}
          </TabsList>
          {stores.data
            .filter((store) => store.IsSelected)
            .map((store) => (
              <TabsContent
                key={store.CompanyId}
                className='w-full overflow-y-auto h-full mt-0'
                value={String(store.CompanyId)}
              >
                <SalesTable store={store} />
              </TabsContent>
            ))}
        </Tabs>
      </div>
    </>
  )
}
