import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui'
import { proper } from '@/lib/utils'
import { GridCellParams, GridRowClassNameParams } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'

export default function useDataGridProperties() {
  return {
    getDataGridColumns,
    getDataGridRows,
    getDataGridRowClassnames,
    getDataGridCellClassnames
  }
}

function getDataGridCellClassnames(_: GridCellParams): string {
  return ''
}

function getDataGridRowClassnames(params: GridRowClassNameParams) {
  if (params.indexRelativeToCurrentPage % 2 === 0) return 'bg-white text-xs'
  return 'bg-slate-100 text-xs'
}

function getDataGridColumns() {
  return [
    {
      field: 'CreatedAt',
      headerName: 'Created At',
      flex: 80
    },
    {
      field: 'UserId',
      headerName: 'Created By',
      flex: 120,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='flex items-center h-full'>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Avatar className='border'>
                    <AvatarFallback>{params.row.UserInitials}</AvatarFallback>
                    <AvatarImage src={params.row.UserImage} alt={params.row.UserInitials} />
                  </Avatar>
                </TooltipTrigger>
                <TooltipContent side='right'>{params.row.UserFullName}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )
      }
    },
    {
      field: 'Value',
      headerName: 'Amount',
      flex: 40,
      sortComparator: (v1: string, v2: string) => Number(v1) - Number(v2),
      valueFormatter: (params: string) => '$' + Number(params).toLocaleString()
    },
    {
      field: 'IsGAdjustment',
      headerName: 'G Adjustment?',
      flex: 40
    },
    {
      field: 'Notes',
      headerName: 'Notes',
      flex: 400
    }
  ]
}

interface RowObject {
  id: Adjustment['AdjustmentId']
  CreatedAt: Adjustment['CreatedAt']
  UserInitials: Adjustment['UserFullName']
  UserFullName: Adjustment['UserFullName']
  UserId: Adjustment['UserId']
  Value: string
  IsGAdjustment: 'Yes' | 'No'
  Notes: Adjustment['Notes']
  UserImage: Adjustment['UserImage']
}
function getDataGridRows(adjustments: Adjustment[]) {
  if (!adjustments) return []
  const rows: (RowObject | null)[] = adjustments.map((adjustment) => {
    if (!adjustment) return null
    return {
      id: adjustment.AdjustmentId,
      CreatedAt: dayjs(adjustment.CreatedAt).format('MM/DD/YYYY'),
      UserInitials: (
        adjustment.UserFullName?.split(' ')
          .map((name) => name.slice(0, 1))
          .join('') ?? ''
      ).toUpperCase(),
      UserFullName: proper(adjustment.UserFullName),
      UserId: adjustment.UserId,
      Value: String(adjustment.Value),
      IsGAdjustment: adjustment.IsGAdjustment ? 'Yes' : 'No',
      Notes: adjustment.Notes,
      UserImage: adjustment.UserImage
    } satisfies RowObject
  })
  const filteredRows = rows.filter((row) => row !== null) as RowObject[]
  return filteredRows
}
