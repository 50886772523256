import { createContext, useEffect, useState } from 'react'

declare interface LocalAppPreferencesContextType {
  preferences: AppPreferences
  setPreference: <T extends AllValidKeys>(key: T, value: any) => void
}

type NestedKeys<T> = {
  [K in keyof T & (string | number)]: T[K] extends object ? K | `${K}.${NestedKeys<T[K]>}` : K
}[keyof T & (string | number)]

type AllValidKeys = NestedKeys<AppPreferences>
type LocalAppPreferencesContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}
export const LocalAppPreferencesContext = createContext({} as LocalAppPreferencesContextType)

export default function LocalAppPreferencesContextProvider({ children }: LocalAppPreferencesContextProviderProps) {
  const defaultPreferences: AppPreferences = {
    global: {},
    dashboard: {
      defaultTab: 'overview',
      layout: {
        cargurus: {
          tab: 0,
          'age-bucket': 0
        }
      },
      useAdjustments: false,
      excludeSources: []
    },
    lists: {
      vehicles: {
        filters: []
      },
      adjustments: {
        filters: []
      }
    }
  }
  const [preferences, setPreferences] = useState<AppPreferences>(defaultPreferences)

  const assertPreferencesObject = () => {
    if (!localStorage.getItem('app-preferences')) {
      localStorage.setItem('app-preferences', JSON.stringify(defaultPreferences))
    }
  }

  const getPreferences = (): AppPreferences => {
    const preferencesRef = localStorage.getItem('app-preferences') as string
    const preferencesObject: AppPreferences = JSON.parse(preferencesRef)
    return preferencesObject
  }

  function setPreference<T extends AllValidKeys>(key: T, value: any): void {
    const keys = key.split('.') as (keyof any)[]
    const newPreferences = { ...preferences }
    let result: any = newPreferences

    for (let i = 0; i < keys.length - 1; i++) {
      const k = keys[i]
      if (!result[k]) {
        result[k] = {}
      }
      result = result[k]
    }

    result[keys[keys.length - 1]] = value
    localStorage.setItem('app-preferences', JSON.stringify(newPreferences))
    setPreferences(newPreferences)
    console.log(1, newPreferences)
  }

  useEffect(() => {
    assertPreferencesObject()
    setPreferences(getPreferences())
  }, [])

  return (
    <LocalAppPreferencesContext.Provider value={{ preferences, setPreference }}>
      {children}
    </LocalAppPreferencesContext.Provider>
  )
}
