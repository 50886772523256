import dayjs from "dayjs";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useQueryDates(){
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const startDate = query.get("startDate") ?? ''
    const endDate = query.get("endDate") ?? ''
    return {startDate, endDate};
}

export function useAssertQueryDates(){
    const navigate = useNavigate();
    const location = useLocation();
    const {startDate, endDate} = useQueryDates();

    const assertDates = () => {
        if(startDate && endDate){
            return new URLSearchParams(location.search).toString();
        }
    
        const newUrlParams = new URLSearchParams(location.search);
        if(!startDate){
            const currentDate = dayjs()
            if(currentDate.date() <= 1){
                currentDate.subtract(1, "month");
            }
            const newStartDate = currentDate.startOf("month").format("MM/DD/YYYY");
            newUrlParams.set("startDate", newStartDate);
        } else {
            newUrlParams.set("startDate", startDate);
        }
    
        if(!endDate){
            const currentDate = dayjs();
            const newEndDate = currentDate.subtract(1, 'day').format("MM/DD/YYYY");
            newUrlParams.set("endDate", newEndDate);
        } else {
            newUrlParams.set("endDate", endDate);
        }
        
        return newUrlParams.toString()
    }

    useEffect(() => {
        const newDates = assertDates();
        navigate(`?${newDates}`, {replace: true});
    }, [navigate, location.search])
}