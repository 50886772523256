import * as React from 'react'

import { cn } from '@/lib/utils'

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'sm' | 'md'
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, size, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'flex w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 placeholder:opacity-40',
        getSizeVariant(String(size) ?? 'md'),
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Input.displayName = 'Input'

var getSizeVariant = (size: string | undefined) => {
  if (size === 'sm') {
    return 'h-8 text-sm'
  } else {
    return 'h-9'
  }
}

export { Input }
